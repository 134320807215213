html {
  height: 100%;
  min-height: 100%;
}

body {
  min-height: 100%;
  font-family: 'Benton';
  background-color: #ebeef2;
  background: linear-gradient(135deg, #ebeef2 0%, #ffffff 100%);
  background-attachment: fixed;
  overflow-x: hidden;
  color: #354052;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

h1,
h2,
h3 {
  font-size: 1em;
  font-weight: bold;
}

a {
  color: #009530;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

a:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  color: #61b3e2;
}

a:focus {
  outline: none;
}

.sf-toolbar-icon .fas {
  color: #aaaaaa;
  font-size: initial;
}

.form-control {
  padding: 0 15px;
  font-weight: bold;
  border-radius: 0px;
  line-height: 50px;
  height: 50px;
  border-radius: 3px;
}

textarea.form-control {
  height: auto;
  min-height: 200px;
}

select.form-control:not([size]):not([multiple]) {
  height: 46px;
}

select:hover {
  cursor: pointer;
}

.help-block {
  background-color: #f6edcd;
  position: relative;
  padding: 10px;
  padding-left: 30px;
}

.help-block:before {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  content: "\F05A";
}

.form-error-container .help-block {
  background-color: #f2d3d3;
  color: #A94442;
}

.control-label .required {
  color: #A94442;
  font-weight: bold;
}

.upload-preview .control-label {
  margin-top: 15px;
}

.image.collection-entry .form-control {
  height: 32px;
  line-height: 32px;
  font-weight: normal;
}

.image.collection-entry .control-label {
  display: none;
}

/* Custom radio and checkboxes */

.form-check.radio {
  color: #AAAAAA;
  display: inline-block;
  position: relative;
  float: left;
  height: 40px;
}

.form-check.radio input[type=radio] {
  position: absolute;
  visibility: hidden;
}

.form-check.radio label {
  display: inline-block;
  position: relative;
  font-weight: 900;
  font-size: 1rem;
  padding: 5px 40px 25px 30px;
  margin: 0px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

.form-check.radio:hover label {
  color: #1b1c20;
}

.check-radio {
  display: inline-block;
  position: absolute;
  border: 3px solid #AAAAAA;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  top: 7px;
  left: 0px;
  z-index: 5;
  transition: border .25s linear;
  -webkit-transition: border .25s linear;
}

.form-check.radio:hover .check-radio {
  border: 3px solid #1b1c20;
}

.form-check.radio .check-radio:before {
  display: inline-block;
  position: absolute;
  content: '';
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: -1px;
  left: -1px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check-radio {
  border: 3px solid #009530;
}

input[type=radio]:checked ~ .check-radio:before {
  background: #009530;
}

input[type=radio]:checked ~ label {
  color: #009530;
}

.form-check.radio:nth-of-type(2) input[type=radio]:checked ~ .check-radio {
  border: 3px solid #A94442;
}

.form-check.radio:nth-of-type(2) input[type=radio]:checked ~ .check-radio:before {
  background: #A94442;
}

.form-check.radio:nth-of-type(2) input[type=radio]:checked ~ label {
  color: #A94442;
}

#product_categories label {
  position: relative;
  top: 5px;
  z-index: -1;
}

/* custom checkbox */

.form-check-inline input[type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: 2;
  width: 24px;
  height: 24px;
}

.form-check-inline input[type=checkbox]:hover {
  cursor: pointer;
}

.form-check-inline input[type=checkbox] + span {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.form-check-inline input[type=checkbox] + span:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 3px;
  border: 1px solid rgba(0, 10, 20, 0.25);
}

.form-check-inline input[type=checkbox]:hover + span:before {
  background: #009530;
}

.form-check-inline input[type=checkbox]:focus + span:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.form-check-inline input[type=checkbox]:checked + span:before {
  background: #009530;
}

.form-check-inline input[type=checkbox]:disabled + span {
  color: #b8b8b8;
  cursor: auto;
}

.form-check-inline input[type=checkbox]:disabled + span:before {
  box-shadow: none;
  background: #ddd;
}

.form-check-inline input[type=checkbox]:checked + span:after {
  content: '';
  position: absolute;
  left: 7px;
  top: 12px;
  background: white;
  width: 3px;
  height: 3px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

@font-face {
  font-family: 'Benton';
  src: url(/assets/fonts/bentonsans_regular-webfont.320dffba2958de052e9625f4e87cb7ca.woff) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Benton';
  src: url(/assets/fonts/bentonsans_bold-webfont.14dcf9b1ce94a3def20e8d28370ad11c.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
          animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
          animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
          transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
          transform: scale(1, -1);
}

.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
          transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
          filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.fa-500px:before {
  content: "\F26E";
}

.fa-abacus:before {
  content: "\F640";
}

.fa-accessible-icon:before {
  content: "\F368";
}

.fa-accusoft:before {
  content: "\F369";
}

.fa-acorn:before {
  content: "\F6AE";
}

.fa-acquisitions-incorporated:before {
  content: "\F6AF";
}

.fa-ad:before {
  content: "\F641";
}

.fa-address-book:before {
  content: "\F2B9";
}

.fa-address-card:before {
  content: "\F2BB";
}

.fa-adjust:before {
  content: "\F042";
}

.fa-adn:before {
  content: "\F170";
}

.fa-adobe:before {
  content: "\F778";
}

.fa-adversal:before {
  content: "\F36A";
}

.fa-affiliatetheme:before {
  content: "\F36B";
}

.fa-air-freshener:before {
  content: "\F5D0";
}

.fa-airbnb:before {
  content: "\F834";
}

.fa-alarm-clock:before {
  content: "\F34E";
}

.fa-alarm-exclamation:before {
  content: "\F843";
}

.fa-alarm-plus:before {
  content: "\F844";
}

.fa-alarm-snooze:before {
  content: "\F845";
}

.fa-algolia:before {
  content: "\F36C";
}

.fa-alicorn:before {
  content: "\F6B0";
}

.fa-align-center:before {
  content: "\F037";
}

.fa-align-justify:before {
  content: "\F039";
}

.fa-align-left:before {
  content: "\F036";
}

.fa-align-right:before {
  content: "\F038";
}

.fa-align-slash:before {
  content: "\F846";
}

.fa-alipay:before {
  content: "\F642";
}

.fa-allergies:before {
  content: "\F461";
}

.fa-amazon:before {
  content: "\F270";
}

.fa-amazon-pay:before {
  content: "\F42C";
}

.fa-ambulance:before {
  content: "\F0F9";
}

.fa-american-sign-language-interpreting:before {
  content: "\F2A3";
}

.fa-amilia:before {
  content: "\F36D";
}

.fa-analytics:before {
  content: "\F643";
}

.fa-anchor:before {
  content: "\F13D";
}

.fa-android:before {
  content: "\F17B";
}

.fa-angel:before {
  content: "\F779";
}

.fa-angellist:before {
  content: "\F209";
}

.fa-angle-double-down:before {
  content: "\F103";
}

.fa-angle-double-left:before {
  content: "\F100";
}

.fa-angle-double-right:before {
  content: "\F101";
}

.fa-angle-double-up:before {
  content: "\F102";
}

.fa-angle-down:before {
  content: "\F107";
}

.fa-angle-left:before {
  content: "\F104";
}

.fa-angle-right:before {
  content: "\F105";
}

.fa-angle-up:before {
  content: "\F106";
}

.fa-angry:before {
  content: "\F556";
}

.fa-angrycreative:before {
  content: "\F36E";
}

.fa-angular:before {
  content: "\F420";
}

.fa-ankh:before {
  content: "\F644";
}

.fa-app-store:before {
  content: "\F36F";
}

.fa-app-store-ios:before {
  content: "\F370";
}

.fa-apper:before {
  content: "\F371";
}

.fa-apple:before {
  content: "\F179";
}

.fa-apple-alt:before {
  content: "\F5D1";
}

.fa-apple-crate:before {
  content: "\F6B1";
}

.fa-apple-pay:before {
  content: "\F415";
}

.fa-archive:before {
  content: "\F187";
}

.fa-archway:before {
  content: "\F557";
}

.fa-arrow-alt-circle-down:before {
  content: "\F358";
}

.fa-arrow-alt-circle-left:before {
  content: "\F359";
}

.fa-arrow-alt-circle-right:before {
  content: "\F35A";
}

.fa-arrow-alt-circle-up:before {
  content: "\F35B";
}

.fa-arrow-alt-down:before {
  content: "\F354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\F346";
}

.fa-arrow-alt-from-left:before {
  content: "\F347";
}

.fa-arrow-alt-from-right:before {
  content: "\F348";
}

.fa-arrow-alt-from-top:before {
  content: "\F349";
}

.fa-arrow-alt-left:before {
  content: "\F355";
}

.fa-arrow-alt-right:before {
  content: "\F356";
}

.fa-arrow-alt-square-down:before {
  content: "\F350";
}

.fa-arrow-alt-square-left:before {
  content: "\F351";
}

.fa-arrow-alt-square-right:before {
  content: "\F352";
}

.fa-arrow-alt-square-up:before {
  content: "\F353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\F34A";
}

.fa-arrow-alt-to-left:before {
  content: "\F34B";
}

.fa-arrow-alt-to-right:before {
  content: "\F34C";
}

.fa-arrow-alt-to-top:before {
  content: "\F34D";
}

.fa-arrow-alt-up:before {
  content: "\F357";
}

.fa-arrow-circle-down:before {
  content: "\F0AB";
}

.fa-arrow-circle-left:before {
  content: "\F0A8";
}

.fa-arrow-circle-right:before {
  content: "\F0A9";
}

.fa-arrow-circle-up:before {
  content: "\F0AA";
}

.fa-arrow-down:before {
  content: "\F063";
}

.fa-arrow-from-bottom:before {
  content: "\F342";
}

.fa-arrow-from-left:before {
  content: "\F343";
}

.fa-arrow-from-right:before {
  content: "\F344";
}

.fa-arrow-from-top:before {
  content: "\F345";
}

.fa-arrow-left:before {
  content: "\F060";
}

.fa-arrow-right:before {
  content: "\F061";
}

.fa-arrow-square-down:before {
  content: "\F339";
}

.fa-arrow-square-left:before {
  content: "\F33A";
}

.fa-arrow-square-right:before {
  content: "\F33B";
}

.fa-arrow-square-up:before {
  content: "\F33C";
}

.fa-arrow-to-bottom:before {
  content: "\F33D";
}

.fa-arrow-to-left:before {
  content: "\F33E";
}

.fa-arrow-to-right:before {
  content: "\F340";
}

.fa-arrow-to-top:before {
  content: "\F341";
}

.fa-arrow-up:before {
  content: "\F062";
}

.fa-arrows:before {
  content: "\F047";
}

.fa-arrows-alt:before {
  content: "\F0B2";
}

.fa-arrows-alt-h:before {
  content: "\F337";
}

.fa-arrows-alt-v:before {
  content: "\F338";
}

.fa-arrows-h:before {
  content: "\F07E";
}

.fa-arrows-v:before {
  content: "\F07D";
}

.fa-artstation:before {
  content: "\F77A";
}

.fa-assistive-listening-systems:before {
  content: "\F2A2";
}

.fa-asterisk:before {
  content: "\F069";
}

.fa-asymmetrik:before {
  content: "\F372";
}

.fa-at:before {
  content: "\F1FA";
}

.fa-atlas:before {
  content: "\F558";
}

.fa-atlassian:before {
  content: "\F77B";
}

.fa-atom:before {
  content: "\F5D2";
}

.fa-atom-alt:before {
  content: "\F5D3";
}

.fa-audible:before {
  content: "\F373";
}

.fa-audio-description:before {
  content: "\F29E";
}

.fa-autoprefixer:before {
  content: "\F41C";
}

.fa-avianex:before {
  content: "\F374";
}

.fa-aviato:before {
  content: "\F421";
}

.fa-award:before {
  content: "\F559";
}

.fa-aws:before {
  content: "\F375";
}

.fa-axe:before {
  content: "\F6B2";
}

.fa-axe-battle:before {
  content: "\F6B3";
}

.fa-baby:before {
  content: "\F77C";
}

.fa-baby-carriage:before {
  content: "\F77D";
}

.fa-backpack:before {
  content: "\F5D4";
}

.fa-backspace:before {
  content: "\F55A";
}

.fa-backward:before {
  content: "\F04A";
}

.fa-bacon:before {
  content: "\F7E5";
}

.fa-badge:before {
  content: "\F335";
}

.fa-badge-check:before {
  content: "\F336";
}

.fa-badge-dollar:before {
  content: "\F645";
}

.fa-badge-percent:before {
  content: "\F646";
}

.fa-badger-honey:before {
  content: "\F6B4";
}

.fa-bags-shopping:before {
  content: "\F847";
}

.fa-balance-scale:before {
  content: "\F24E";
}

.fa-balance-scale-left:before {
  content: "\F515";
}

.fa-balance-scale-right:before {
  content: "\F516";
}

.fa-ball-pile:before {
  content: "\F77E";
}

.fa-ballot:before {
  content: "\F732";
}

.fa-ballot-check:before {
  content: "\F733";
}

.fa-ban:before {
  content: "\F05E";
}

.fa-band-aid:before {
  content: "\F462";
}

.fa-bandcamp:before {
  content: "\F2D5";
}

.fa-barcode:before {
  content: "\F02A";
}

.fa-barcode-alt:before {
  content: "\F463";
}

.fa-barcode-read:before {
  content: "\F464";
}

.fa-barcode-scan:before {
  content: "\F465";
}

.fa-bars:before {
  content: "\F0C9";
}

.fa-baseball:before {
  content: "\F432";
}

.fa-baseball-ball:before {
  content: "\F433";
}

.fa-basketball-ball:before {
  content: "\F434";
}

.fa-basketball-hoop:before {
  content: "\F435";
}

.fa-bat:before {
  content: "\F6B5";
}

.fa-bath:before {
  content: "\F2CD";
}

.fa-battery-bolt:before {
  content: "\F376";
}

.fa-battery-empty:before {
  content: "\F244";
}

.fa-battery-full:before {
  content: "\F240";
}

.fa-battery-half:before {
  content: "\F242";
}

.fa-battery-quarter:before {
  content: "\F243";
}

.fa-battery-slash:before {
  content: "\F377";
}

.fa-battery-three-quarters:before {
  content: "\F241";
}

.fa-battle-net:before {
  content: "\F835";
}

.fa-bed:before {
  content: "\F236";
}

.fa-beer:before {
  content: "\F0FC";
}

.fa-behance:before {
  content: "\F1B4";
}

.fa-behance-square:before {
  content: "\F1B5";
}

.fa-bell:before {
  content: "\F0F3";
}

.fa-bell-exclamation:before {
  content: "\F848";
}

.fa-bell-plus:before {
  content: "\F849";
}

.fa-bell-school:before {
  content: "\F5D5";
}

.fa-bell-school-slash:before {
  content: "\F5D6";
}

.fa-bell-slash:before {
  content: "\F1F6";
}

.fa-bells:before {
  content: "\F77F";
}

.fa-bezier-curve:before {
  content: "\F55B";
}

.fa-bible:before {
  content: "\F647";
}

.fa-bicycle:before {
  content: "\F206";
}

.fa-biking:before {
  content: "\F84A";
}

.fa-biking-mountain:before {
  content: "\F84B";
}

.fa-bimobject:before {
  content: "\F378";
}

.fa-binoculars:before {
  content: "\F1E5";
}

.fa-biohazard:before {
  content: "\F780";
}

.fa-birthday-cake:before {
  content: "\F1FD";
}

.fa-bitbucket:before {
  content: "\F171";
}

.fa-bitcoin:before {
  content: "\F379";
}

.fa-bity:before {
  content: "\F37A";
}

.fa-black-tie:before {
  content: "\F27E";
}

.fa-blackberry:before {
  content: "\F37B";
}

.fa-blanket:before {
  content: "\F498";
}

.fa-blender:before {
  content: "\F517";
}

.fa-blender-phone:before {
  content: "\F6B6";
}

.fa-blind:before {
  content: "\F29D";
}

.fa-blog:before {
  content: "\F781";
}

.fa-blogger:before {
  content: "\F37C";
}

.fa-blogger-b:before {
  content: "\F37D";
}

.fa-bluetooth:before {
  content: "\F293";
}

.fa-bluetooth-b:before {
  content: "\F294";
}

.fa-bold:before {
  content: "\F032";
}

.fa-bolt:before {
  content: "\F0E7";
}

.fa-bomb:before {
  content: "\F1E2";
}

.fa-bone:before {
  content: "\F5D7";
}

.fa-bone-break:before {
  content: "\F5D8";
}

.fa-bong:before {
  content: "\F55C";
}

.fa-book:before {
  content: "\F02D";
}

.fa-book-alt:before {
  content: "\F5D9";
}

.fa-book-dead:before {
  content: "\F6B7";
}

.fa-book-heart:before {
  content: "\F499";
}

.fa-book-medical:before {
  content: "\F7E6";
}

.fa-book-open:before {
  content: "\F518";
}

.fa-book-reader:before {
  content: "\F5DA";
}

.fa-book-spells:before {
  content: "\F6B8";
}

.fa-book-user:before {
  content: "\F7E7";
}

.fa-bookmark:before {
  content: "\F02E";
}

.fa-books:before {
  content: "\F5DB";
}

.fa-books-medical:before {
  content: "\F7E8";
}

.fa-boot:before {
  content: "\F782";
}

.fa-booth-curtain:before {
  content: "\F734";
}

.fa-bootstrap:before {
  content: "\F836";
}

.fa-border-all:before {
  content: "\F84C";
}

.fa-border-bottom:before {
  content: "\F84D";
}

.fa-border-inner:before {
  content: "\F84E";
}

.fa-border-left:before {
  content: "\F84F";
}

.fa-border-none:before {
  content: "\F850";
}

.fa-border-outer:before {
  content: "\F851";
}

.fa-border-right:before {
  content: "\F852";
}

.fa-border-style:before {
  content: "\F853";
}

.fa-border-style-alt:before {
  content: "\F854";
}

.fa-border-top:before {
  content: "\F855";
}

.fa-bow-arrow:before {
  content: "\F6B9";
}

.fa-bowling-ball:before {
  content: "\F436";
}

.fa-bowling-pins:before {
  content: "\F437";
}

.fa-box:before {
  content: "\F466";
}

.fa-box-alt:before {
  content: "\F49A";
}

.fa-box-ballot:before {
  content: "\F735";
}

.fa-box-check:before {
  content: "\F467";
}

.fa-box-fragile:before {
  content: "\F49B";
}

.fa-box-full:before {
  content: "\F49C";
}

.fa-box-heart:before {
  content: "\F49D";
}

.fa-box-open:before {
  content: "\F49E";
}

.fa-box-up:before {
  content: "\F49F";
}

.fa-box-usd:before {
  content: "\F4A0";
}

.fa-boxes:before {
  content: "\F468";
}

.fa-boxes-alt:before {
  content: "\F4A1";
}

.fa-boxing-glove:before {
  content: "\F438";
}

.fa-brackets:before {
  content: "\F7E9";
}

.fa-brackets-curly:before {
  content: "\F7EA";
}

.fa-braille:before {
  content: "\F2A1";
}

.fa-brain:before {
  content: "\F5DC";
}

.fa-bread-loaf:before {
  content: "\F7EB";
}

.fa-bread-slice:before {
  content: "\F7EC";
}

.fa-briefcase:before {
  content: "\F0B1";
}

.fa-briefcase-medical:before {
  content: "\F469";
}

.fa-bring-forward:before {
  content: "\F856";
}

.fa-bring-front:before {
  content: "\F857";
}

.fa-broadcast-tower:before {
  content: "\F519";
}

.fa-broom:before {
  content: "\F51A";
}

.fa-browser:before {
  content: "\F37E";
}

.fa-brush:before {
  content: "\F55D";
}

.fa-btc:before {
  content: "\F15A";
}

.fa-buffer:before {
  content: "\F837";
}

.fa-bug:before {
  content: "\F188";
}

.fa-building:before {
  content: "\F1AD";
}

.fa-bullhorn:before {
  content: "\F0A1";
}

.fa-bullseye:before {
  content: "\F140";
}

.fa-bullseye-arrow:before {
  content: "\F648";
}

.fa-bullseye-pointer:before {
  content: "\F649";
}

.fa-burger-soda:before {
  content: "\F858";
}

.fa-burn:before {
  content: "\F46A";
}

.fa-buromobelexperte:before {
  content: "\F37F";
}

.fa-burrito:before {
  content: "\F7ED";
}

.fa-bus:before {
  content: "\F207";
}

.fa-bus-alt:before {
  content: "\F55E";
}

.fa-bus-school:before {
  content: "\F5DD";
}

.fa-business-time:before {
  content: "\F64A";
}

.fa-buysellads:before {
  content: "\F20D";
}

.fa-cabinet-filing:before {
  content: "\F64B";
}

.fa-calculator:before {
  content: "\F1EC";
}

.fa-calculator-alt:before {
  content: "\F64C";
}

.fa-calendar:before {
  content: "\F133";
}

.fa-calendar-alt:before {
  content: "\F073";
}

.fa-calendar-check:before {
  content: "\F274";
}

.fa-calendar-day:before {
  content: "\F783";
}

.fa-calendar-edit:before {
  content: "\F333";
}

.fa-calendar-exclamation:before {
  content: "\F334";
}

.fa-calendar-minus:before {
  content: "\F272";
}

.fa-calendar-plus:before {
  content: "\F271";
}

.fa-calendar-star:before {
  content: "\F736";
}

.fa-calendar-times:before {
  content: "\F273";
}

.fa-calendar-week:before {
  content: "\F784";
}

.fa-camera:before {
  content: "\F030";
}

.fa-camera-alt:before {
  content: "\F332";
}

.fa-camera-retro:before {
  content: "\F083";
}

.fa-campfire:before {
  content: "\F6BA";
}

.fa-campground:before {
  content: "\F6BB";
}

.fa-canadian-maple-leaf:before {
  content: "\F785";
}

.fa-candle-holder:before {
  content: "\F6BC";
}

.fa-candy-cane:before {
  content: "\F786";
}

.fa-candy-corn:before {
  content: "\F6BD";
}

.fa-cannabis:before {
  content: "\F55F";
}

.fa-capsules:before {
  content: "\F46B";
}

.fa-car:before {
  content: "\F1B9";
}

.fa-car-alt:before {
  content: "\F5DE";
}

.fa-car-battery:before {
  content: "\F5DF";
}

.fa-car-building:before {
  content: "\F859";
}

.fa-car-bump:before {
  content: "\F5E0";
}

.fa-car-bus:before {
  content: "\F85A";
}

.fa-car-crash:before {
  content: "\F5E1";
}

.fa-car-garage:before {
  content: "\F5E2";
}

.fa-car-mechanic:before {
  content: "\F5E3";
}

.fa-car-side:before {
  content: "\F5E4";
}

.fa-car-tilt:before {
  content: "\F5E5";
}

.fa-car-wash:before {
  content: "\F5E6";
}

.fa-caret-circle-down:before {
  content: "\F32D";
}

.fa-caret-circle-left:before {
  content: "\F32E";
}

.fa-caret-circle-right:before {
  content: "\F330";
}

.fa-caret-circle-up:before {
  content: "\F331";
}

.fa-caret-down:before {
  content: "\F0D7";
}

.fa-caret-left:before {
  content: "\F0D9";
}

.fa-caret-right:before {
  content: "\F0DA";
}

.fa-caret-square-down:before {
  content: "\F150";
}

.fa-caret-square-left:before {
  content: "\F191";
}

.fa-caret-square-right:before {
  content: "\F152";
}

.fa-caret-square-up:before {
  content: "\F151";
}

.fa-caret-up:before {
  content: "\F0D8";
}

.fa-carrot:before {
  content: "\F787";
}

.fa-cars:before {
  content: "\F85B";
}

.fa-cart-arrow-down:before {
  content: "\F218";
}

.fa-cart-plus:before {
  content: "\F217";
}

.fa-cash-register:before {
  content: "\F788";
}

.fa-cat:before {
  content: "\F6BE";
}

.fa-cauldron:before {
  content: "\F6BF";
}

.fa-cc-amazon-pay:before {
  content: "\F42D";
}

.fa-cc-amex:before {
  content: "\F1F3";
}

.fa-cc-apple-pay:before {
  content: "\F416";
}

.fa-cc-diners-club:before {
  content: "\F24C";
}

.fa-cc-discover:before {
  content: "\F1F2";
}

.fa-cc-jcb:before {
  content: "\F24B";
}

.fa-cc-mastercard:before {
  content: "\F1F1";
}

.fa-cc-paypal:before {
  content: "\F1F4";
}

.fa-cc-stripe:before {
  content: "\F1F5";
}

.fa-cc-visa:before {
  content: "\F1F0";
}

.fa-centercode:before {
  content: "\F380";
}

.fa-centos:before {
  content: "\F789";
}

.fa-certificate:before {
  content: "\F0A3";
}

.fa-chair:before {
  content: "\F6C0";
}

.fa-chair-office:before {
  content: "\F6C1";
}

.fa-chalkboard:before {
  content: "\F51B";
}

.fa-chalkboard-teacher:before {
  content: "\F51C";
}

.fa-charging-station:before {
  content: "\F5E7";
}

.fa-chart-area:before {
  content: "\F1FE";
}

.fa-chart-bar:before {
  content: "\F080";
}

.fa-chart-line:before {
  content: "\F201";
}

.fa-chart-line-down:before {
  content: "\F64D";
}

.fa-chart-network:before {
  content: "\F78A";
}

.fa-chart-pie:before {
  content: "\F200";
}

.fa-chart-pie-alt:before {
  content: "\F64E";
}

.fa-chart-scatter:before {
  content: "\F7EE";
}

.fa-check:before {
  content: "\F00C";
}

.fa-check-circle:before {
  content: "\F058";
}

.fa-check-double:before {
  content: "\F560";
}

.fa-check-square:before {
  content: "\F14A";
}

.fa-cheese:before {
  content: "\F7EF";
}

.fa-cheese-swiss:before {
  content: "\F7F0";
}

.fa-cheeseburger:before {
  content: "\F7F1";
}

.fa-chess:before {
  content: "\F439";
}

.fa-chess-bishop:before {
  content: "\F43A";
}

.fa-chess-bishop-alt:before {
  content: "\F43B";
}

.fa-chess-board:before {
  content: "\F43C";
}

.fa-chess-clock:before {
  content: "\F43D";
}

.fa-chess-clock-alt:before {
  content: "\F43E";
}

.fa-chess-king:before {
  content: "\F43F";
}

.fa-chess-king-alt:before {
  content: "\F440";
}

.fa-chess-knight:before {
  content: "\F441";
}

.fa-chess-knight-alt:before {
  content: "\F442";
}

.fa-chess-pawn:before {
  content: "\F443";
}

.fa-chess-pawn-alt:before {
  content: "\F444";
}

.fa-chess-queen:before {
  content: "\F445";
}

.fa-chess-queen-alt:before {
  content: "\F446";
}

.fa-chess-rook:before {
  content: "\F447";
}

.fa-chess-rook-alt:before {
  content: "\F448";
}

.fa-chevron-circle-down:before {
  content: "\F13A";
}

.fa-chevron-circle-left:before {
  content: "\F137";
}

.fa-chevron-circle-right:before {
  content: "\F138";
}

.fa-chevron-circle-up:before {
  content: "\F139";
}

.fa-chevron-double-down:before {
  content: "\F322";
}

.fa-chevron-double-left:before {
  content: "\F323";
}

.fa-chevron-double-right:before {
  content: "\F324";
}

.fa-chevron-double-up:before {
  content: "\F325";
}

.fa-chevron-down:before {
  content: "\F078";
}

.fa-chevron-left:before {
  content: "\F053";
}

.fa-chevron-right:before {
  content: "\F054";
}

.fa-chevron-square-down:before {
  content: "\F329";
}

.fa-chevron-square-left:before {
  content: "\F32A";
}

.fa-chevron-square-right:before {
  content: "\F32B";
}

.fa-chevron-square-up:before {
  content: "\F32C";
}

.fa-chevron-up:before {
  content: "\F077";
}

.fa-child:before {
  content: "\F1AE";
}

.fa-chimney:before {
  content: "\F78B";
}

.fa-chrome:before {
  content: "\F268";
}

.fa-chromecast:before {
  content: "\F838";
}

.fa-church:before {
  content: "\F51D";
}

.fa-circle:before {
  content: "\F111";
}

.fa-circle-notch:before {
  content: "\F1CE";
}

.fa-city:before {
  content: "\F64F";
}

.fa-claw-marks:before {
  content: "\F6C2";
}

.fa-clinic-medical:before {
  content: "\F7F2";
}

.fa-clipboard:before {
  content: "\F328";
}

.fa-clipboard-check:before {
  content: "\F46C";
}

.fa-clipboard-list:before {
  content: "\F46D";
}

.fa-clipboard-list-check:before {
  content: "\F737";
}

.fa-clipboard-prescription:before {
  content: "\F5E8";
}

.fa-clipboard-user:before {
  content: "\F7F3";
}

.fa-clock:before {
  content: "\F017";
}

.fa-clone:before {
  content: "\F24D";
}

.fa-closed-captioning:before {
  content: "\F20A";
}

.fa-cloud:before {
  content: "\F0C2";
}

.fa-cloud-download:before {
  content: "\F0ED";
}

.fa-cloud-download-alt:before {
  content: "\F381";
}

.fa-cloud-drizzle:before {
  content: "\F738";
}

.fa-cloud-hail:before {
  content: "\F739";
}

.fa-cloud-hail-mixed:before {
  content: "\F73A";
}

.fa-cloud-meatball:before {
  content: "\F73B";
}

.fa-cloud-moon:before {
  content: "\F6C3";
}

.fa-cloud-moon-rain:before {
  content: "\F73C";
}

.fa-cloud-rain:before {
  content: "\F73D";
}

.fa-cloud-rainbow:before {
  content: "\F73E";
}

.fa-cloud-showers:before {
  content: "\F73F";
}

.fa-cloud-showers-heavy:before {
  content: "\F740";
}

.fa-cloud-sleet:before {
  content: "\F741";
}

.fa-cloud-snow:before {
  content: "\F742";
}

.fa-cloud-sun:before {
  content: "\F6C4";
}

.fa-cloud-sun-rain:before {
  content: "\F743";
}

.fa-cloud-upload:before {
  content: "\F0EE";
}

.fa-cloud-upload-alt:before {
  content: "\F382";
}

.fa-clouds:before {
  content: "\F744";
}

.fa-clouds-moon:before {
  content: "\F745";
}

.fa-clouds-sun:before {
  content: "\F746";
}

.fa-cloudscale:before {
  content: "\F383";
}

.fa-cloudsmith:before {
  content: "\F384";
}

.fa-cloudversify:before {
  content: "\F385";
}

.fa-club:before {
  content: "\F327";
}

.fa-cocktail:before {
  content: "\F561";
}

.fa-code:before {
  content: "\F121";
}

.fa-code-branch:before {
  content: "\F126";
}

.fa-code-commit:before {
  content: "\F386";
}

.fa-code-merge:before {
  content: "\F387";
}

.fa-codepen:before {
  content: "\F1CB";
}

.fa-codiepie:before {
  content: "\F284";
}

.fa-coffee:before {
  content: "\F0F4";
}

.fa-coffee-togo:before {
  content: "\F6C5";
}

.fa-coffin:before {
  content: "\F6C6";
}

.fa-cog:before {
  content: "\F013";
}

.fa-cogs:before {
  content: "\F085";
}

.fa-coin:before {
  content: "\F85C";
}

.fa-coins:before {
  content: "\F51E";
}

.fa-columns:before {
  content: "\F0DB";
}

.fa-comment:before {
  content: "\F075";
}

.fa-comment-alt:before {
  content: "\F27A";
}

.fa-comment-alt-check:before {
  content: "\F4A2";
}

.fa-comment-alt-dollar:before {
  content: "\F650";
}

.fa-comment-alt-dots:before {
  content: "\F4A3";
}

.fa-comment-alt-edit:before {
  content: "\F4A4";
}

.fa-comment-alt-exclamation:before {
  content: "\F4A5";
}

.fa-comment-alt-lines:before {
  content: "\F4A6";
}

.fa-comment-alt-medical:before {
  content: "\F7F4";
}

.fa-comment-alt-minus:before {
  content: "\F4A7";
}

.fa-comment-alt-plus:before {
  content: "\F4A8";
}

.fa-comment-alt-slash:before {
  content: "\F4A9";
}

.fa-comment-alt-smile:before {
  content: "\F4AA";
}

.fa-comment-alt-times:before {
  content: "\F4AB";
}

.fa-comment-check:before {
  content: "\F4AC";
}

.fa-comment-dollar:before {
  content: "\F651";
}

.fa-comment-dots:before {
  content: "\F4AD";
}

.fa-comment-edit:before {
  content: "\F4AE";
}

.fa-comment-exclamation:before {
  content: "\F4AF";
}

.fa-comment-lines:before {
  content: "\F4B0";
}

.fa-comment-medical:before {
  content: "\F7F5";
}

.fa-comment-minus:before {
  content: "\F4B1";
}

.fa-comment-plus:before {
  content: "\F4B2";
}

.fa-comment-slash:before {
  content: "\F4B3";
}

.fa-comment-smile:before {
  content: "\F4B4";
}

.fa-comment-times:before {
  content: "\F4B5";
}

.fa-comments:before {
  content: "\F086";
}

.fa-comments-alt:before {
  content: "\F4B6";
}

.fa-comments-alt-dollar:before {
  content: "\F652";
}

.fa-comments-dollar:before {
  content: "\F653";
}

.fa-compact-disc:before {
  content: "\F51F";
}

.fa-compass:before {
  content: "\F14E";
}

.fa-compass-slash:before {
  content: "\F5E9";
}

.fa-compress:before {
  content: "\F066";
}

.fa-compress-alt:before {
  content: "\F422";
}

.fa-compress-arrows-alt:before {
  content: "\F78C";
}

.fa-compress-wide:before {
  content: "\F326";
}

.fa-concierge-bell:before {
  content: "\F562";
}

.fa-confluence:before {
  content: "\F78D";
}

.fa-connectdevelop:before {
  content: "\F20E";
}

.fa-construction:before {
  content: "\F85D";
}

.fa-container-storage:before {
  content: "\F4B7";
}

.fa-contao:before {
  content: "\F26D";
}

.fa-conveyor-belt:before {
  content: "\F46E";
}

.fa-conveyor-belt-alt:before {
  content: "\F46F";
}

.fa-cookie:before {
  content: "\F563";
}

.fa-cookie-bite:before {
  content: "\F564";
}

.fa-copy:before {
  content: "\F0C5";
}

.fa-copyright:before {
  content: "\F1F9";
}

.fa-corn:before {
  content: "\F6C7";
}

.fa-couch:before {
  content: "\F4B8";
}

.fa-cow:before {
  content: "\F6C8";
}

.fa-cpanel:before {
  content: "\F388";
}

.fa-creative-commons:before {
  content: "\F25E";
}

.fa-creative-commons-by:before {
  content: "\F4E7";
}

.fa-creative-commons-nc:before {
  content: "\F4E8";
}

.fa-creative-commons-nc-eu:before {
  content: "\F4E9";
}

.fa-creative-commons-nc-jp:before {
  content: "\F4EA";
}

.fa-creative-commons-nd:before {
  content: "\F4EB";
}

.fa-creative-commons-pd:before {
  content: "\F4EC";
}

.fa-creative-commons-pd-alt:before {
  content: "\F4ED";
}

.fa-creative-commons-remix:before {
  content: "\F4EE";
}

.fa-creative-commons-sa:before {
  content: "\F4EF";
}

.fa-creative-commons-sampling:before {
  content: "\F4F0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\F4F1";
}

.fa-creative-commons-share:before {
  content: "\F4F2";
}

.fa-creative-commons-zero:before {
  content: "\F4F3";
}

.fa-credit-card:before {
  content: "\F09D";
}

.fa-credit-card-blank:before {
  content: "\F389";
}

.fa-credit-card-front:before {
  content: "\F38A";
}

.fa-cricket:before {
  content: "\F449";
}

.fa-critical-role:before {
  content: "\F6C9";
}

.fa-croissant:before {
  content: "\F7F6";
}

.fa-crop:before {
  content: "\F125";
}

.fa-crop-alt:before {
  content: "\F565";
}

.fa-cross:before {
  content: "\F654";
}

.fa-crosshairs:before {
  content: "\F05B";
}

.fa-crow:before {
  content: "\F520";
}

.fa-crown:before {
  content: "\F521";
}

.fa-crutch:before {
  content: "\F7F7";
}

.fa-crutches:before {
  content: "\F7F8";
}

.fa-css3:before {
  content: "\F13C";
}

.fa-css3-alt:before {
  content: "\F38B";
}

.fa-cube:before {
  content: "\F1B2";
}

.fa-cubes:before {
  content: "\F1B3";
}

.fa-curling:before {
  content: "\F44A";
}

.fa-cut:before {
  content: "\F0C4";
}

.fa-cuttlefish:before {
  content: "\F38C";
}

.fa-d-and-d:before {
  content: "\F38D";
}

.fa-d-and-d-beyond:before {
  content: "\F6CA";
}

.fa-dagger:before {
  content: "\F6CB";
}

.fa-dashcube:before {
  content: "\F210";
}

.fa-database:before {
  content: "\F1C0";
}

.fa-deaf:before {
  content: "\F2A4";
}

.fa-debug:before {
  content: "\F7F9";
}

.fa-deer:before {
  content: "\F78E";
}

.fa-deer-rudolph:before {
  content: "\F78F";
}

.fa-delicious:before {
  content: "\F1A5";
}

.fa-democrat:before {
  content: "\F747";
}

.fa-deploydog:before {
  content: "\F38E";
}

.fa-deskpro:before {
  content: "\F38F";
}

.fa-desktop:before {
  content: "\F108";
}

.fa-desktop-alt:before {
  content: "\F390";
}

.fa-dev:before {
  content: "\F6CC";
}

.fa-deviantart:before {
  content: "\F1BD";
}

.fa-dewpoint:before {
  content: "\F748";
}

.fa-dharmachakra:before {
  content: "\F655";
}

.fa-dhl:before {
  content: "\F790";
}

.fa-diagnoses:before {
  content: "\F470";
}

.fa-diamond:before {
  content: "\F219";
}

.fa-diaspora:before {
  content: "\F791";
}

.fa-dice:before {
  content: "\F522";
}

.fa-dice-d10:before {
  content: "\F6CD";
}

.fa-dice-d12:before {
  content: "\F6CE";
}

.fa-dice-d20:before {
  content: "\F6CF";
}

.fa-dice-d4:before {
  content: "\F6D0";
}

.fa-dice-d6:before {
  content: "\F6D1";
}

.fa-dice-d8:before {
  content: "\F6D2";
}

.fa-dice-five:before {
  content: "\F523";
}

.fa-dice-four:before {
  content: "\F524";
}

.fa-dice-one:before {
  content: "\F525";
}

.fa-dice-six:before {
  content: "\F526";
}

.fa-dice-three:before {
  content: "\F527";
}

.fa-dice-two:before {
  content: "\F528";
}

.fa-digg:before {
  content: "\F1A6";
}

.fa-digging:before {
  content: "\F85E";
}

.fa-digital-ocean:before {
  content: "\F391";
}

.fa-digital-tachograph:before {
  content: "\F566";
}

.fa-diploma:before {
  content: "\F5EA";
}

.fa-directions:before {
  content: "\F5EB";
}

.fa-discord:before {
  content: "\F392";
}

.fa-discourse:before {
  content: "\F393";
}

.fa-disease:before {
  content: "\F7FA";
}

.fa-divide:before {
  content: "\F529";
}

.fa-dizzy:before {
  content: "\F567";
}

.fa-dna:before {
  content: "\F471";
}

.fa-do-not-enter:before {
  content: "\F5EC";
}

.fa-dochub:before {
  content: "\F394";
}

.fa-docker:before {
  content: "\F395";
}

.fa-dog:before {
  content: "\F6D3";
}

.fa-dog-leashed:before {
  content: "\F6D4";
}

.fa-dollar-sign:before {
  content: "\F155";
}

.fa-dolly:before {
  content: "\F472";
}

.fa-dolly-empty:before {
  content: "\F473";
}

.fa-dolly-flatbed:before {
  content: "\F474";
}

.fa-dolly-flatbed-alt:before {
  content: "\F475";
}

.fa-dolly-flatbed-empty:before {
  content: "\F476";
}

.fa-donate:before {
  content: "\F4B9";
}

.fa-door-closed:before {
  content: "\F52A";
}

.fa-door-open:before {
  content: "\F52B";
}

.fa-dot-circle:before {
  content: "\F192";
}

.fa-dove:before {
  content: "\F4BA";
}

.fa-download:before {
  content: "\F019";
}

.fa-draft2digital:before {
  content: "\F396";
}

.fa-drafting-compass:before {
  content: "\F568";
}

.fa-dragon:before {
  content: "\F6D5";
}

.fa-draw-circle:before {
  content: "\F5ED";
}

.fa-draw-polygon:before {
  content: "\F5EE";
}

.fa-draw-square:before {
  content: "\F5EF";
}

.fa-dreidel:before {
  content: "\F792";
}

.fa-dribbble:before {
  content: "\F17D";
}

.fa-dribbble-square:before {
  content: "\F397";
}

.fa-drone:before {
  content: "\F85F";
}

.fa-drone-alt:before {
  content: "\F860";
}

.fa-dropbox:before {
  content: "\F16B";
}

.fa-drum:before {
  content: "\F569";
}

.fa-drum-steelpan:before {
  content: "\F56A";
}

.fa-drumstick:before {
  content: "\F6D6";
}

.fa-drumstick-bite:before {
  content: "\F6D7";
}

.fa-drupal:before {
  content: "\F1A9";
}

.fa-dryer:before {
  content: "\F861";
}

.fa-dryer-alt:before {
  content: "\F862";
}

.fa-duck:before {
  content: "\F6D8";
}

.fa-dumbbell:before {
  content: "\F44B";
}

.fa-dumpster:before {
  content: "\F793";
}

.fa-dumpster-fire:before {
  content: "\F794";
}

.fa-dungeon:before {
  content: "\F6D9";
}

.fa-dyalog:before {
  content: "\F399";
}

.fa-ear:before {
  content: "\F5F0";
}

.fa-ear-muffs:before {
  content: "\F795";
}

.fa-earlybirds:before {
  content: "\F39A";
}

.fa-ebay:before {
  content: "\F4F4";
}

.fa-eclipse:before {
  content: "\F749";
}

.fa-eclipse-alt:before {
  content: "\F74A";
}

.fa-edge:before {
  content: "\F282";
}

.fa-edit:before {
  content: "\F044";
}

.fa-egg:before {
  content: "\F7FB";
}

.fa-egg-fried:before {
  content: "\F7FC";
}

.fa-eject:before {
  content: "\F052";
}

.fa-elementor:before {
  content: "\F430";
}

.fa-elephant:before {
  content: "\F6DA";
}

.fa-ellipsis-h:before {
  content: "\F141";
}

.fa-ellipsis-h-alt:before {
  content: "\F39B";
}

.fa-ellipsis-v:before {
  content: "\F142";
}

.fa-ellipsis-v-alt:before {
  content: "\F39C";
}

.fa-ello:before {
  content: "\F5F1";
}

.fa-ember:before {
  content: "\F423";
}

.fa-empire:before {
  content: "\F1D1";
}

.fa-empty-set:before {
  content: "\F656";
}

.fa-engine-warning:before {
  content: "\F5F2";
}

.fa-envelope:before {
  content: "\F0E0";
}

.fa-envelope-open:before {
  content: "\F2B6";
}

.fa-envelope-open-dollar:before {
  content: "\F657";
}

.fa-envelope-open-text:before {
  content: "\F658";
}

.fa-envelope-square:before {
  content: "\F199";
}

.fa-envira:before {
  content: "\F299";
}

.fa-equals:before {
  content: "\F52C";
}

.fa-eraser:before {
  content: "\F12D";
}

.fa-erlang:before {
  content: "\F39D";
}

.fa-ethereum:before {
  content: "\F42E";
}

.fa-ethernet:before {
  content: "\F796";
}

.fa-etsy:before {
  content: "\F2D7";
}

.fa-euro-sign:before {
  content: "\F153";
}

.fa-evernote:before {
  content: "\F839";
}

.fa-exchange:before {
  content: "\F0EC";
}

.fa-exchange-alt:before {
  content: "\F362";
}

.fa-exclamation:before {
  content: "\F12A";
}

.fa-exclamation-circle:before {
  content: "\F06A";
}

.fa-exclamation-square:before {
  content: "\F321";
}

.fa-exclamation-triangle:before {
  content: "\F071";
}

.fa-expand:before {
  content: "\F065";
}

.fa-expand-alt:before {
  content: "\F424";
}

.fa-expand-arrows:before {
  content: "\F31D";
}

.fa-expand-arrows-alt:before {
  content: "\F31E";
}

.fa-expand-wide:before {
  content: "\F320";
}

.fa-expeditedssl:before {
  content: "\F23E";
}

.fa-external-link:before {
  content: "\F08E";
}

.fa-external-link-alt:before {
  content: "\F35D";
}

.fa-external-link-square:before {
  content: "\F14C";
}

.fa-external-link-square-alt:before {
  content: "\F360";
}

.fa-eye:before {
  content: "\F06E";
}

.fa-eye-dropper:before {
  content: "\F1FB";
}

.fa-eye-evil:before {
  content: "\F6DB";
}

.fa-eye-slash:before {
  content: "\F070";
}

.fa-facebook:before {
  content: "\F09A";
}

.fa-facebook-f:before {
  content: "\F39E";
}

.fa-facebook-messenger:before {
  content: "\F39F";
}

.fa-facebook-square:before {
  content: "\F082";
}

.fa-fan:before {
  content: "\F863";
}

.fa-fantasy-flight-games:before {
  content: "\F6DC";
}

.fa-farm:before {
  content: "\F864";
}

.fa-fast-backward:before {
  content: "\F049";
}

.fa-fast-forward:before {
  content: "\F050";
}

.fa-fax:before {
  content: "\F1AC";
}

.fa-feather:before {
  content: "\F52D";
}

.fa-feather-alt:before {
  content: "\F56B";
}

.fa-fedex:before {
  content: "\F797";
}

.fa-fedora:before {
  content: "\F798";
}

.fa-female:before {
  content: "\F182";
}

.fa-field-hockey:before {
  content: "\F44C";
}

.fa-fighter-jet:before {
  content: "\F0FB";
}

.fa-figma:before {
  content: "\F799";
}

.fa-file:before {
  content: "\F15B";
}

.fa-file-alt:before {
  content: "\F15C";
}

.fa-file-archive:before {
  content: "\F1C6";
}

.fa-file-audio:before {
  content: "\F1C7";
}

.fa-file-certificate:before {
  content: "\F5F3";
}

.fa-file-chart-line:before {
  content: "\F659";
}

.fa-file-chart-pie:before {
  content: "\F65A";
}

.fa-file-check:before {
  content: "\F316";
}

.fa-file-code:before {
  content: "\F1C9";
}

.fa-file-contract:before {
  content: "\F56C";
}

.fa-file-csv:before {
  content: "\F6DD";
}

.fa-file-download:before {
  content: "\F56D";
}

.fa-file-edit:before {
  content: "\F31C";
}

.fa-file-excel:before {
  content: "\F1C3";
}

.fa-file-exclamation:before {
  content: "\F31A";
}

.fa-file-export:before {
  content: "\F56E";
}

.fa-file-image:before {
  content: "\F1C5";
}

.fa-file-import:before {
  content: "\F56F";
}

.fa-file-invoice:before {
  content: "\F570";
}

.fa-file-invoice-dollar:before {
  content: "\F571";
}

.fa-file-medical:before {
  content: "\F477";
}

.fa-file-medical-alt:before {
  content: "\F478";
}

.fa-file-minus:before {
  content: "\F318";
}

.fa-file-pdf:before {
  content: "\F1C1";
}

.fa-file-plus:before {
  content: "\F319";
}

.fa-file-powerpoint:before {
  content: "\F1C4";
}

.fa-file-prescription:before {
  content: "\F572";
}

.fa-file-search:before {
  content: "\F865";
}

.fa-file-signature:before {
  content: "\F573";
}

.fa-file-spreadsheet:before {
  content: "\F65B";
}

.fa-file-times:before {
  content: "\F317";
}

.fa-file-upload:before {
  content: "\F574";
}

.fa-file-user:before {
  content: "\F65C";
}

.fa-file-video:before {
  content: "\F1C8";
}

.fa-file-word:before {
  content: "\F1C2";
}

.fa-files-medical:before {
  content: "\F7FD";
}

.fa-fill:before {
  content: "\F575";
}

.fa-fill-drip:before {
  content: "\F576";
}

.fa-film:before {
  content: "\F008";
}

.fa-film-alt:before {
  content: "\F3A0";
}

.fa-filter:before {
  content: "\F0B0";
}

.fa-fingerprint:before {
  content: "\F577";
}

.fa-fire:before {
  content: "\F06D";
}

.fa-fire-alt:before {
  content: "\F7E4";
}

.fa-fire-extinguisher:before {
  content: "\F134";
}

.fa-fire-smoke:before {
  content: "\F74B";
}

.fa-firefox:before {
  content: "\F269";
}

.fa-fireplace:before {
  content: "\F79A";
}

.fa-first-aid:before {
  content: "\F479";
}

.fa-first-order:before {
  content: "\F2B0";
}

.fa-first-order-alt:before {
  content: "\F50A";
}

.fa-firstdraft:before {
  content: "\F3A1";
}

.fa-fish:before {
  content: "\F578";
}

.fa-fish-cooked:before {
  content: "\F7FE";
}

.fa-fist-raised:before {
  content: "\F6DE";
}

.fa-flag:before {
  content: "\F024";
}

.fa-flag-alt:before {
  content: "\F74C";
}

.fa-flag-checkered:before {
  content: "\F11E";
}

.fa-flag-usa:before {
  content: "\F74D";
}

.fa-flame:before {
  content: "\F6DF";
}

.fa-flask:before {
  content: "\F0C3";
}

.fa-flask-poison:before {
  content: "\F6E0";
}

.fa-flask-potion:before {
  content: "\F6E1";
}

.fa-flickr:before {
  content: "\F16E";
}

.fa-flipboard:before {
  content: "\F44D";
}

.fa-flower:before {
  content: "\F7FF";
}

.fa-flower-daffodil:before {
  content: "\F800";
}

.fa-flower-tulip:before {
  content: "\F801";
}

.fa-flushed:before {
  content: "\F579";
}

.fa-fly:before {
  content: "\F417";
}

.fa-fog:before {
  content: "\F74E";
}

.fa-folder:before {
  content: "\F07B";
}

.fa-folder-minus:before {
  content: "\F65D";
}

.fa-folder-open:before {
  content: "\F07C";
}

.fa-folder-plus:before {
  content: "\F65E";
}

.fa-folder-times:before {
  content: "\F65F";
}

.fa-folder-tree:before {
  content: "\F802";
}

.fa-folders:before {
  content: "\F660";
}

.fa-font:before {
  content: "\F031";
}

.fa-font-awesome:before {
  content: "\F2B4";
}

.fa-font-awesome-alt:before {
  content: "\F35C";
}

.fa-font-awesome-flag:before {
  content: "\F425";
}

.fa-font-awesome-logo-full:before {
  content: "\F4E6";
}

.fa-font-case:before {
  content: "\F866";
}

.fa-fonticons:before {
  content: "\F280";
}

.fa-fonticons-fi:before {
  content: "\F3A2";
}

.fa-football-ball:before {
  content: "\F44E";
}

.fa-football-helmet:before {
  content: "\F44F";
}

.fa-forklift:before {
  content: "\F47A";
}

.fa-fort-awesome:before {
  content: "\F286";
}

.fa-fort-awesome-alt:before {
  content: "\F3A3";
}

.fa-forumbee:before {
  content: "\F211";
}

.fa-forward:before {
  content: "\F04E";
}

.fa-foursquare:before {
  content: "\F180";
}

.fa-fragile:before {
  content: "\F4BB";
}

.fa-free-code-camp:before {
  content: "\F2C5";
}

.fa-freebsd:before {
  content: "\F3A4";
}

.fa-french-fries:before {
  content: "\F803";
}

.fa-frog:before {
  content: "\F52E";
}

.fa-frosty-head:before {
  content: "\F79B";
}

.fa-frown:before {
  content: "\F119";
}

.fa-frown-open:before {
  content: "\F57A";
}

.fa-fulcrum:before {
  content: "\F50B";
}

.fa-function:before {
  content: "\F661";
}

.fa-funnel-dollar:before {
  content: "\F662";
}

.fa-futbol:before {
  content: "\F1E3";
}

.fa-galactic-republic:before {
  content: "\F50C";
}

.fa-galactic-senate:before {
  content: "\F50D";
}

.fa-game-board:before {
  content: "\F867";
}

.fa-game-board-alt:before {
  content: "\F868";
}

.fa-gamepad:before {
  content: "\F11B";
}

.fa-gas-pump:before {
  content: "\F52F";
}

.fa-gas-pump-slash:before {
  content: "\F5F4";
}

.fa-gavel:before {
  content: "\F0E3";
}

.fa-gem:before {
  content: "\F3A5";
}

.fa-genderless:before {
  content: "\F22D";
}

.fa-get-pocket:before {
  content: "\F265";
}

.fa-gg:before {
  content: "\F260";
}

.fa-gg-circle:before {
  content: "\F261";
}

.fa-ghost:before {
  content: "\F6E2";
}

.fa-gift:before {
  content: "\F06B";
}

.fa-gift-card:before {
  content: "\F663";
}

.fa-gifts:before {
  content: "\F79C";
}

.fa-gingerbread-man:before {
  content: "\F79D";
}

.fa-git:before {
  content: "\F1D3";
}

.fa-git-alt:before {
  content: "\F841";
}

.fa-git-square:before {
  content: "\F1D2";
}

.fa-github:before {
  content: "\F09B";
}

.fa-github-alt:before {
  content: "\F113";
}

.fa-github-square:before {
  content: "\F092";
}

.fa-gitkraken:before {
  content: "\F3A6";
}

.fa-gitlab:before {
  content: "\F296";
}

.fa-gitter:before {
  content: "\F426";
}

.fa-glass:before {
  content: "\F804";
}

.fa-glass-champagne:before {
  content: "\F79E";
}

.fa-glass-cheers:before {
  content: "\F79F";
}

.fa-glass-citrus:before {
  content: "\F869";
}

.fa-glass-martini:before {
  content: "\F000";
}

.fa-glass-martini-alt:before {
  content: "\F57B";
}

.fa-glass-whiskey:before {
  content: "\F7A0";
}

.fa-glass-whiskey-rocks:before {
  content: "\F7A1";
}

.fa-glasses:before {
  content: "\F530";
}

.fa-glasses-alt:before {
  content: "\F5F5";
}

.fa-glide:before {
  content: "\F2A5";
}

.fa-glide-g:before {
  content: "\F2A6";
}

.fa-globe:before {
  content: "\F0AC";
}

.fa-globe-africa:before {
  content: "\F57C";
}

.fa-globe-americas:before {
  content: "\F57D";
}

.fa-globe-asia:before {
  content: "\F57E";
}

.fa-globe-europe:before {
  content: "\F7A2";
}

.fa-globe-snow:before {
  content: "\F7A3";
}

.fa-globe-stand:before {
  content: "\F5F6";
}

.fa-gofore:before {
  content: "\F3A7";
}

.fa-golf-ball:before {
  content: "\F450";
}

.fa-golf-club:before {
  content: "\F451";
}

.fa-goodreads:before {
  content: "\F3A8";
}

.fa-goodreads-g:before {
  content: "\F3A9";
}

.fa-google:before {
  content: "\F1A0";
}

.fa-google-drive:before {
  content: "\F3AA";
}

.fa-google-play:before {
  content: "\F3AB";
}

.fa-google-plus:before {
  content: "\F2B3";
}

.fa-google-plus-g:before {
  content: "\F0D5";
}

.fa-google-plus-square:before {
  content: "\F0D4";
}

.fa-google-wallet:before {
  content: "\F1EE";
}

.fa-gopuram:before {
  content: "\F664";
}

.fa-graduation-cap:before {
  content: "\F19D";
}

.fa-gratipay:before {
  content: "\F184";
}

.fa-grav:before {
  content: "\F2D6";
}

.fa-greater-than:before {
  content: "\F531";
}

.fa-greater-than-equal:before {
  content: "\F532";
}

.fa-grimace:before {
  content: "\F57F";
}

.fa-grin:before {
  content: "\F580";
}

.fa-grin-alt:before {
  content: "\F581";
}

.fa-grin-beam:before {
  content: "\F582";
}

.fa-grin-beam-sweat:before {
  content: "\F583";
}

.fa-grin-hearts:before {
  content: "\F584";
}

.fa-grin-squint:before {
  content: "\F585";
}

.fa-grin-squint-tears:before {
  content: "\F586";
}

.fa-grin-stars:before {
  content: "\F587";
}

.fa-grin-tears:before {
  content: "\F588";
}

.fa-grin-tongue:before {
  content: "\F589";
}

.fa-grin-tongue-squint:before {
  content: "\F58A";
}

.fa-grin-tongue-wink:before {
  content: "\F58B";
}

.fa-grin-wink:before {
  content: "\F58C";
}

.fa-grip-horizontal:before {
  content: "\F58D";
}

.fa-grip-lines:before {
  content: "\F7A4";
}

.fa-grip-lines-vertical:before {
  content: "\F7A5";
}

.fa-grip-vertical:before {
  content: "\F58E";
}

.fa-gripfire:before {
  content: "\F3AC";
}

.fa-grunt:before {
  content: "\F3AD";
}

.fa-guitar:before {
  content: "\F7A6";
}

.fa-gulp:before {
  content: "\F3AE";
}

.fa-h-square:before {
  content: "\F0FD";
}

.fa-h1:before {
  content: "\F313";
}

.fa-h2:before {
  content: "\F314";
}

.fa-h3:before {
  content: "\F315";
}

.fa-h4:before {
  content: "\F86A";
}

.fa-hacker-news:before {
  content: "\F1D4";
}

.fa-hacker-news-square:before {
  content: "\F3AF";
}

.fa-hackerrank:before {
  content: "\F5F7";
}

.fa-hamburger:before {
  content: "\F805";
}

.fa-hammer:before {
  content: "\F6E3";
}

.fa-hammer-war:before {
  content: "\F6E4";
}

.fa-hamsa:before {
  content: "\F665";
}

.fa-hand-heart:before {
  content: "\F4BC";
}

.fa-hand-holding:before {
  content: "\F4BD";
}

.fa-hand-holding-box:before {
  content: "\F47B";
}

.fa-hand-holding-heart:before {
  content: "\F4BE";
}

.fa-hand-holding-magic:before {
  content: "\F6E5";
}

.fa-hand-holding-seedling:before {
  content: "\F4BF";
}

.fa-hand-holding-usd:before {
  content: "\F4C0";
}

.fa-hand-holding-water:before {
  content: "\F4C1";
}

.fa-hand-lizard:before {
  content: "\F258";
}

.fa-hand-middle-finger:before {
  content: "\F806";
}

.fa-hand-paper:before {
  content: "\F256";
}

.fa-hand-peace:before {
  content: "\F25B";
}

.fa-hand-point-down:before {
  content: "\F0A7";
}

.fa-hand-point-left:before {
  content: "\F0A5";
}

.fa-hand-point-right:before {
  content: "\F0A4";
}

.fa-hand-point-up:before {
  content: "\F0A6";
}

.fa-hand-pointer:before {
  content: "\F25A";
}

.fa-hand-receiving:before {
  content: "\F47C";
}

.fa-hand-rock:before {
  content: "\F255";
}

.fa-hand-scissors:before {
  content: "\F257";
}

.fa-hand-spock:before {
  content: "\F259";
}

.fa-hands:before {
  content: "\F4C2";
}

.fa-hands-heart:before {
  content: "\F4C3";
}

.fa-hands-helping:before {
  content: "\F4C4";
}

.fa-hands-usd:before {
  content: "\F4C5";
}

.fa-handshake:before {
  content: "\F2B5";
}

.fa-handshake-alt:before {
  content: "\F4C6";
}

.fa-hanukiah:before {
  content: "\F6E6";
}

.fa-hard-hat:before {
  content: "\F807";
}

.fa-hashtag:before {
  content: "\F292";
}

.fa-hat-chef:before {
  content: "\F86B";
}

.fa-hat-santa:before {
  content: "\F7A7";
}

.fa-hat-winter:before {
  content: "\F7A8";
}

.fa-hat-witch:before {
  content: "\F6E7";
}

.fa-hat-wizard:before {
  content: "\F6E8";
}

.fa-haykal:before {
  content: "\F666";
}

.fa-hdd:before {
  content: "\F0A0";
}

.fa-head-side:before {
  content: "\F6E9";
}

.fa-head-side-brain:before {
  content: "\F808";
}

.fa-head-side-medical:before {
  content: "\F809";
}

.fa-head-vr:before {
  content: "\F6EA";
}

.fa-heading:before {
  content: "\F1DC";
}

.fa-headphones:before {
  content: "\F025";
}

.fa-headphones-alt:before {
  content: "\F58F";
}

.fa-headset:before {
  content: "\F590";
}

.fa-heart:before {
  content: "\F004";
}

.fa-heart-broken:before {
  content: "\F7A9";
}

.fa-heart-circle:before {
  content: "\F4C7";
}

.fa-heart-rate:before {
  content: "\F5F8";
}

.fa-heart-square:before {
  content: "\F4C8";
}

.fa-heartbeat:before {
  content: "\F21E";
}

.fa-helicopter:before {
  content: "\F533";
}

.fa-helmet-battle:before {
  content: "\F6EB";
}

.fa-hexagon:before {
  content: "\F312";
}

.fa-highlighter:before {
  content: "\F591";
}

.fa-hiking:before {
  content: "\F6EC";
}

.fa-hippo:before {
  content: "\F6ED";
}

.fa-hips:before {
  content: "\F452";
}

.fa-hire-a-helper:before {
  content: "\F3B0";
}

.fa-history:before {
  content: "\F1DA";
}

.fa-hockey-mask:before {
  content: "\F6EE";
}

.fa-hockey-puck:before {
  content: "\F453";
}

.fa-hockey-sticks:before {
  content: "\F454";
}

.fa-holly-berry:before {
  content: "\F7AA";
}

.fa-home:before {
  content: "\F015";
}

.fa-home-alt:before {
  content: "\F80A";
}

.fa-home-heart:before {
  content: "\F4C9";
}

.fa-home-lg:before {
  content: "\F80B";
}

.fa-home-lg-alt:before {
  content: "\F80C";
}

.fa-hood-cloak:before {
  content: "\F6EF";
}

.fa-hooli:before {
  content: "\F427";
}

.fa-horizontal-rule:before {
  content: "\F86C";
}

.fa-hornbill:before {
  content: "\F592";
}

.fa-horse:before {
  content: "\F6F0";
}

.fa-horse-head:before {
  content: "\F7AB";
}

.fa-hospital:before {
  content: "\F0F8";
}

.fa-hospital-alt:before {
  content: "\F47D";
}

.fa-hospital-symbol:before {
  content: "\F47E";
}

.fa-hospital-user:before {
  content: "\F80D";
}

.fa-hospitals:before {
  content: "\F80E";
}

.fa-hot-tub:before {
  content: "\F593";
}

.fa-hotdog:before {
  content: "\F80F";
}

.fa-hotel:before {
  content: "\F594";
}

.fa-hotjar:before {
  content: "\F3B1";
}

.fa-hourglass:before {
  content: "\F254";
}

.fa-hourglass-end:before {
  content: "\F253";
}

.fa-hourglass-half:before {
  content: "\F252";
}

.fa-hourglass-start:before {
  content: "\F251";
}

.fa-house-damage:before {
  content: "\F6F1";
}

.fa-house-flood:before {
  content: "\F74F";
}

.fa-houzz:before {
  content: "\F27C";
}

.fa-hryvnia:before {
  content: "\F6F2";
}

.fa-html5:before {
  content: "\F13B";
}

.fa-hubspot:before {
  content: "\F3B2";
}

.fa-humidity:before {
  content: "\F750";
}

.fa-hurricane:before {
  content: "\F751";
}

.fa-i-cursor:before {
  content: "\F246";
}

.fa-ice-cream:before {
  content: "\F810";
}

.fa-ice-skate:before {
  content: "\F7AC";
}

.fa-icicles:before {
  content: "\F7AD";
}

.fa-icons:before {
  content: "\F86D";
}

.fa-icons-alt:before {
  content: "\F86E";
}

.fa-id-badge:before {
  content: "\F2C1";
}

.fa-id-card:before {
  content: "\F2C2";
}

.fa-id-card-alt:before {
  content: "\F47F";
}

.fa-igloo:before {
  content: "\F7AE";
}

.fa-image:before {
  content: "\F03E";
}

.fa-images:before {
  content: "\F302";
}

.fa-imdb:before {
  content: "\F2D8";
}

.fa-inbox:before {
  content: "\F01C";
}

.fa-inbox-in:before {
  content: "\F310";
}

.fa-inbox-out:before {
  content: "\F311";
}

.fa-indent:before {
  content: "\F03C";
}

.fa-industry:before {
  content: "\F275";
}

.fa-industry-alt:before {
  content: "\F3B3";
}

.fa-infinity:before {
  content: "\F534";
}

.fa-info:before {
  content: "\F129";
}

.fa-info-circle:before {
  content: "\F05A";
}

.fa-info-square:before {
  content: "\F30F";
}

.fa-inhaler:before {
  content: "\F5F9";
}

.fa-instagram:before {
  content: "\F16D";
}

.fa-integral:before {
  content: "\F667";
}

.fa-intercom:before {
  content: "\F7AF";
}

.fa-internet-explorer:before {
  content: "\F26B";
}

.fa-intersection:before {
  content: "\F668";
}

.fa-inventory:before {
  content: "\F480";
}

.fa-invision:before {
  content: "\F7B0";
}

.fa-ioxhost:before {
  content: "\F208";
}

.fa-island-tropical:before {
  content: "\F811";
}

.fa-italic:before {
  content: "\F033";
}

.fa-itch-io:before {
  content: "\F83A";
}

.fa-itunes:before {
  content: "\F3B4";
}

.fa-itunes-note:before {
  content: "\F3B5";
}

.fa-jack-o-lantern:before {
  content: "\F30E";
}

.fa-java:before {
  content: "\F4E4";
}

.fa-jedi:before {
  content: "\F669";
}

.fa-jedi-order:before {
  content: "\F50E";
}

.fa-jenkins:before {
  content: "\F3B6";
}

.fa-jira:before {
  content: "\F7B1";
}

.fa-joget:before {
  content: "\F3B7";
}

.fa-joint:before {
  content: "\F595";
}

.fa-joomla:before {
  content: "\F1AA";
}

.fa-journal-whills:before {
  content: "\F66A";
}

.fa-js:before {
  content: "\F3B8";
}

.fa-js-square:before {
  content: "\F3B9";
}

.fa-jsfiddle:before {
  content: "\F1CC";
}

.fa-kaaba:before {
  content: "\F66B";
}

.fa-kaggle:before {
  content: "\F5FA";
}

.fa-kerning:before {
  content: "\F86F";
}

.fa-key:before {
  content: "\F084";
}

.fa-key-skeleton:before {
  content: "\F6F3";
}

.fa-keybase:before {
  content: "\F4F5";
}

.fa-keyboard:before {
  content: "\F11C";
}

.fa-keycdn:before {
  content: "\F3BA";
}

.fa-keynote:before {
  content: "\F66C";
}

.fa-khanda:before {
  content: "\F66D";
}

.fa-kickstarter:before {
  content: "\F3BB";
}

.fa-kickstarter-k:before {
  content: "\F3BC";
}

.fa-kidneys:before {
  content: "\F5FB";
}

.fa-kiss:before {
  content: "\F596";
}

.fa-kiss-beam:before {
  content: "\F597";
}

.fa-kiss-wink-heart:before {
  content: "\F598";
}

.fa-kite:before {
  content: "\F6F4";
}

.fa-kiwi-bird:before {
  content: "\F535";
}

.fa-knife-kitchen:before {
  content: "\F6F5";
}

.fa-korvue:before {
  content: "\F42F";
}

.fa-lambda:before {
  content: "\F66E";
}

.fa-lamp:before {
  content: "\F4CA";
}

.fa-landmark:before {
  content: "\F66F";
}

.fa-landmark-alt:before {
  content: "\F752";
}

.fa-language:before {
  content: "\F1AB";
}

.fa-laptop:before {
  content: "\F109";
}

.fa-laptop-code:before {
  content: "\F5FC";
}

.fa-laptop-medical:before {
  content: "\F812";
}

.fa-laravel:before {
  content: "\F3BD";
}

.fa-lastfm:before {
  content: "\F202";
}

.fa-lastfm-square:before {
  content: "\F203";
}

.fa-laugh:before {
  content: "\F599";
}

.fa-laugh-beam:before {
  content: "\F59A";
}

.fa-laugh-squint:before {
  content: "\F59B";
}

.fa-laugh-wink:before {
  content: "\F59C";
}

.fa-layer-group:before {
  content: "\F5FD";
}

.fa-layer-minus:before {
  content: "\F5FE";
}

.fa-layer-plus:before {
  content: "\F5FF";
}

.fa-leaf:before {
  content: "\F06C";
}

.fa-leaf-heart:before {
  content: "\F4CB";
}

.fa-leaf-maple:before {
  content: "\F6F6";
}

.fa-leaf-oak:before {
  content: "\F6F7";
}

.fa-leanpub:before {
  content: "\F212";
}

.fa-lemon:before {
  content: "\F094";
}

.fa-less:before {
  content: "\F41D";
}

.fa-less-than:before {
  content: "\F536";
}

.fa-less-than-equal:before {
  content: "\F537";
}

.fa-level-down:before {
  content: "\F149";
}

.fa-level-down-alt:before {
  content: "\F3BE";
}

.fa-level-up:before {
  content: "\F148";
}

.fa-level-up-alt:before {
  content: "\F3BF";
}

.fa-life-ring:before {
  content: "\F1CD";
}

.fa-lightbulb:before {
  content: "\F0EB";
}

.fa-lightbulb-dollar:before {
  content: "\F670";
}

.fa-lightbulb-exclamation:before {
  content: "\F671";
}

.fa-lightbulb-on:before {
  content: "\F672";
}

.fa-lightbulb-slash:before {
  content: "\F673";
}

.fa-lights-holiday:before {
  content: "\F7B2";
}

.fa-line:before {
  content: "\F3C0";
}

.fa-line-columns:before {
  content: "\F870";
}

.fa-line-height:before {
  content: "\F871";
}

.fa-link:before {
  content: "\F0C1";
}

.fa-linkedin:before {
  content: "\F08C";
}

.fa-linkedin-in:before {
  content: "\F0E1";
}

.fa-linode:before {
  content: "\F2B8";
}

.fa-linux:before {
  content: "\F17C";
}

.fa-lips:before {
  content: "\F600";
}

.fa-lira-sign:before {
  content: "\F195";
}

.fa-list:before {
  content: "\F03A";
}

.fa-list-alt:before {
  content: "\F022";
}

.fa-list-ol:before {
  content: "\F0CB";
}

.fa-list-ul:before {
  content: "\F0CA";
}

.fa-location:before {
  content: "\F601";
}

.fa-location-arrow:before {
  content: "\F124";
}

.fa-location-circle:before {
  content: "\F602";
}

.fa-location-slash:before {
  content: "\F603";
}

.fa-lock:before {
  content: "\F023";
}

.fa-lock-alt:before {
  content: "\F30D";
}

.fa-lock-open:before {
  content: "\F3C1";
}

.fa-lock-open-alt:before {
  content: "\F3C2";
}

.fa-long-arrow-alt-down:before {
  content: "\F309";
}

.fa-long-arrow-alt-left:before {
  content: "\F30A";
}

.fa-long-arrow-alt-right:before {
  content: "\F30B";
}

.fa-long-arrow-alt-up:before {
  content: "\F30C";
}

.fa-long-arrow-down:before {
  content: "\F175";
}

.fa-long-arrow-left:before {
  content: "\F177";
}

.fa-long-arrow-right:before {
  content: "\F178";
}

.fa-long-arrow-up:before {
  content: "\F176";
}

.fa-loveseat:before {
  content: "\F4CC";
}

.fa-low-vision:before {
  content: "\F2A8";
}

.fa-luchador:before {
  content: "\F455";
}

.fa-luggage-cart:before {
  content: "\F59D";
}

.fa-lungs:before {
  content: "\F604";
}

.fa-lyft:before {
  content: "\F3C3";
}

.fa-mace:before {
  content: "\F6F8";
}

.fa-magento:before {
  content: "\F3C4";
}

.fa-magic:before {
  content: "\F0D0";
}

.fa-magnet:before {
  content: "\F076";
}

.fa-mail-bulk:before {
  content: "\F674";
}

.fa-mailbox:before {
  content: "\F813";
}

.fa-mailchimp:before {
  content: "\F59E";
}

.fa-male:before {
  content: "\F183";
}

.fa-mandalorian:before {
  content: "\F50F";
}

.fa-mandolin:before {
  content: "\F6F9";
}

.fa-map:before {
  content: "\F279";
}

.fa-map-marked:before {
  content: "\F59F";
}

.fa-map-marked-alt:before {
  content: "\F5A0";
}

.fa-map-marker:before {
  content: "\F041";
}

.fa-map-marker-alt:before {
  content: "\F3C5";
}

.fa-map-marker-alt-slash:before {
  content: "\F605";
}

.fa-map-marker-check:before {
  content: "\F606";
}

.fa-map-marker-edit:before {
  content: "\F607";
}

.fa-map-marker-exclamation:before {
  content: "\F608";
}

.fa-map-marker-minus:before {
  content: "\F609";
}

.fa-map-marker-plus:before {
  content: "\F60A";
}

.fa-map-marker-question:before {
  content: "\F60B";
}

.fa-map-marker-slash:before {
  content: "\F60C";
}

.fa-map-marker-smile:before {
  content: "\F60D";
}

.fa-map-marker-times:before {
  content: "\F60E";
}

.fa-map-pin:before {
  content: "\F276";
}

.fa-map-signs:before {
  content: "\F277";
}

.fa-markdown:before {
  content: "\F60F";
}

.fa-marker:before {
  content: "\F5A1";
}

.fa-mars:before {
  content: "\F222";
}

.fa-mars-double:before {
  content: "\F227";
}

.fa-mars-stroke:before {
  content: "\F229";
}

.fa-mars-stroke-h:before {
  content: "\F22B";
}

.fa-mars-stroke-v:before {
  content: "\F22A";
}

.fa-mask:before {
  content: "\F6FA";
}

.fa-mastodon:before {
  content: "\F4F6";
}

.fa-maxcdn:before {
  content: "\F136";
}

.fa-meat:before {
  content: "\F814";
}

.fa-medal:before {
  content: "\F5A2";
}

.fa-medapps:before {
  content: "\F3C6";
}

.fa-medium:before {
  content: "\F23A";
}

.fa-medium-m:before {
  content: "\F3C7";
}

.fa-medkit:before {
  content: "\F0FA";
}

.fa-medrt:before {
  content: "\F3C8";
}

.fa-meetup:before {
  content: "\F2E0";
}

.fa-megaphone:before {
  content: "\F675";
}

.fa-megaport:before {
  content: "\F5A3";
}

.fa-meh:before {
  content: "\F11A";
}

.fa-meh-blank:before {
  content: "\F5A4";
}

.fa-meh-rolling-eyes:before {
  content: "\F5A5";
}

.fa-memory:before {
  content: "\F538";
}

.fa-mendeley:before {
  content: "\F7B3";
}

.fa-menorah:before {
  content: "\F676";
}

.fa-mercury:before {
  content: "\F223";
}

.fa-meteor:before {
  content: "\F753";
}

.fa-microchip:before {
  content: "\F2DB";
}

.fa-microphone:before {
  content: "\F130";
}

.fa-microphone-alt:before {
  content: "\F3C9";
}

.fa-microphone-alt-slash:before {
  content: "\F539";
}

.fa-microphone-slash:before {
  content: "\F131";
}

.fa-microscope:before {
  content: "\F610";
}

.fa-microsoft:before {
  content: "\F3CA";
}

.fa-mind-share:before {
  content: "\F677";
}

.fa-minus:before {
  content: "\F068";
}

.fa-minus-circle:before {
  content: "\F056";
}

.fa-minus-hexagon:before {
  content: "\F307";
}

.fa-minus-octagon:before {
  content: "\F308";
}

.fa-minus-square:before {
  content: "\F146";
}

.fa-mistletoe:before {
  content: "\F7B4";
}

.fa-mitten:before {
  content: "\F7B5";
}

.fa-mix:before {
  content: "\F3CB";
}

.fa-mixcloud:before {
  content: "\F289";
}

.fa-mizuni:before {
  content: "\F3CC";
}

.fa-mobile:before {
  content: "\F10B";
}

.fa-mobile-alt:before {
  content: "\F3CD";
}

.fa-mobile-android:before {
  content: "\F3CE";
}

.fa-mobile-android-alt:before {
  content: "\F3CF";
}

.fa-modx:before {
  content: "\F285";
}

.fa-monero:before {
  content: "\F3D0";
}

.fa-money-bill:before {
  content: "\F0D6";
}

.fa-money-bill-alt:before {
  content: "\F3D1";
}

.fa-money-bill-wave:before {
  content: "\F53A";
}

.fa-money-bill-wave-alt:before {
  content: "\F53B";
}

.fa-money-check:before {
  content: "\F53C";
}

.fa-money-check-alt:before {
  content: "\F53D";
}

.fa-money-check-edit:before {
  content: "\F872";
}

.fa-money-check-edit-alt:before {
  content: "\F873";
}

.fa-monitor-heart-rate:before {
  content: "\F611";
}

.fa-monkey:before {
  content: "\F6FB";
}

.fa-monument:before {
  content: "\F5A6";
}

.fa-moon:before {
  content: "\F186";
}

.fa-moon-cloud:before {
  content: "\F754";
}

.fa-moon-stars:before {
  content: "\F755";
}

.fa-mortar-pestle:before {
  content: "\F5A7";
}

.fa-mosque:before {
  content: "\F678";
}

.fa-motorcycle:before {
  content: "\F21C";
}

.fa-mountain:before {
  content: "\F6FC";
}

.fa-mountains:before {
  content: "\F6FD";
}

.fa-mouse-pointer:before {
  content: "\F245";
}

.fa-mug:before {
  content: "\F874";
}

.fa-mug-hot:before {
  content: "\F7B6";
}

.fa-mug-marshmallows:before {
  content: "\F7B7";
}

.fa-mug-tea:before {
  content: "\F875";
}

.fa-music:before {
  content: "\F001";
}

.fa-napster:before {
  content: "\F3D2";
}

.fa-narwhal:before {
  content: "\F6FE";
}

.fa-neos:before {
  content: "\F612";
}

.fa-network-wired:before {
  content: "\F6FF";
}

.fa-neuter:before {
  content: "\F22C";
}

.fa-newspaper:before {
  content: "\F1EA";
}

.fa-nimblr:before {
  content: "\F5A8";
}

.fa-node:before {
  content: "\F419";
}

.fa-node-js:before {
  content: "\F3D3";
}

.fa-not-equal:before {
  content: "\F53E";
}

.fa-notes-medical:before {
  content: "\F481";
}

.fa-npm:before {
  content: "\F3D4";
}

.fa-ns8:before {
  content: "\F3D5";
}

.fa-nutritionix:before {
  content: "\F3D6";
}

.fa-object-group:before {
  content: "\F247";
}

.fa-object-ungroup:before {
  content: "\F248";
}

.fa-octagon:before {
  content: "\F306";
}

.fa-odnoklassniki:before {
  content: "\F263";
}

.fa-odnoklassniki-square:before {
  content: "\F264";
}

.fa-oil-can:before {
  content: "\F613";
}

.fa-oil-temp:before {
  content: "\F614";
}

.fa-old-republic:before {
  content: "\F510";
}

.fa-om:before {
  content: "\F679";
}

.fa-omega:before {
  content: "\F67A";
}

.fa-opencart:before {
  content: "\F23D";
}

.fa-openid:before {
  content: "\F19B";
}

.fa-opera:before {
  content: "\F26A";
}

.fa-optin-monster:before {
  content: "\F23C";
}

.fa-ornament:before {
  content: "\F7B8";
}

.fa-osi:before {
  content: "\F41A";
}

.fa-otter:before {
  content: "\F700";
}

.fa-outdent:before {
  content: "\F03B";
}

.fa-overline:before {
  content: "\F876";
}

.fa-page-break:before {
  content: "\F877";
}

.fa-page4:before {
  content: "\F3D7";
}

.fa-pagelines:before {
  content: "\F18C";
}

.fa-pager:before {
  content: "\F815";
}

.fa-paint-brush:before {
  content: "\F1FC";
}

.fa-paint-brush-alt:before {
  content: "\F5A9";
}

.fa-paint-roller:before {
  content: "\F5AA";
}

.fa-palette:before {
  content: "\F53F";
}

.fa-palfed:before {
  content: "\F3D8";
}

.fa-pallet:before {
  content: "\F482";
}

.fa-pallet-alt:before {
  content: "\F483";
}

.fa-paper-plane:before {
  content: "\F1D8";
}

.fa-paperclip:before {
  content: "\F0C6";
}

.fa-parachute-box:before {
  content: "\F4CD";
}

.fa-paragraph:before {
  content: "\F1DD";
}

.fa-paragraph-rtl:before {
  content: "\F878";
}

.fa-parking:before {
  content: "\F540";
}

.fa-parking-circle:before {
  content: "\F615";
}

.fa-parking-circle-slash:before {
  content: "\F616";
}

.fa-parking-slash:before {
  content: "\F617";
}

.fa-passport:before {
  content: "\F5AB";
}

.fa-pastafarianism:before {
  content: "\F67B";
}

.fa-paste:before {
  content: "\F0EA";
}

.fa-patreon:before {
  content: "\F3D9";
}

.fa-pause:before {
  content: "\F04C";
}

.fa-pause-circle:before {
  content: "\F28B";
}

.fa-paw:before {
  content: "\F1B0";
}

.fa-paw-alt:before {
  content: "\F701";
}

.fa-paw-claws:before {
  content: "\F702";
}

.fa-paypal:before {
  content: "\F1ED";
}

.fa-peace:before {
  content: "\F67C";
}

.fa-pegasus:before {
  content: "\F703";
}

.fa-pen:before {
  content: "\F304";
}

.fa-pen-alt:before {
  content: "\F305";
}

.fa-pen-fancy:before {
  content: "\F5AC";
}

.fa-pen-nib:before {
  content: "\F5AD";
}

.fa-pen-square:before {
  content: "\F14B";
}

.fa-pencil:before {
  content: "\F040";
}

.fa-pencil-alt:before {
  content: "\F303";
}

.fa-pencil-paintbrush:before {
  content: "\F618";
}

.fa-pencil-ruler:before {
  content: "\F5AE";
}

.fa-pennant:before {
  content: "\F456";
}

.fa-penny-arcade:before {
  content: "\F704";
}

.fa-people-carry:before {
  content: "\F4CE";
}

.fa-pepper-hot:before {
  content: "\F816";
}

.fa-percent:before {
  content: "\F295";
}

.fa-percentage:before {
  content: "\F541";
}

.fa-periscope:before {
  content: "\F3DA";
}

.fa-person-booth:before {
  content: "\F756";
}

.fa-person-carry:before {
  content: "\F4CF";
}

.fa-person-dolly:before {
  content: "\F4D0";
}

.fa-person-dolly-empty:before {
  content: "\F4D1";
}

.fa-person-sign:before {
  content: "\F757";
}

.fa-phabricator:before {
  content: "\F3DB";
}

.fa-phoenix-framework:before {
  content: "\F3DC";
}

.fa-phoenix-squadron:before {
  content: "\F511";
}

.fa-phone:before {
  content: "\F095";
}

.fa-phone-alt:before {
  content: "\F879";
}

.fa-phone-laptop:before {
  content: "\F87A";
}

.fa-phone-office:before {
  content: "\F67D";
}

.fa-phone-plus:before {
  content: "\F4D2";
}

.fa-phone-slash:before {
  content: "\F3DD";
}

.fa-phone-square:before {
  content: "\F098";
}

.fa-phone-square-alt:before {
  content: "\F87B";
}

.fa-phone-volume:before {
  content: "\F2A0";
}

.fa-photo-video:before {
  content: "\F87C";
}

.fa-php:before {
  content: "\F457";
}

.fa-pi:before {
  content: "\F67E";
}

.fa-pie:before {
  content: "\F705";
}

.fa-pied-piper:before {
  content: "\F2AE";
}

.fa-pied-piper-alt:before {
  content: "\F1A8";
}

.fa-pied-piper-hat:before {
  content: "\F4E5";
}

.fa-pied-piper-pp:before {
  content: "\F1A7";
}

.fa-pig:before {
  content: "\F706";
}

.fa-piggy-bank:before {
  content: "\F4D3";
}

.fa-pills:before {
  content: "\F484";
}

.fa-pinterest:before {
  content: "\F0D2";
}

.fa-pinterest-p:before {
  content: "\F231";
}

.fa-pinterest-square:before {
  content: "\F0D3";
}

.fa-pizza:before {
  content: "\F817";
}

.fa-pizza-slice:before {
  content: "\F818";
}

.fa-place-of-worship:before {
  content: "\F67F";
}

.fa-plane:before {
  content: "\F072";
}

.fa-plane-alt:before {
  content: "\F3DE";
}

.fa-plane-arrival:before {
  content: "\F5AF";
}

.fa-plane-departure:before {
  content: "\F5B0";
}

.fa-play:before {
  content: "\F04B";
}

.fa-play-circle:before {
  content: "\F144";
}

.fa-playstation:before {
  content: "\F3DF";
}

.fa-plug:before {
  content: "\F1E6";
}

.fa-plus:before {
  content: "\F067";
}

.fa-plus-circle:before {
  content: "\F055";
}

.fa-plus-hexagon:before {
  content: "\F300";
}

.fa-plus-octagon:before {
  content: "\F301";
}

.fa-plus-square:before {
  content: "\F0FE";
}

.fa-podcast:before {
  content: "\F2CE";
}

.fa-podium:before {
  content: "\F680";
}

.fa-podium-star:before {
  content: "\F758";
}

.fa-poll:before {
  content: "\F681";
}

.fa-poll-h:before {
  content: "\F682";
}

.fa-poll-people:before {
  content: "\F759";
}

.fa-poo:before {
  content: "\F2FE";
}

.fa-poo-storm:before {
  content: "\F75A";
}

.fa-poop:before {
  content: "\F619";
}

.fa-popcorn:before {
  content: "\F819";
}

.fa-portrait:before {
  content: "\F3E0";
}

.fa-pound-sign:before {
  content: "\F154";
}

.fa-power-off:before {
  content: "\F011";
}

.fa-pray:before {
  content: "\F683";
}

.fa-praying-hands:before {
  content: "\F684";
}

.fa-prescription:before {
  content: "\F5B1";
}

.fa-prescription-bottle:before {
  content: "\F485";
}

.fa-prescription-bottle-alt:before {
  content: "\F486";
}

.fa-presentation:before {
  content: "\F685";
}

.fa-print:before {
  content: "\F02F";
}

.fa-print-search:before {
  content: "\F81A";
}

.fa-print-slash:before {
  content: "\F686";
}

.fa-procedures:before {
  content: "\F487";
}

.fa-product-hunt:before {
  content: "\F288";
}

.fa-project-diagram:before {
  content: "\F542";
}

.fa-pumpkin:before {
  content: "\F707";
}

.fa-pushed:before {
  content: "\F3E1";
}

.fa-puzzle-piece:before {
  content: "\F12E";
}

.fa-python:before {
  content: "\F3E2";
}

.fa-qq:before {
  content: "\F1D6";
}

.fa-qrcode:before {
  content: "\F029";
}

.fa-question:before {
  content: "\F128";
}

.fa-question-circle:before {
  content: "\F059";
}

.fa-question-square:before {
  content: "\F2FD";
}

.fa-quidditch:before {
  content: "\F458";
}

.fa-quinscape:before {
  content: "\F459";
}

.fa-quora:before {
  content: "\F2C4";
}

.fa-quote-left:before {
  content: "\F10D";
}

.fa-quote-right:before {
  content: "\F10E";
}

.fa-quran:before {
  content: "\F687";
}

.fa-r-project:before {
  content: "\F4F7";
}

.fa-rabbit:before {
  content: "\F708";
}

.fa-rabbit-fast:before {
  content: "\F709";
}

.fa-racquet:before {
  content: "\F45A";
}

.fa-radiation:before {
  content: "\F7B9";
}

.fa-radiation-alt:before {
  content: "\F7BA";
}

.fa-rainbow:before {
  content: "\F75B";
}

.fa-raindrops:before {
  content: "\F75C";
}

.fa-ram:before {
  content: "\F70A";
}

.fa-ramp-loading:before {
  content: "\F4D4";
}

.fa-random:before {
  content: "\F074";
}

.fa-raspberry-pi:before {
  content: "\F7BB";
}

.fa-ravelry:before {
  content: "\F2D9";
}

.fa-react:before {
  content: "\F41B";
}

.fa-reacteurope:before {
  content: "\F75D";
}

.fa-readme:before {
  content: "\F4D5";
}

.fa-rebel:before {
  content: "\F1D0";
}

.fa-receipt:before {
  content: "\F543";
}

.fa-rectangle-landscape:before {
  content: "\F2FA";
}

.fa-rectangle-portrait:before {
  content: "\F2FB";
}

.fa-rectangle-wide:before {
  content: "\F2FC";
}

.fa-recycle:before {
  content: "\F1B8";
}

.fa-red-river:before {
  content: "\F3E3";
}

.fa-reddit:before {
  content: "\F1A1";
}

.fa-reddit-alien:before {
  content: "\F281";
}

.fa-reddit-square:before {
  content: "\F1A2";
}

.fa-redhat:before {
  content: "\F7BC";
}

.fa-redo:before {
  content: "\F01E";
}

.fa-redo-alt:before {
  content: "\F2F9";
}

.fa-registered:before {
  content: "\F25D";
}

.fa-remove-format:before {
  content: "\F87D";
}

.fa-renren:before {
  content: "\F18B";
}

.fa-repeat:before {
  content: "\F363";
}

.fa-repeat-1:before {
  content: "\F365";
}

.fa-repeat-1-alt:before {
  content: "\F366";
}

.fa-repeat-alt:before {
  content: "\F364";
}

.fa-reply:before {
  content: "\F3E5";
}

.fa-reply-all:before {
  content: "\F122";
}

.fa-replyd:before {
  content: "\F3E6";
}

.fa-republican:before {
  content: "\F75E";
}

.fa-researchgate:before {
  content: "\F4F8";
}

.fa-resolving:before {
  content: "\F3E7";
}

.fa-restroom:before {
  content: "\F7BD";
}

.fa-retweet:before {
  content: "\F079";
}

.fa-retweet-alt:before {
  content: "\F361";
}

.fa-rev:before {
  content: "\F5B2";
}

.fa-ribbon:before {
  content: "\F4D6";
}

.fa-ring:before {
  content: "\F70B";
}

.fa-rings-wedding:before {
  content: "\F81B";
}

.fa-road:before {
  content: "\F018";
}

.fa-robot:before {
  content: "\F544";
}

.fa-rocket:before {
  content: "\F135";
}

.fa-rocketchat:before {
  content: "\F3E8";
}

.fa-rockrms:before {
  content: "\F3E9";
}

.fa-route:before {
  content: "\F4D7";
}

.fa-route-highway:before {
  content: "\F61A";
}

.fa-route-interstate:before {
  content: "\F61B";
}

.fa-rss:before {
  content: "\F09E";
}

.fa-rss-square:before {
  content: "\F143";
}

.fa-ruble-sign:before {
  content: "\F158";
}

.fa-ruler:before {
  content: "\F545";
}

.fa-ruler-combined:before {
  content: "\F546";
}

.fa-ruler-horizontal:before {
  content: "\F547";
}

.fa-ruler-triangle:before {
  content: "\F61C";
}

.fa-ruler-vertical:before {
  content: "\F548";
}

.fa-running:before {
  content: "\F70C";
}

.fa-rupee-sign:before {
  content: "\F156";
}

.fa-rv:before {
  content: "\F7BE";
}

.fa-sack:before {
  content: "\F81C";
}

.fa-sack-dollar:before {
  content: "\F81D";
}

.fa-sad-cry:before {
  content: "\F5B3";
}

.fa-sad-tear:before {
  content: "\F5B4";
}

.fa-safari:before {
  content: "\F267";
}

.fa-salad:before {
  content: "\F81E";
}

.fa-salesforce:before {
  content: "\F83B";
}

.fa-sandwich:before {
  content: "\F81F";
}

.fa-sass:before {
  content: "\F41E";
}

.fa-satellite:before {
  content: "\F7BF";
}

.fa-satellite-dish:before {
  content: "\F7C0";
}

.fa-sausage:before {
  content: "\F820";
}

.fa-save:before {
  content: "\F0C7";
}

.fa-scalpel:before {
  content: "\F61D";
}

.fa-scalpel-path:before {
  content: "\F61E";
}

.fa-scanner:before {
  content: "\F488";
}

.fa-scanner-keyboard:before {
  content: "\F489";
}

.fa-scanner-touchscreen:before {
  content: "\F48A";
}

.fa-scarecrow:before {
  content: "\F70D";
}

.fa-scarf:before {
  content: "\F7C1";
}

.fa-schlix:before {
  content: "\F3EA";
}

.fa-school:before {
  content: "\F549";
}

.fa-screwdriver:before {
  content: "\F54A";
}

.fa-scribd:before {
  content: "\F28A";
}

.fa-scroll:before {
  content: "\F70E";
}

.fa-scroll-old:before {
  content: "\F70F";
}

.fa-scrubber:before {
  content: "\F2F8";
}

.fa-scythe:before {
  content: "\F710";
}

.fa-sd-card:before {
  content: "\F7C2";
}

.fa-search:before {
  content: "\F002";
}

.fa-search-dollar:before {
  content: "\F688";
}

.fa-search-location:before {
  content: "\F689";
}

.fa-search-minus:before {
  content: "\F010";
}

.fa-search-plus:before {
  content: "\F00E";
}

.fa-searchengin:before {
  content: "\F3EB";
}

.fa-seedling:before {
  content: "\F4D8";
}

.fa-sellcast:before {
  content: "\F2DA";
}

.fa-sellsy:before {
  content: "\F213";
}

.fa-send-back:before {
  content: "\F87E";
}

.fa-send-backward:before {
  content: "\F87F";
}

.fa-server:before {
  content: "\F233";
}

.fa-servicestack:before {
  content: "\F3EC";
}

.fa-shapes:before {
  content: "\F61F";
}

.fa-share:before {
  content: "\F064";
}

.fa-share-all:before {
  content: "\F367";
}

.fa-share-alt:before {
  content: "\F1E0";
}

.fa-share-alt-square:before {
  content: "\F1E1";
}

.fa-share-square:before {
  content: "\F14D";
}

.fa-sheep:before {
  content: "\F711";
}

.fa-shekel-sign:before {
  content: "\F20B";
}

.fa-shield:before {
  content: "\F132";
}

.fa-shield-alt:before {
  content: "\F3ED";
}

.fa-shield-check:before {
  content: "\F2F7";
}

.fa-shield-cross:before {
  content: "\F712";
}

.fa-ship:before {
  content: "\F21A";
}

.fa-shipping-fast:before {
  content: "\F48B";
}

.fa-shipping-timed:before {
  content: "\F48C";
}

.fa-shirtsinbulk:before {
  content: "\F214";
}

.fa-shish-kebab:before {
  content: "\F821";
}

.fa-shoe-prints:before {
  content: "\F54B";
}

.fa-shopping-bag:before {
  content: "\F290";
}

.fa-shopping-basket:before {
  content: "\F291";
}

.fa-shopping-cart:before {
  content: "\F07A";
}

.fa-shopware:before {
  content: "\F5B5";
}

.fa-shovel:before {
  content: "\F713";
}

.fa-shovel-snow:before {
  content: "\F7C3";
}

.fa-shower:before {
  content: "\F2CC";
}

.fa-shredder:before {
  content: "\F68A";
}

.fa-shuttle-van:before {
  content: "\F5B6";
}

.fa-shuttlecock:before {
  content: "\F45B";
}

.fa-sickle:before {
  content: "\F822";
}

.fa-sigma:before {
  content: "\F68B";
}

.fa-sign:before {
  content: "\F4D9";
}

.fa-sign-in:before {
  content: "\F090";
}

.fa-sign-in-alt:before {
  content: "\F2F6";
}

.fa-sign-language:before {
  content: "\F2A7";
}

.fa-sign-out:before {
  content: "\F08B";
}

.fa-sign-out-alt:before {
  content: "\F2F5";
}

.fa-signal:before {
  content: "\F012";
}

.fa-signal-1:before {
  content: "\F68C";
}

.fa-signal-2:before {
  content: "\F68D";
}

.fa-signal-3:before {
  content: "\F68E";
}

.fa-signal-4:before {
  content: "\F68F";
}

.fa-signal-alt:before {
  content: "\F690";
}

.fa-signal-alt-1:before {
  content: "\F691";
}

.fa-signal-alt-2:before {
  content: "\F692";
}

.fa-signal-alt-3:before {
  content: "\F693";
}

.fa-signal-alt-slash:before {
  content: "\F694";
}

.fa-signal-slash:before {
  content: "\F695";
}

.fa-signature:before {
  content: "\F5B7";
}

.fa-sim-card:before {
  content: "\F7C4";
}

.fa-simplybuilt:before {
  content: "\F215";
}

.fa-sistrix:before {
  content: "\F3EE";
}

.fa-sitemap:before {
  content: "\F0E8";
}

.fa-sith:before {
  content: "\F512";
}

.fa-skating:before {
  content: "\F7C5";
}

.fa-skeleton:before {
  content: "\F620";
}

.fa-sketch:before {
  content: "\F7C6";
}

.fa-ski-jump:before {
  content: "\F7C7";
}

.fa-ski-lift:before {
  content: "\F7C8";
}

.fa-skiing:before {
  content: "\F7C9";
}

.fa-skiing-nordic:before {
  content: "\F7CA";
}

.fa-skull:before {
  content: "\F54C";
}

.fa-skull-crossbones:before {
  content: "\F714";
}

.fa-skyatlas:before {
  content: "\F216";
}

.fa-skype:before {
  content: "\F17E";
}

.fa-slack:before {
  content: "\F198";
}

.fa-slack-hash:before {
  content: "\F3EF";
}

.fa-slash:before {
  content: "\F715";
}

.fa-sledding:before {
  content: "\F7CB";
}

.fa-sleigh:before {
  content: "\F7CC";
}

.fa-sliders-h:before {
  content: "\F1DE";
}

.fa-sliders-h-square:before {
  content: "\F3F0";
}

.fa-sliders-v:before {
  content: "\F3F1";
}

.fa-sliders-v-square:before {
  content: "\F3F2";
}

.fa-slideshare:before {
  content: "\F1E7";
}

.fa-smile:before {
  content: "\F118";
}

.fa-smile-beam:before {
  content: "\F5B8";
}

.fa-smile-plus:before {
  content: "\F5B9";
}

.fa-smile-wink:before {
  content: "\F4DA";
}

.fa-smog:before {
  content: "\F75F";
}

.fa-smoke:before {
  content: "\F760";
}

.fa-smoking:before {
  content: "\F48D";
}

.fa-smoking-ban:before {
  content: "\F54D";
}

.fa-sms:before {
  content: "\F7CD";
}

.fa-snake:before {
  content: "\F716";
}

.fa-snapchat:before {
  content: "\F2AB";
}

.fa-snapchat-ghost:before {
  content: "\F2AC";
}

.fa-snapchat-square:before {
  content: "\F2AD";
}

.fa-snooze:before {
  content: "\F880";
}

.fa-snow-blowing:before {
  content: "\F761";
}

.fa-snowboarding:before {
  content: "\F7CE";
}

.fa-snowflake:before {
  content: "\F2DC";
}

.fa-snowflakes:before {
  content: "\F7CF";
}

.fa-snowman:before {
  content: "\F7D0";
}

.fa-snowmobile:before {
  content: "\F7D1";
}

.fa-snowplow:before {
  content: "\F7D2";
}

.fa-socks:before {
  content: "\F696";
}

.fa-solar-panel:before {
  content: "\F5BA";
}

.fa-sort:before {
  content: "\F0DC";
}

.fa-sort-alpha-down:before {
  content: "\F15D";
}

.fa-sort-alpha-down-alt:before {
  content: "\F881";
}

.fa-sort-alpha-up:before {
  content: "\F15E";
}

.fa-sort-alpha-up-alt:before {
  content: "\F882";
}

.fa-sort-alt:before {
  content: "\F883";
}

.fa-sort-amount-down:before {
  content: "\F160";
}

.fa-sort-amount-down-alt:before {
  content: "\F884";
}

.fa-sort-amount-up:before {
  content: "\F161";
}

.fa-sort-amount-up-alt:before {
  content: "\F885";
}

.fa-sort-down:before {
  content: "\F0DD";
}

.fa-sort-numeric-down:before {
  content: "\F162";
}

.fa-sort-numeric-down-alt:before {
  content: "\F886";
}

.fa-sort-numeric-up:before {
  content: "\F163";
}

.fa-sort-numeric-up-alt:before {
  content: "\F887";
}

.fa-sort-shapes-down:before {
  content: "\F888";
}

.fa-sort-shapes-down-alt:before {
  content: "\F889";
}

.fa-sort-shapes-up:before {
  content: "\F88A";
}

.fa-sort-shapes-up-alt:before {
  content: "\F88B";
}

.fa-sort-size-down:before {
  content: "\F88C";
}

.fa-sort-size-down-alt:before {
  content: "\F88D";
}

.fa-sort-size-up:before {
  content: "\F88E";
}

.fa-sort-size-up-alt:before {
  content: "\F88F";
}

.fa-sort-up:before {
  content: "\F0DE";
}

.fa-soundcloud:before {
  content: "\F1BE";
}

.fa-soup:before {
  content: "\F823";
}

.fa-sourcetree:before {
  content: "\F7D3";
}

.fa-spa:before {
  content: "\F5BB";
}

.fa-space-shuttle:before {
  content: "\F197";
}

.fa-spade:before {
  content: "\F2F4";
}

.fa-sparkles:before {
  content: "\F890";
}

.fa-speakap:before {
  content: "\F3F3";
}

.fa-speaker-deck:before {
  content: "\F83C";
}

.fa-spell-check:before {
  content: "\F891";
}

.fa-spider:before {
  content: "\F717";
}

.fa-spider-black-widow:before {
  content: "\F718";
}

.fa-spider-web:before {
  content: "\F719";
}

.fa-spinner:before {
  content: "\F110";
}

.fa-spinner-third:before {
  content: "\F3F4";
}

.fa-splotch:before {
  content: "\F5BC";
}

.fa-spotify:before {
  content: "\F1BC";
}

.fa-spray-can:before {
  content: "\F5BD";
}

.fa-square:before {
  content: "\F0C8";
}

.fa-square-full:before {
  content: "\F45C";
}

.fa-square-root:before {
  content: "\F697";
}

.fa-square-root-alt:before {
  content: "\F698";
}

.fa-squarespace:before {
  content: "\F5BE";
}

.fa-squirrel:before {
  content: "\F71A";
}

.fa-stack-exchange:before {
  content: "\F18D";
}

.fa-stack-overflow:before {
  content: "\F16C";
}

.fa-stackpath:before {
  content: "\F842";
}

.fa-staff:before {
  content: "\F71B";
}

.fa-stamp:before {
  content: "\F5BF";
}

.fa-star:before {
  content: "\F005";
}

.fa-star-and-crescent:before {
  content: "\F699";
}

.fa-star-christmas:before {
  content: "\F7D4";
}

.fa-star-exclamation:before {
  content: "\F2F3";
}

.fa-star-half:before {
  content: "\F089";
}

.fa-star-half-alt:before {
  content: "\F5C0";
}

.fa-star-of-david:before {
  content: "\F69A";
}

.fa-star-of-life:before {
  content: "\F621";
}

.fa-stars:before {
  content: "\F762";
}

.fa-staylinked:before {
  content: "\F3F5";
}

.fa-steak:before {
  content: "\F824";
}

.fa-steam:before {
  content: "\F1B6";
}

.fa-steam-square:before {
  content: "\F1B7";
}

.fa-steam-symbol:before {
  content: "\F3F6";
}

.fa-steering-wheel:before {
  content: "\F622";
}

.fa-step-backward:before {
  content: "\F048";
}

.fa-step-forward:before {
  content: "\F051";
}

.fa-stethoscope:before {
  content: "\F0F1";
}

.fa-sticker-mule:before {
  content: "\F3F7";
}

.fa-sticky-note:before {
  content: "\F249";
}

.fa-stocking:before {
  content: "\F7D5";
}

.fa-stomach:before {
  content: "\F623";
}

.fa-stop:before {
  content: "\F04D";
}

.fa-stop-circle:before {
  content: "\F28D";
}

.fa-stopwatch:before {
  content: "\F2F2";
}

.fa-store:before {
  content: "\F54E";
}

.fa-store-alt:before {
  content: "\F54F";
}

.fa-strava:before {
  content: "\F428";
}

.fa-stream:before {
  content: "\F550";
}

.fa-street-view:before {
  content: "\F21D";
}

.fa-stretcher:before {
  content: "\F825";
}

.fa-strikethrough:before {
  content: "\F0CC";
}

.fa-stripe:before {
  content: "\F429";
}

.fa-stripe-s:before {
  content: "\F42A";
}

.fa-stroopwafel:before {
  content: "\F551";
}

.fa-studiovinari:before {
  content: "\F3F8";
}

.fa-stumbleupon:before {
  content: "\F1A4";
}

.fa-stumbleupon-circle:before {
  content: "\F1A3";
}

.fa-subscript:before {
  content: "\F12C";
}

.fa-subway:before {
  content: "\F239";
}

.fa-suitcase:before {
  content: "\F0F2";
}

.fa-suitcase-rolling:before {
  content: "\F5C1";
}

.fa-sun:before {
  content: "\F185";
}

.fa-sun-cloud:before {
  content: "\F763";
}

.fa-sun-dust:before {
  content: "\F764";
}

.fa-sun-haze:before {
  content: "\F765";
}

.fa-sunglasses:before {
  content: "\F892";
}

.fa-sunrise:before {
  content: "\F766";
}

.fa-sunset:before {
  content: "\F767";
}

.fa-superpowers:before {
  content: "\F2DD";
}

.fa-superscript:before {
  content: "\F12B";
}

.fa-supple:before {
  content: "\F3F9";
}

.fa-surprise:before {
  content: "\F5C2";
}

.fa-suse:before {
  content: "\F7D6";
}

.fa-swatchbook:before {
  content: "\F5C3";
}

.fa-swimmer:before {
  content: "\F5C4";
}

.fa-swimming-pool:before {
  content: "\F5C5";
}

.fa-sword:before {
  content: "\F71C";
}

.fa-swords:before {
  content: "\F71D";
}

.fa-symfony:before {
  content: "\F83D";
}

.fa-synagogue:before {
  content: "\F69B";
}

.fa-sync:before {
  content: "\F021";
}

.fa-sync-alt:before {
  content: "\F2F1";
}

.fa-syringe:before {
  content: "\F48E";
}

.fa-table:before {
  content: "\F0CE";
}

.fa-table-tennis:before {
  content: "\F45D";
}

.fa-tablet:before {
  content: "\F10A";
}

.fa-tablet-alt:before {
  content: "\F3FA";
}

.fa-tablet-android:before {
  content: "\F3FB";
}

.fa-tablet-android-alt:before {
  content: "\F3FC";
}

.fa-tablet-rugged:before {
  content: "\F48F";
}

.fa-tablets:before {
  content: "\F490";
}

.fa-tachometer:before {
  content: "\F0E4";
}

.fa-tachometer-alt:before {
  content: "\F3FD";
}

.fa-tachometer-alt-average:before {
  content: "\F624";
}

.fa-tachometer-alt-fast:before {
  content: "\F625";
}

.fa-tachometer-alt-fastest:before {
  content: "\F626";
}

.fa-tachometer-alt-slow:before {
  content: "\F627";
}

.fa-tachometer-alt-slowest:before {
  content: "\F628";
}

.fa-tachometer-average:before {
  content: "\F629";
}

.fa-tachometer-fast:before {
  content: "\F62A";
}

.fa-tachometer-fastest:before {
  content: "\F62B";
}

.fa-tachometer-slow:before {
  content: "\F62C";
}

.fa-tachometer-slowest:before {
  content: "\F62D";
}

.fa-taco:before {
  content: "\F826";
}

.fa-tag:before {
  content: "\F02B";
}

.fa-tags:before {
  content: "\F02C";
}

.fa-tally:before {
  content: "\F69C";
}

.fa-tanakh:before {
  content: "\F827";
}

.fa-tape:before {
  content: "\F4DB";
}

.fa-tasks:before {
  content: "\F0AE";
}

.fa-tasks-alt:before {
  content: "\F828";
}

.fa-taxi:before {
  content: "\F1BA";
}

.fa-teamspeak:before {
  content: "\F4F9";
}

.fa-teeth:before {
  content: "\F62E";
}

.fa-teeth-open:before {
  content: "\F62F";
}

.fa-telegram:before {
  content: "\F2C6";
}

.fa-telegram-plane:before {
  content: "\F3FE";
}

.fa-temperature-frigid:before {
  content: "\F768";
}

.fa-temperature-high:before {
  content: "\F769";
}

.fa-temperature-hot:before {
  content: "\F76A";
}

.fa-temperature-low:before {
  content: "\F76B";
}

.fa-tencent-weibo:before {
  content: "\F1D5";
}

.fa-tenge:before {
  content: "\F7D7";
}

.fa-tennis-ball:before {
  content: "\F45E";
}

.fa-terminal:before {
  content: "\F120";
}

.fa-text:before {
  content: "\F893";
}

.fa-text-height:before {
  content: "\F034";
}

.fa-text-size:before {
  content: "\F894";
}

.fa-text-width:before {
  content: "\F035";
}

.fa-th:before {
  content: "\F00A";
}

.fa-th-large:before {
  content: "\F009";
}

.fa-th-list:before {
  content: "\F00B";
}

.fa-the-red-yeti:before {
  content: "\F69D";
}

.fa-theater-masks:before {
  content: "\F630";
}

.fa-themeco:before {
  content: "\F5C6";
}

.fa-themeisle:before {
  content: "\F2B2";
}

.fa-thermometer:before {
  content: "\F491";
}

.fa-thermometer-empty:before {
  content: "\F2CB";
}

.fa-thermometer-full:before {
  content: "\F2C7";
}

.fa-thermometer-half:before {
  content: "\F2C9";
}

.fa-thermometer-quarter:before {
  content: "\F2CA";
}

.fa-thermometer-three-quarters:before {
  content: "\F2C8";
}

.fa-theta:before {
  content: "\F69E";
}

.fa-think-peaks:before {
  content: "\F731";
}

.fa-thumbs-down:before {
  content: "\F165";
}

.fa-thumbs-up:before {
  content: "\F164";
}

.fa-thumbtack:before {
  content: "\F08D";
}

.fa-thunderstorm:before {
  content: "\F76C";
}

.fa-thunderstorm-moon:before {
  content: "\F76D";
}

.fa-thunderstorm-sun:before {
  content: "\F76E";
}

.fa-ticket:before {
  content: "\F145";
}

.fa-ticket-alt:before {
  content: "\F3FF";
}

.fa-tilde:before {
  content: "\F69F";
}

.fa-times:before {
  content: "\F00D";
}

.fa-times-circle:before {
  content: "\F057";
}

.fa-times-hexagon:before {
  content: "\F2EE";
}

.fa-times-octagon:before {
  content: "\F2F0";
}

.fa-times-square:before {
  content: "\F2D3";
}

.fa-tint:before {
  content: "\F043";
}

.fa-tint-slash:before {
  content: "\F5C7";
}

.fa-tire:before {
  content: "\F631";
}

.fa-tire-flat:before {
  content: "\F632";
}

.fa-tire-pressure-warning:before {
  content: "\F633";
}

.fa-tire-rugged:before {
  content: "\F634";
}

.fa-tired:before {
  content: "\F5C8";
}

.fa-toggle-off:before {
  content: "\F204";
}

.fa-toggle-on:before {
  content: "\F205";
}

.fa-toilet:before {
  content: "\F7D8";
}

.fa-toilet-paper:before {
  content: "\F71E";
}

.fa-toilet-paper-alt:before {
  content: "\F71F";
}

.fa-tombstone:before {
  content: "\F720";
}

.fa-tombstone-alt:before {
  content: "\F721";
}

.fa-toolbox:before {
  content: "\F552";
}

.fa-tools:before {
  content: "\F7D9";
}

.fa-tooth:before {
  content: "\F5C9";
}

.fa-toothbrush:before {
  content: "\F635";
}

.fa-torah:before {
  content: "\F6A0";
}

.fa-torii-gate:before {
  content: "\F6A1";
}

.fa-tornado:before {
  content: "\F76F";
}

.fa-tractor:before {
  content: "\F722";
}

.fa-trade-federation:before {
  content: "\F513";
}

.fa-trademark:before {
  content: "\F25C";
}

.fa-traffic-cone:before {
  content: "\F636";
}

.fa-traffic-light:before {
  content: "\F637";
}

.fa-traffic-light-go:before {
  content: "\F638";
}

.fa-traffic-light-slow:before {
  content: "\F639";
}

.fa-traffic-light-stop:before {
  content: "\F63A";
}

.fa-train:before {
  content: "\F238";
}

.fa-tram:before {
  content: "\F7DA";
}

.fa-transgender:before {
  content: "\F224";
}

.fa-transgender-alt:before {
  content: "\F225";
}

.fa-trash:before {
  content: "\F1F8";
}

.fa-trash-alt:before {
  content: "\F2ED";
}

.fa-trash-restore:before {
  content: "\F829";
}

.fa-trash-restore-alt:before {
  content: "\F82A";
}

.fa-trash-undo:before {
  content: "\F895";
}

.fa-trash-undo-alt:before {
  content: "\F896";
}

.fa-treasure-chest:before {
  content: "\F723";
}

.fa-tree:before {
  content: "\F1BB";
}

.fa-tree-alt:before {
  content: "\F400";
}

.fa-tree-christmas:before {
  content: "\F7DB";
}

.fa-tree-decorated:before {
  content: "\F7DC";
}

.fa-tree-large:before {
  content: "\F7DD";
}

.fa-tree-palm:before {
  content: "\F82B";
}

.fa-trees:before {
  content: "\F724";
}

.fa-trello:before {
  content: "\F181";
}

.fa-triangle:before {
  content: "\F2EC";
}

.fa-tripadvisor:before {
  content: "\F262";
}

.fa-trophy:before {
  content: "\F091";
}

.fa-trophy-alt:before {
  content: "\F2EB";
}

.fa-truck:before {
  content: "\F0D1";
}

.fa-truck-container:before {
  content: "\F4DC";
}

.fa-truck-couch:before {
  content: "\F4DD";
}

.fa-truck-loading:before {
  content: "\F4DE";
}

.fa-truck-monster:before {
  content: "\F63B";
}

.fa-truck-moving:before {
  content: "\F4DF";
}

.fa-truck-pickup:before {
  content: "\F63C";
}

.fa-truck-plow:before {
  content: "\F7DE";
}

.fa-truck-ramp:before {
  content: "\F4E0";
}

.fa-tshirt:before {
  content: "\F553";
}

.fa-tty:before {
  content: "\F1E4";
}

.fa-tumblr:before {
  content: "\F173";
}

.fa-tumblr-square:before {
  content: "\F174";
}

.fa-turkey:before {
  content: "\F725";
}

.fa-turtle:before {
  content: "\F726";
}

.fa-tv:before {
  content: "\F26C";
}

.fa-tv-retro:before {
  content: "\F401";
}

.fa-twitch:before {
  content: "\F1E8";
}

.fa-twitter:before {
  content: "\F099";
}

.fa-twitter-square:before {
  content: "\F081";
}

.fa-typo3:before {
  content: "\F42B";
}

.fa-uber:before {
  content: "\F402";
}

.fa-ubuntu:before {
  content: "\F7DF";
}

.fa-uikit:before {
  content: "\F403";
}

.fa-umbrella:before {
  content: "\F0E9";
}

.fa-umbrella-beach:before {
  content: "\F5CA";
}

.fa-underline:before {
  content: "\F0CD";
}

.fa-undo:before {
  content: "\F0E2";
}

.fa-undo-alt:before {
  content: "\F2EA";
}

.fa-unicorn:before {
  content: "\F727";
}

.fa-union:before {
  content: "\F6A2";
}

.fa-uniregistry:before {
  content: "\F404";
}

.fa-universal-access:before {
  content: "\F29A";
}

.fa-university:before {
  content: "\F19C";
}

.fa-unlink:before {
  content: "\F127";
}

.fa-unlock:before {
  content: "\F09C";
}

.fa-unlock-alt:before {
  content: "\F13E";
}

.fa-untappd:before {
  content: "\F405";
}

.fa-upload:before {
  content: "\F093";
}

.fa-ups:before {
  content: "\F7E0";
}

.fa-usb:before {
  content: "\F287";
}

.fa-usd-circle:before {
  content: "\F2E8";
}

.fa-usd-square:before {
  content: "\F2E9";
}

.fa-user:before {
  content: "\F007";
}

.fa-user-alt:before {
  content: "\F406";
}

.fa-user-alt-slash:before {
  content: "\F4FA";
}

.fa-user-astronaut:before {
  content: "\F4FB";
}

.fa-user-chart:before {
  content: "\F6A3";
}

.fa-user-check:before {
  content: "\F4FC";
}

.fa-user-circle:before {
  content: "\F2BD";
}

.fa-user-clock:before {
  content: "\F4FD";
}

.fa-user-cog:before {
  content: "\F4FE";
}

.fa-user-crown:before {
  content: "\F6A4";
}

.fa-user-edit:before {
  content: "\F4FF";
}

.fa-user-friends:before {
  content: "\F500";
}

.fa-user-graduate:before {
  content: "\F501";
}

.fa-user-hard-hat:before {
  content: "\F82C";
}

.fa-user-headset:before {
  content: "\F82D";
}

.fa-user-injured:before {
  content: "\F728";
}

.fa-user-lock:before {
  content: "\F502";
}

.fa-user-md:before {
  content: "\F0F0";
}

.fa-user-md-chat:before {
  content: "\F82E";
}

.fa-user-minus:before {
  content: "\F503";
}

.fa-user-ninja:before {
  content: "\F504";
}

.fa-user-nurse:before {
  content: "\F82F";
}

.fa-user-plus:before {
  content: "\F234";
}

.fa-user-secret:before {
  content: "\F21B";
}

.fa-user-shield:before {
  content: "\F505";
}

.fa-user-slash:before {
  content: "\F506";
}

.fa-user-tag:before {
  content: "\F507";
}

.fa-user-tie:before {
  content: "\F508";
}

.fa-user-times:before {
  content: "\F235";
}

.fa-users:before {
  content: "\F0C0";
}

.fa-users-class:before {
  content: "\F63D";
}

.fa-users-cog:before {
  content: "\F509";
}

.fa-users-crown:before {
  content: "\F6A5";
}

.fa-users-medical:before {
  content: "\F830";
}

.fa-usps:before {
  content: "\F7E1";
}

.fa-ussunnah:before {
  content: "\F407";
}

.fa-utensil-fork:before {
  content: "\F2E3";
}

.fa-utensil-knife:before {
  content: "\F2E4";
}

.fa-utensil-spoon:before {
  content: "\F2E5";
}

.fa-utensils:before {
  content: "\F2E7";
}

.fa-utensils-alt:before {
  content: "\F2E6";
}

.fa-vaadin:before {
  content: "\F408";
}

.fa-value-absolute:before {
  content: "\F6A6";
}

.fa-vector-square:before {
  content: "\F5CB";
}

.fa-venus:before {
  content: "\F221";
}

.fa-venus-double:before {
  content: "\F226";
}

.fa-venus-mars:before {
  content: "\F228";
}

.fa-viacoin:before {
  content: "\F237";
}

.fa-viadeo:before {
  content: "\F2A9";
}

.fa-viadeo-square:before {
  content: "\F2AA";
}

.fa-vial:before {
  content: "\F492";
}

.fa-vials:before {
  content: "\F493";
}

.fa-viber:before {
  content: "\F409";
}

.fa-video:before {
  content: "\F03D";
}

.fa-video-plus:before {
  content: "\F4E1";
}

.fa-video-slash:before {
  content: "\F4E2";
}

.fa-vihara:before {
  content: "\F6A7";
}

.fa-vimeo:before {
  content: "\F40A";
}

.fa-vimeo-square:before {
  content: "\F194";
}

.fa-vimeo-v:before {
  content: "\F27D";
}

.fa-vine:before {
  content: "\F1CA";
}

.fa-vk:before {
  content: "\F189";
}

.fa-vnv:before {
  content: "\F40B";
}

.fa-voicemail:before {
  content: "\F897";
}

.fa-volcano:before {
  content: "\F770";
}

.fa-volleyball-ball:before {
  content: "\F45F";
}

.fa-volume:before {
  content: "\F6A8";
}

.fa-volume-down:before {
  content: "\F027";
}

.fa-volume-mute:before {
  content: "\F6A9";
}

.fa-volume-off:before {
  content: "\F026";
}

.fa-volume-slash:before {
  content: "\F2E2";
}

.fa-volume-up:before {
  content: "\F028";
}

.fa-vote-nay:before {
  content: "\F771";
}

.fa-vote-yea:before {
  content: "\F772";
}

.fa-vr-cardboard:before {
  content: "\F729";
}

.fa-vuejs:before {
  content: "\F41F";
}

.fa-walker:before {
  content: "\F831";
}

.fa-walking:before {
  content: "\F554";
}

.fa-wallet:before {
  content: "\F555";
}

.fa-wand:before {
  content: "\F72A";
}

.fa-wand-magic:before {
  content: "\F72B";
}

.fa-warehouse:before {
  content: "\F494";
}

.fa-warehouse-alt:before {
  content: "\F495";
}

.fa-washer:before {
  content: "\F898";
}

.fa-watch:before {
  content: "\F2E1";
}

.fa-watch-fitness:before {
  content: "\F63E";
}

.fa-water:before {
  content: "\F773";
}

.fa-water-lower:before {
  content: "\F774";
}

.fa-water-rise:before {
  content: "\F775";
}

.fa-wave-sine:before {
  content: "\F899";
}

.fa-wave-square:before {
  content: "\F83E";
}

.fa-wave-triangle:before {
  content: "\F89A";
}

.fa-waze:before {
  content: "\F83F";
}

.fa-webcam:before {
  content: "\F832";
}

.fa-webcam-slash:before {
  content: "\F833";
}

.fa-weebly:before {
  content: "\F5CC";
}

.fa-weibo:before {
  content: "\F18A";
}

.fa-weight:before {
  content: "\F496";
}

.fa-weight-hanging:before {
  content: "\F5CD";
}

.fa-weixin:before {
  content: "\F1D7";
}

.fa-whale:before {
  content: "\F72C";
}

.fa-whatsapp:before {
  content: "\F232";
}

.fa-whatsapp-square:before {
  content: "\F40C";
}

.fa-wheat:before {
  content: "\F72D";
}

.fa-wheelchair:before {
  content: "\F193";
}

.fa-whistle:before {
  content: "\F460";
}

.fa-whmcs:before {
  content: "\F40D";
}

.fa-wifi:before {
  content: "\F1EB";
}

.fa-wifi-1:before {
  content: "\F6AA";
}

.fa-wifi-2:before {
  content: "\F6AB";
}

.fa-wifi-slash:before {
  content: "\F6AC";
}

.fa-wikipedia-w:before {
  content: "\F266";
}

.fa-wind:before {
  content: "\F72E";
}

.fa-wind-turbine:before {
  content: "\F89B";
}

.fa-wind-warning:before {
  content: "\F776";
}

.fa-window:before {
  content: "\F40E";
}

.fa-window-alt:before {
  content: "\F40F";
}

.fa-window-close:before {
  content: "\F410";
}

.fa-window-maximize:before {
  content: "\F2D0";
}

.fa-window-minimize:before {
  content: "\F2D1";
}

.fa-window-restore:before {
  content: "\F2D2";
}

.fa-windows:before {
  content: "\F17A";
}

.fa-windsock:before {
  content: "\F777";
}

.fa-wine-bottle:before {
  content: "\F72F";
}

.fa-wine-glass:before {
  content: "\F4E3";
}

.fa-wine-glass-alt:before {
  content: "\F5CE";
}

.fa-wix:before {
  content: "\F5CF";
}

.fa-wizards-of-the-coast:before {
  content: "\F730";
}

.fa-wolf-pack-battalion:before {
  content: "\F514";
}

.fa-won-sign:before {
  content: "\F159";
}

.fa-wordpress:before {
  content: "\F19A";
}

.fa-wordpress-simple:before {
  content: "\F411";
}

.fa-wpbeginner:before {
  content: "\F297";
}

.fa-wpexplorer:before {
  content: "\F2DE";
}

.fa-wpforms:before {
  content: "\F298";
}

.fa-wpressr:before {
  content: "\F3E4";
}

.fa-wreath:before {
  content: "\F7E2";
}

.fa-wrench:before {
  content: "\F0AD";
}

.fa-x-ray:before {
  content: "\F497";
}

.fa-xbox:before {
  content: "\F412";
}

.fa-xing:before {
  content: "\F168";
}

.fa-xing-square:before {
  content: "\F169";
}

.fa-y-combinator:before {
  content: "\F23B";
}

.fa-yahoo:before {
  content: "\F19E";
}

.fa-yammer:before {
  content: "\F840";
}

.fa-yandex:before {
  content: "\F413";
}

.fa-yandex-international:before {
  content: "\F414";
}

.fa-yarn:before {
  content: "\F7E3";
}

.fa-yelp:before {
  content: "\F1E9";
}

.fa-yen-sign:before {
  content: "\F157";
}

.fa-yin-yang:before {
  content: "\F6AD";
}

.fa-yoast:before {
  content: "\F2B1";
}

.fa-youtube:before {
  content: "\F167";
}

.fa-youtube-square:before {
  content: "\F431";
}

.fa-zhihu:before {
  content: "\F63F";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(/assets/fonts/fa-solid-900.9d1f26cae377a0fc01adc2791ab11731.eot);
  src: url(/assets/fonts/fa-solid-900.9d1f26cae377a0fc01adc2791ab11731.eot) format("embedded-opentype"), url(/assets/fonts/fa-solid-900.01ab51e9ff8aa7a971f99f6df25fd31f.woff2) format("woff2"), url(/assets/fonts/fa-solid-900.7cd9d4aabfbed46dfae7946e56f4c0e4.woff) format("woff"), url(/assets/fonts/fa-solid-900.28015e0a4828010dd9a3b5d770fb4572.ttf) format("truetype"), url(/assets/fonts/fa-solid-900.3cde8b2d38743c1c0bdbd00243b9743d.svg) format("svg");
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(/assets/fonts/fa-regular-400.8367c7f83e733f06787d88476fb7ed34.eot);
  src: url(/assets/fonts/fa-regular-400.8367c7f83e733f06787d88476fb7ed34.eot) format("embedded-opentype"), url(/assets/fonts/fa-regular-400.bc70ae3f10e3da7672a12847a2bf0175.woff2) format("woff2"), url(/assets/fonts/fa-regular-400.9d318325eaaf66a1293e3712a6e90b25.woff) format("woff"), url(/assets/fonts/fa-regular-400.caf8defa5e22124a0804890120b8281c.ttf) format("truetype"), url(/assets/fonts/fa-regular-400.fe906605803a02d6488510a5e4fb5fc0.svg) format("svg");
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(/assets/fonts/fa-light-300.a7b47620323558cbdb9404a735eacb53.eot);
  src: url(/assets/fonts/fa-light-300.a7b47620323558cbdb9404a735eacb53.eot) format("embedded-opentype"), url(/assets/fonts/fa-light-300.8d8d7db2c6282c3a8174dc601ad5c4bd.woff2) format("woff2"), url(/assets/fonts/fa-light-300.f86c07103be2b005bf228d28fa5e3aa3.woff) format("woff"), url(/assets/fonts/fa-light-300.7cf79debf046ceb2eedf110dff169b7b.ttf) format("truetype"), url(/assets/fonts/fa-light-300.e141441ff71b1eba3edb0e5279a536b0.svg) format("svg");
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(/assets/fonts/fa-brands-400.4dc22e4b357107f0023f75918b77396c.eot);
  src: url(/assets/fonts/fa-brands-400.4dc22e4b357107f0023f75918b77396c.eot) format("embedded-opentype"), url(/assets/fonts/fa-brands-400.1cd1794057af01db79a142672a0d62f2.woff2) format("woff2"), url(/assets/fonts/fa-brands-400.9935d05f2de0ccb34dbd607953b67e5f.woff) format("woff"), url(/assets/fonts/fa-brands-400.ad50d57482d87425a32dc40a056e9441.ttf) format("truetype"), url(/assets/fonts/fa-brands-400.0decb3bb05c7d4e68f410fbcabbcd5e0.svg) format("svg");
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}

/*!
 * Font Awesome Pro 5.9.0 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */

.fa.fa-glass:before {
  content: "\F000";
}

.fa.fa-meetup {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-star-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-o:before {
  content: "\F005";
}

.fa.fa-remove:before {
  content: "\F00D";
}

.fa.fa-close:before {
  content: "\F00D";
}

.fa.fa-gear:before {
  content: "\F013";
}

.fa.fa-trash-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-trash-o:before {
  content: "\F2ED";
}

.fa.fa-file-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-o:before {
  content: "\F15B";
}

.fa.fa-clock-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-clock-o:before {
  content: "\F017";
}

.fa.fa-arrow-circle-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-down:before {
  content: "\F358";
}

.fa.fa-arrow-circle-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-up:before {
  content: "\F35B";
}

.fa.fa-play-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-play-circle-o:before {
  content: "\F144";
}

.fa.fa-repeat:before {
  content: "\F01E";
}

.fa.fa-rotate-right:before {
  content: "\F01E";
}

.fa.fa-refresh:before {
  content: "\F021";
}

.fa.fa-list-alt {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-dedent:before {
  content: "\F03B";
}

.fa.fa-video-camera:before {
  content: "\F03D";
}

.fa.fa-picture-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-picture-o:before {
  content: "\F03E";
}

.fa.fa-photo {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-photo:before {
  content: "\F03E";
}

.fa.fa-image {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-image:before {
  content: "\F03E";
}

.fa.fa-pencil:before {
  content: "\F303";
}

.fa.fa-map-marker:before {
  content: "\F3C5";
}

.fa.fa-pencil-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-pencil-square-o:before {
  content: "\F044";
}

.fa.fa-share-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-share-square-o:before {
  content: "\F14D";
}

.fa.fa-check-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-check-square-o:before {
  content: "\F14A";
}

.fa.fa-arrows:before {
  content: "\F0B2";
}

.fa.fa-times-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-times-circle-o:before {
  content: "\F057";
}

.fa.fa-check-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-check-circle-o:before {
  content: "\F058";
}

.fa.fa-mail-forward:before {
  content: "\F064";
}

.fa.fa-eye {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-eye-slash {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-warning:before {
  content: "\F071";
}

.fa.fa-calendar:before {
  content: "\F073";
}

.fa.fa-arrows-v:before {
  content: "\F338";
}

.fa.fa-arrows-h:before {
  content: "\F337";
}

.fa.fa-bar-chart {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bar-chart:before {
  content: "\F080";
}

.fa.fa-bar-chart-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bar-chart-o:before {
  content: "\F080";
}

.fa.fa-twitter-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gears:before {
  content: "\F085";
}

.fa.fa-thumbs-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-thumbs-o-up:before {
  content: "\F164";
}

.fa.fa-thumbs-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-thumbs-o-down:before {
  content: "\F165";
}

.fa.fa-heart-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-heart-o:before {
  content: "\F004";
}

.fa.fa-sign-out:before {
  content: "\F2F5";
}

.fa.fa-linkedin-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-linkedin-square:before {
  content: "\F08C";
}

.fa.fa-thumb-tack:before {
  content: "\F08D";
}

.fa.fa-external-link:before {
  content: "\F35D";
}

.fa.fa-sign-in:before {
  content: "\F2F6";
}

.fa.fa-github-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-lemon-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-lemon-o:before {
  content: "\F094";
}

.fa.fa-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-square-o:before {
  content: "\F0C8";
}

.fa.fa-bookmark-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bookmark-o:before {
  content: "\F02E";
}

.fa.fa-twitter {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook:before {
  content: "\F39E";
}

.fa.fa-facebook-f {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook-f:before {
  content: "\F39E";
}

.fa.fa-github {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-credit-card {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-feed:before {
  content: "\F09E";
}

.fa.fa-hdd-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hdd-o:before {
  content: "\F0A0";
}

.fa.fa-hand-o-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-right:before {
  content: "\F0A4";
}

.fa.fa-hand-o-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-left:before {
  content: "\F0A5";
}

.fa.fa-hand-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-up:before {
  content: "\F0A6";
}

.fa.fa-hand-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-o-down:before {
  content: "\F0A7";
}

.fa.fa-arrows-alt:before {
  content: "\F31E";
}

.fa.fa-group:before {
  content: "\F0C0";
}

.fa.fa-chain:before {
  content: "\F0C1";
}

.fa.fa-scissors:before {
  content: "\F0C4";
}

.fa.fa-files-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-files-o:before {
  content: "\F0C5";
}

.fa.fa-floppy-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-floppy-o:before {
  content: "\F0C7";
}

.fa.fa-navicon:before {
  content: "\F0C9";
}

.fa.fa-reorder:before {
  content: "\F0C9";
}

.fa.fa-pinterest {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pinterest-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus:before {
  content: "\F0D5";
}

.fa.fa-money {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-money:before {
  content: "\F3D1";
}

.fa.fa-unsorted:before {
  content: "\F0DC";
}

.fa.fa-sort-desc:before {
  content: "\F0DD";
}

.fa.fa-sort-asc:before {
  content: "\F0DE";
}

.fa.fa-linkedin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-linkedin:before {
  content: "\F0E1";
}

.fa.fa-rotate-left:before {
  content: "\F0E2";
}

.fa.fa-legal:before {
  content: "\F0E3";
}

.fa.fa-tachometer:before {
  content: "\F3FD";
}

.fa.fa-dashboard:before {
  content: "\F3FD";
}

.fa.fa-comment-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-comment-o:before {
  content: "\F075";
}

.fa.fa-comments-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-comments-o:before {
  content: "\F086";
}

.fa.fa-flash:before {
  content: "\F0E7";
}

.fa.fa-clipboard {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-paste {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-paste:before {
  content: "\F328";
}

.fa.fa-lightbulb-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-lightbulb-o:before {
  content: "\F0EB";
}

.fa.fa-exchange:before {
  content: "\F362";
}

.fa.fa-cloud-download:before {
  content: "\F381";
}

.fa.fa-cloud-upload:before {
  content: "\F382";
}

.fa.fa-bell-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bell-o:before {
  content: "\F0F3";
}

.fa.fa-cutlery:before {
  content: "\F2E7";
}

.fa.fa-file-text-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-text-o:before {
  content: "\F15C";
}

.fa.fa-building-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-building-o:before {
  content: "\F1AD";
}

.fa.fa-hospital-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hospital-o:before {
  content: "\F0F8";
}

.fa.fa-tablet:before {
  content: "\F3FA";
}

.fa.fa-mobile:before {
  content: "\F3CD";
}

.fa.fa-mobile-phone:before {
  content: "\F3CD";
}

.fa.fa-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-circle-o:before {
  content: "\F111";
}

.fa.fa-mail-reply:before {
  content: "\F3E5";
}

.fa.fa-github-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-folder-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-folder-o:before {
  content: "\F07B";
}

.fa.fa-folder-open-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-folder-open-o:before {
  content: "\F07C";
}

.fa.fa-smile-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-smile-o:before {
  content: "\F118";
}

.fa.fa-frown-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-frown-o:before {
  content: "\F119";
}

.fa.fa-meh-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-meh-o:before {
  content: "\F11A";
}

.fa.fa-keyboard-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-keyboard-o:before {
  content: "\F11C";
}

.fa.fa-flag-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-flag-o:before {
  content: "\F024";
}

.fa.fa-mail-reply-all:before {
  content: "\F122";
}

.fa.fa-star-half-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-half-o:before {
  content: "\F089";
}

.fa.fa-star-half-empty {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-half-empty:before {
  content: "\F089";
}

.fa.fa-star-half-full {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-star-half-full:before {
  content: "\F089";
}

.fa.fa-code-fork:before {
  content: "\F126";
}

.fa.fa-chain-broken:before {
  content: "\F127";
}

.fa.fa-shield:before {
  content: "\F3ED";
}

.fa.fa-calendar-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-o:before {
  content: "\F133";
}

.fa.fa-maxcdn {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-html5 {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-css3 {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ticket:before {
  content: "\F3FF";
}

.fa.fa-minus-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-minus-square-o:before {
  content: "\F146";
}

.fa.fa-level-up:before {
  content: "\F3BF";
}

.fa.fa-level-down:before {
  content: "\F3BE";
}

.fa.fa-pencil-square:before {
  content: "\F14B";
}

.fa.fa-external-link-square:before {
  content: "\F360";
}

.fa.fa-compass {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-down:before {
  content: "\F150";
}

.fa.fa-toggle-down {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-down:before {
  content: "\F150";
}

.fa.fa-caret-square-o-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-up:before {
  content: "\F151";
}

.fa.fa-toggle-up {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-up:before {
  content: "\F151";
}

.fa.fa-caret-square-o-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-right:before {
  content: "\F152";
}

.fa.fa-toggle-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-right:before {
  content: "\F152";
}

.fa.fa-eur:before {
  content: "\F153";
}

.fa.fa-euro:before {
  content: "\F153";
}

.fa.fa-gbp:before {
  content: "\F154";
}

.fa.fa-usd:before {
  content: "\F155";
}

.fa.fa-dollar:before {
  content: "\F155";
}

.fa.fa-inr:before {
  content: "\F156";
}

.fa.fa-rupee:before {
  content: "\F156";
}

.fa.fa-jpy:before {
  content: "\F157";
}

.fa.fa-cny:before {
  content: "\F157";
}

.fa.fa-rmb:before {
  content: "\F157";
}

.fa.fa-yen:before {
  content: "\F157";
}

.fa.fa-rub:before {
  content: "\F158";
}

.fa.fa-ruble:before {
  content: "\F158";
}

.fa.fa-rouble:before {
  content: "\F158";
}

.fa.fa-krw:before {
  content: "\F159";
}

.fa.fa-won:before {
  content: "\F159";
}

.fa.fa-btc {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitcoin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitcoin:before {
  content: "\F15A";
}

.fa.fa-file-text:before {
  content: "\F15C";
}

.fa.fa-sort-alpha-asc:before {
  content: "\F15D";
}

.fa.fa-sort-alpha-desc:before {
  content: "\F15E";
}

.fa.fa-sort-amount-asc:before {
  content: "\F160";
}

.fa.fa-sort-amount-desc:before {
  content: "\F161";
}

.fa.fa-sort-numeric-asc:before {
  content: "\F162";
}

.fa.fa-sort-numeric-desc:before {
  content: "\F163";
}

.fa.fa-youtube-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-youtube {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-xing {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-xing-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-youtube-play {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-youtube-play:before {
  content: "\F167";
}

.fa.fa-dropbox {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stack-overflow {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-instagram {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-flickr {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-adn {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitbucket {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitbucket-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bitbucket-square:before {
  content: "\F171";
}

.fa.fa-tumblr {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-tumblr-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-long-arrow-down:before {
  content: "\F309";
}

.fa.fa-long-arrow-up:before {
  content: "\F30C";
}

.fa.fa-long-arrow-left:before {
  content: "\F30A";
}

.fa.fa-long-arrow-right:before {
  content: "\F30B";
}

.fa.fa-apple {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-windows {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-android {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-linux {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-dribbble {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-skype {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-foursquare {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-trello {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gratipay {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gittip {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gittip:before {
  content: "\F184";
}

.fa.fa-sun-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-sun-o:before {
  content: "\F185";
}

.fa.fa-moon-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-moon-o:before {
  content: "\F186";
}

.fa.fa-vk {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-weibo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-renren {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pagelines {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stack-exchange {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-right {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-right:before {
  content: "\F35A";
}

.fa.fa-arrow-circle-o-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-arrow-circle-o-left:before {
  content: "\F359";
}

.fa.fa-caret-square-o-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-caret-square-o-left:before {
  content: "\F191";
}

.fa.fa-toggle-left {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-toggle-left:before {
  content: "\F191";
}

.fa.fa-dot-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-dot-circle-o:before {
  content: "\F192";
}

.fa.fa-vimeo-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-try:before {
  content: "\F195";
}

.fa.fa-turkish-lira:before {
  content: "\F195";
}

.fa.fa-plus-square-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-plus-square-o:before {
  content: "\F0FE";
}

.fa.fa-slack {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wordpress {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-openid {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-institution:before {
  content: "\F19C";
}

.fa.fa-bank:before {
  content: "\F19C";
}

.fa.fa-mortar-board:before {
  content: "\F19D";
}

.fa.fa-yahoo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-reddit {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-reddit-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stumbleupon-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-stumbleupon {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-delicious {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-digg {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pied-piper-pp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pied-piper-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-drupal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-joomla {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-spoon:before {
  content: "\F2E5";
}

.fa.fa-behance {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-behance-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-steam {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-steam-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-automobile:before {
  content: "\F1B9";
}

.fa.fa-cab:before {
  content: "\F1BA";
}

.fa.fa-envelope-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-envelope-o:before {
  content: "\F0E0";
}

.fa.fa-deviantart {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-soundcloud {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-file-pdf-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-pdf-o:before {
  content: "\F1C1";
}

.fa.fa-file-word-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-word-o:before {
  content: "\F1C2";
}

.fa.fa-file-excel-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-excel-o:before {
  content: "\F1C3";
}

.fa.fa-file-powerpoint-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-powerpoint-o:before {
  content: "\F1C4";
}

.fa.fa-file-image-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-image-o:before {
  content: "\F1C5";
}

.fa.fa-file-photo-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-photo-o:before {
  content: "\F1C5";
}

.fa.fa-file-picture-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-picture-o:before {
  content: "\F1C5";
}

.fa.fa-file-archive-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-archive-o:before {
  content: "\F1C6";
}

.fa.fa-file-zip-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-zip-o:before {
  content: "\F1C6";
}

.fa.fa-file-audio-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-audio-o:before {
  content: "\F1C7";
}

.fa.fa-file-sound-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-sound-o:before {
  content: "\F1C7";
}

.fa.fa-file-video-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-video-o:before {
  content: "\F1C8";
}

.fa.fa-file-movie-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-movie-o:before {
  content: "\F1C8";
}

.fa.fa-file-code-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-file-code-o:before {
  content: "\F1C9";
}

.fa.fa-vine {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-codepen {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-jsfiddle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-life-ring {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-bouy {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-bouy:before {
  content: "\F1CD";
}

.fa.fa-life-buoy {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-buoy:before {
  content: "\F1CD";
}

.fa.fa-life-saver {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-life-saver:before {
  content: "\F1CD";
}

.fa.fa-support {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-support:before {
  content: "\F1CD";
}

.fa.fa-circle-o-notch:before {
  content: "\F1CE";
}

.fa.fa-rebel {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ra {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ra:before {
  content: "\F1D0";
}

.fa.fa-resistance {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-resistance:before {
  content: "\F1D0";
}

.fa.fa-empire {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ge {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ge:before {
  content: "\F1D1";
}

.fa.fa-git-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-git {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-hacker-news {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-y-combinator-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-y-combinator-square:before {
  content: "\F1D4";
}

.fa.fa-yc-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yc-square:before {
  content: "\F1D4";
}

.fa.fa-tencent-weibo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-qq {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-weixin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wechat {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wechat:before {
  content: "\F1D7";
}

.fa.fa-send:before {
  content: "\F1D8";
}

.fa.fa-paper-plane-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-paper-plane-o:before {
  content: "\F1D8";
}

.fa.fa-send-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-send-o:before {
  content: "\F1D8";
}

.fa.fa-circle-thin {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-circle-thin:before {
  content: "\F111";
}

.fa.fa-header:before {
  content: "\F1DC";
}

.fa.fa-sliders:before {
  content: "\F1DE";
}

.fa.fa-futbol-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-futbol-o:before {
  content: "\F1E3";
}

.fa.fa-soccer-ball-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-soccer-ball-o:before {
  content: "\F1E3";
}

.fa.fa-slideshare {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-twitch {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yelp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-newspaper-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-newspaper-o:before {
  content: "\F1EA";
}

.fa.fa-paypal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-wallet {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-visa {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-mastercard {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-discover {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-amex {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-paypal {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-stripe {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bell-slash-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-bell-slash-o:before {
  content: "\F1F6";
}

.fa.fa-trash:before {
  content: "\F2ED";
}

.fa.fa-copyright {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-eyedropper:before {
  content: "\F1FB";
}

.fa.fa-area-chart:before {
  content: "\F1FE";
}

.fa.fa-pie-chart:before {
  content: "\F200";
}

.fa.fa-line-chart:before {
  content: "\F201";
}

.fa.fa-lastfm {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-lastfm-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ioxhost {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-angellist {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-cc:before {
  content: "\F20A";
}

.fa.fa-ils:before {
  content: "\F20B";
}

.fa.fa-shekel:before {
  content: "\F20B";
}

.fa.fa-sheqel:before {
  content: "\F20B";
}

.fa.fa-meanpath {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-meanpath:before {
  content: "\F2B4";
}

.fa.fa-buysellads {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-connectdevelop {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-dashcube {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-forumbee {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-leanpub {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-sellsy {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-shirtsinbulk {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-simplybuilt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-skyatlas {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-diamond {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-diamond:before {
  content: "\F3A5";
}

.fa.fa-intersex:before {
  content: "\F224";
}

.fa.fa-facebook-official {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-facebook-official:before {
  content: "\F09A";
}

.fa.fa-pinterest-p {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-whatsapp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-hotel:before {
  content: "\F236";
}

.fa.fa-viacoin {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-medium {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-y-combinator {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yc {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yc:before {
  content: "\F23B";
}

.fa.fa-optin-monster {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-opencart {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-expeditedssl {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-battery-4:before {
  content: "\F240";
}

.fa.fa-battery:before {
  content: "\F240";
}

.fa.fa-battery-3:before {
  content: "\F241";
}

.fa.fa-battery-2:before {
  content: "\F242";
}

.fa.fa-battery-1:before {
  content: "\F243";
}

.fa.fa-battery-0:before {
  content: "\F244";
}

.fa.fa-object-group {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-object-ungroup {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-sticky-note-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-sticky-note-o:before {
  content: "\F249";
}

.fa.fa-cc-jcb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-cc-diners-club {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-clone {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hourglass-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hourglass-o:before {
  content: "\F254";
}

.fa.fa-hourglass-1:before {
  content: "\F251";
}

.fa.fa-hourglass-2:before {
  content: "\F252";
}

.fa.fa-hourglass-3:before {
  content: "\F253";
}

.fa.fa-hand-rock-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-rock-o:before {
  content: "\F255";
}

.fa.fa-hand-grab-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-grab-o:before {
  content: "\F255";
}

.fa.fa-hand-paper-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-paper-o:before {
  content: "\F256";
}

.fa.fa-hand-stop-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-stop-o:before {
  content: "\F256";
}

.fa.fa-hand-scissors-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-scissors-o:before {
  content: "\F257";
}

.fa.fa-hand-lizard-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-lizard-o:before {
  content: "\F258";
}

.fa.fa-hand-spock-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-spock-o:before {
  content: "\F259";
}

.fa.fa-hand-pointer-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-pointer-o:before {
  content: "\F25A";
}

.fa.fa-hand-peace-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-hand-peace-o:before {
  content: "\F25B";
}

.fa.fa-registered {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-creative-commons {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gg {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gg-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-tripadvisor {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-odnoklassniki {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-odnoklassniki-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-get-pocket {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wikipedia-w {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-safari {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-chrome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-firefox {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-opera {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-internet-explorer {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-television:before {
  content: "\F26C";
}

.fa.fa-contao {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-500px {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-amazon {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-calendar-plus-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-plus-o:before {
  content: "\F271";
}

.fa.fa-calendar-minus-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-minus-o:before {
  content: "\F272";
}

.fa.fa-calendar-times-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-times-o:before {
  content: "\F273";
}

.fa.fa-calendar-check-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-calendar-check-o:before {
  content: "\F274";
}

.fa.fa-map-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-map-o:before {
  content: "\F279";
}

.fa.fa-commenting:before {
  content: "\F4AD";
}

.fa.fa-commenting-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-commenting-o:before {
  content: "\F4AD";
}

.fa.fa-houzz {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-vimeo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-vimeo:before {
  content: "\F27D";
}

.fa.fa-black-tie {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fonticons {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-reddit-alien {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-edge {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-credit-card-alt:before {
  content: "\F09D";
}

.fa.fa-codiepie {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-modx {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fort-awesome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-usb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-product-hunt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-mixcloud {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-scribd {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pause-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-pause-circle-o:before {
  content: "\F28B";
}

.fa.fa-stop-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-stop-circle-o:before {
  content: "\F28D";
}

.fa.fa-bluetooth {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-bluetooth-b {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-gitlab {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wpbeginner {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wpforms {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-envira {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wheelchair-alt {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wheelchair-alt:before {
  content: "\F368";
}

.fa.fa-question-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-question-circle-o:before {
  content: "\F059";
}

.fa.fa-volume-control-phone:before {
  content: "\F2A0";
}

.fa.fa-asl-interpreting:before {
  content: "\F2A3";
}

.fa.fa-deafness:before {
  content: "\F2A4";
}

.fa.fa-hard-of-hearing:before {
  content: "\F2A4";
}

.fa.fa-glide {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-glide-g {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-signing:before {
  content: "\F2A7";
}

.fa.fa-viadeo {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-viadeo-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-snapchat {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-snapchat-ghost {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-snapchat-square {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-pied-piper {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-first-order {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-yoast {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-themeisle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-official {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-official:before {
  content: "\F2B3";
}

.fa.fa-google-plus-circle {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-google-plus-circle:before {
  content: "\F2B3";
}

.fa.fa-font-awesome {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fa {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-fa:before {
  content: "\F2B4";
}

.fa.fa-handshake-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-handshake-o:before {
  content: "\F2B5";
}

.fa.fa-envelope-open-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-envelope-open-o:before {
  content: "\F2B6";
}

.fa.fa-linode {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-address-book-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-address-book-o:before {
  content: "\F2B9";
}

.fa.fa-vcard:before {
  content: "\F2BB";
}

.fa.fa-address-card-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-address-card-o:before {
  content: "\F2BB";
}

.fa.fa-vcard-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-vcard-o:before {
  content: "\F2BB";
}

.fa.fa-user-circle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-user-circle-o:before {
  content: "\F2BD";
}

.fa.fa-user-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-user-o:before {
  content: "\F007";
}

.fa.fa-id-badge {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-drivers-license:before {
  content: "\F2C2";
}

.fa.fa-id-card-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-id-card-o:before {
  content: "\F2C2";
}

.fa.fa-drivers-license-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-drivers-license-o:before {
  content: "\F2C2";
}

.fa.fa-quora {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-free-code-camp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-telegram {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-thermometer-4:before {
  content: "\F2C7";
}

.fa.fa-thermometer:before {
  content: "\F2C7";
}

.fa.fa-thermometer-3:before {
  content: "\F2C8";
}

.fa.fa-thermometer-2:before {
  content: "\F2C9";
}

.fa.fa-thermometer-1:before {
  content: "\F2CA";
}

.fa.fa-thermometer-0:before {
  content: "\F2CB";
}

.fa.fa-bathtub:before {
  content: "\F2CD";
}

.fa.fa-s15:before {
  content: "\F2CD";
}

.fa.fa-window-maximize {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-window-restore {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-times-rectangle:before {
  content: "\F410";
}

.fa.fa-window-close-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-window-close-o:before {
  content: "\F410";
}

.fa.fa-times-rectangle-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-times-rectangle-o:before {
  content: "\F410";
}

.fa.fa-bandcamp {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-grav {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-etsy {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-imdb {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-ravelry {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-eercast {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-eercast:before {
  content: "\F2DA";
}

.fa.fa-snowflake-o {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa.fa-snowflake-o:before {
  content: "\F2DC";
}

.fa.fa-superpowers {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-wpexplorer {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

.fa.fa-spotify {
  font-family: 'Font Awesome 5 Brands';
  font-weight: 400;
}

/* Bounce To Right */

.hvr-bounce-to-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.hvr-bounce-to-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-bounce-to-right:hover,
.hvr-bounce-to-right.focus,
.hvr-bounce-to-right.active {
  color: white;
}

.hvr-bounce-to-right:hover:before,
.hvr-bounce-to-right:focus:before,
.hvr-bounce-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

/* Radial Out */

.hvr-radial-out {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.hvr-radial-out:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-radial-out:hover,
.hvr-radial-out:focus,
.hvr-radial-out:active {
  color: white;
}

.hvr-radial-out:hover:before,
.hvr-radial-out:focus:before,
.hvr-radial-out:active:before {
  -webkit-transform: scale(2);
  transform: scale(2);
}

.actions-group {
  float: left;
}

.actions-group button {
  margin-right: 5px;
}

.actions-group .swipe {
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  margin: 15px 0;
}

@media (min-width: 750px) {
  .actions-group .swipe {
    display: none;
  }
}

.btn {
  padding: 10px 20px;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 10, 20, 0.25);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.btn:hover {
  position: relative;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transform: scale(0.98);
  transform: scale(0.98);
  z-index: 10;
  box-shadow: 0 0px 3px rgba(0, 10, 20, 0.25);
}

.btn.btn-default {
  background-color: #61b3e2;
  border: none;
  color: #ffffff;
}

.btn.btn-default:hover.hvr-radial-out:before {
  background: #2098D1;
}

.btn.btn-default.btn-sm {
  background-color: #ffffff;
  color: #354052;
  border: 1px solid rgba(0, 10, 20, 0.25);
  float: right;
  margin-top: 15px;
}

.btn.btn-primary {
  background-color: #009530;
  border: none;
}

.btn.btn-primary:hover.hvr-radial-out:before {
  background: #006e23;
}

.btn.btn-secondary {
  background-color: #bebfc7;
  border: none;
}

.btn.btn-secondary:hover.hvr-radial-out:before {
  background: #373841;
}

.btn.btn-preview {
  position: relative;
  padding-left: 30px;
}

.btn.btn-preview:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F002";
}

.btn.btn-save-continue {
  position: relative;
  padding-left: 30px;
  display: none;
}

@media (min-width: 750px) {
  .btn.btn-save-continue {
    display: inline-block;
  }
}

.btn.btn-save-continue:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F00C";
}

.btn.btn-select-all {
  position: relative;
  padding-left: 30px;
}

.btn.btn-select-all:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F14A";
}

.btn.btn-apply {
  position: relative;
  padding-left: 30px;
}

.btn.btn-apply:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F00C";
}

.btn.btn-save {
  position: relative;
  padding-left: 30px;
}

.btn.btn-save:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F0C7";
}

.btn.btn-delete {
  border: none;
  background-color: #A94442;
  color: #ffffff;
}

.btn.btn-delete:hover.hvr-radial-out:before {
  background: maroon;
}

.btn.btn-upload {
  padding-left: 40px;
  color: #000000;
  background-color: #ffbe00;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  margin: 15px;
}

.btn.btn-upload:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F0EE";
}

.btn.btn-upload:hover {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  color: #ffffff;
}

.btn.btn-help {
  background-color: #ffbe00;
}

.btn.select-button,
.btn.clear-button {
  width: 52px;
  height: 52px;
  margin-left: 5px;
}

.btn.collection-add {
  position: relative;
  padding-left: 30px;
}

.btn.collection-add:after {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F03E";
}

.btn.btn-close {
  background-color: #1b1c20;
  border-radius: 0;
  border: none;
  color: #ffffff;
}

.btn.btn-close:hover.hvr-radial-out:before {
  background: maroon;
}

.image .collection-delete {
  position: absolute;
  top: 0;
  left: auto;
  right: 0 !important;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background-color: #A94442;
}

.image .collection-delete:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  background-color: maroon;
}

.image .collection-delete i {
  color: #ffffff;
}

#side.side {
  background-color: #373841;
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(75%, #373841), to(#43464f));
  background: linear-gradient(to bottom, #373841 75%, #43464f 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#373841', endColorstr='#43464f',GradientType=0 );
  /* IE6-9 */
}

@media (min-width: 1000px) {
  #side.side {
    position: fixed;
    overflow: auto;
    overflow-x: hidden;
    height: 100%;
    z-index: 20;
    padding-bottom: 100px;
  }

  #side.side::-webkit-scrollbar {
    display: none;
  }
}

#side.side .side-top {
  position: relative;
  background-color: #009530;
  height: 59px;
  line-height: 60px;
  padding-left: 20px;
  color: #ffffff;
}

#side.side .side-top .close-sidemenu {
  position: absolute;
  top: 0px;
  right: 0px;
}

#side.side .side-top .close-sidemenu button {
  background-color: transparent;
  border: none;
  width: 60px;
}

#side.side .side-top .close-sidemenu button i {
  color: white;
}

#side.side .side-top .close-sidemenu button:hover {
  cursor: pointer;
}

#side.side .side-top .close-sidemenu button:focus {
  outline: none;
}

#side.side .side-top .hamburger {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 30px;
}

@media (min-width: 1000px) {
  #side.side .side-top .hamburger {
    display: none;
  }
}

#side.side .side-top .hamburger.is-active span:nth-of-type(1) {
  top: 12px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#side.side .side-top .hamburger.is-active span:nth-of-type(2) {
  top: 11px;
  opacity: 0;
}

#side.side .side-top .hamburger.is-active span:nth-of-type(3) {
  top: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#side.side .side-top .hamburger span {
  position: absolute;
  top: 0;
  display: inline-block;
  width: 30px;
  height: 3px;
  border-radius: 3px;
  background-color: #ffffff;
}

#side.side .side-top .hamburger span:nth-of-type(1) {
  top: 5px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

#side.side .side-top .hamburger span:nth-of-type(2) {
  top: 11px;
}

#side.side .side-top .hamburger span:nth-of-type(3) {
  top: 17px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

#side.side .side-footer {
  position: relative;
  left: 10px;
  bottom: 10px;
  color: #ffffff;
  font-size: .6em;
  opacity: 0.25;
}

#side.side .side-footer:hover {
  opacity: 1;
}

#side.side .side-footer span {
  float: left;
  display: block;
  line-height: 30px;
  margin-right: 5px;
}

/* toggle */

#side.side.xshown {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media (min-width: 1000px) {
  #side.side.xshown {
    width: 260px;
  }
}

#side.side.xshown .close-sidemenu {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  display: none;
}

@media (min-width: 1000px) {
  #side.side.xshown .close-sidemenu {
    display: block;
  }
}

#side.side.xshown .close-sidemenu:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
  background-color: #00e94b;
}

#side.side.xshown .close-sidemenu i {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

#side.side.xhidden {
  overflow: inherit;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media (min-width: 1000px) {
  #side.side.xhidden {
    width: 60px;
  }
}

#side.side.xhidden .close-sidemenu:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  cursor: pointer;
  background-color: #00e94b;
}

#side.side.xhidden .close-sidemenu i {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

#side.side.xhidden .side-username {
  display: none;
}

#side.side.xhidden .side-container .menu ul li {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  height: 60px;
  width: 60px;
  overflow: hidden;
}

#side.side.xhidden .side-container .menu ul li.active i {
  color: #00e94b;
}

#side.side.xhidden .side-container .menu ul li.active li i {
  color: #ffffff;
}

#side.side.xhidden .side-container .menu ul li.active li:hover i {
  color: #00e94b;
}

#side.side.xhidden .side-container .menu ul li li {
  opacity: 0;
}

#side.side.xhidden .side-container .menu ul li ul {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  opacity: 0;
  display: block;
  visibility: hidden;
  left: 0px;
}

#side.side.xhidden .side-container .menu ul li:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 320px;
  overflow: inherit;
}

#side.side.xhidden .side-container .menu ul li:hover a span {
  opacity: 1;
}

#side.side.xhidden .side-container .menu ul li:hover ul {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  display: block;
  opacity: 1;
  visibility: visible;
  position: absolute;
  left: 60px;
  top: 60px;
  z-index: 2;
  box-shadow: 0 0 15px rgba(0, 10, 20, 0.5);
}

#side.side.xhidden .side-container .menu ul li:hover li {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 260px;
  opacity: 1;
}

#side.side.xhidden .side-container .menu ul li.active:after {
  display: none;
}

#side.side.xhidden .side-container .menu ul li:after {
  display: none;
}

#side.side.xhidden .side-container .menu ul li a span {
  opacity: 0;
}

#side.side.xhidden .side-container .admin.menu,
#side.side.xhidden .side-container .domain.menu {
  margin: 0;
}

#side.side.xhidden .side-container .admin.menu ul li,
#side.side.xhidden .side-container .domain.menu ul li {
  width: 60px;
  position: relative;
  overflow: hidden;
  background-color: #373841;
}

#side.side.xhidden .side-container .admin.menu ul li:hover,
#side.side.xhidden .side-container .domain.menu ul li:hover {
  width: 320px;
  overflow: inherit;
  line-height: 60px;
}

#side.side.xhidden .side-container .admin.menu ul li:hover ul,
#side.side.xhidden .side-container .domain.menu ul li:hover ul {
  top: 60px;
  left: 60px;
}

#side.side.xhidden .side-container .admin.menu ul li:hover ul li,
#side.side.xhidden .side-container .domain.menu ul li:hover ul li {
  width: 260px;
  line-height: 60px;
}

#side.side.xhidden .side-container .admin.menu ul li:hover ul li a,
#side.side.xhidden .side-container .domain.menu ul li:hover ul li a {
  line-height: 60px;
  height: 60px;
}

#side.side.xhidden .side-container .admin.menu ul li:hover ul li a i,
#side.side.xhidden .side-container .domain.menu ul li:hover ul li a i {
  line-height: 60px;
  height: 60px;
}

body.no-scroll {
  overflow: hidden;
}

@media (min-width: 1000px) {
  #main.main {
    padding-top: 60px;
    padding-bottom: 100px;
    margin-left: 260px;
  }
}

#main.main header {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  position: relative;
  background-color: #ffffff;
  top: 0;
  height: 60px;
  line-height: 60px;
  width: 100%;
  z-index: 5;
  border-bottom: 1px solid rgba(0, 10, 20, 0.1);
  overflow: hidden;
  width: 100%;
}

@media (min-width: 750px) {
  #main.main header {
    overflow: hidden;
    text-align: center;
  }
}

@media (min-width: 1000px) {
  #main.main header {
    position: fixed;
    width: calc(100% - 260px);
  }
}

#main.main header .back {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  height: 60px;
  width: 60px;
  border-right: 1px solid rgba(0, 10, 20, 0.1);
  text-align: center;
}

#main.main header .back:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

#main.main header .back:hover a {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  margin-left: -10px;
  background-color: rgba(0, 10, 20, 0.1);
}

#main.main header .back a {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  color: #354052;
  display: block;
}

#main.main header .back a:hover {
  color: #009530;
}

#main.main header h1 {
  position: relative;
  left: 70px;
  line-height: 60px;
}

@media (min-width: 750px) {
  #main.main header h1 {
    position: static;
    left: auto;
  }
}

#main.main header h1 small {
  position: relative;
  display: inline-block;
  padding-left: 15px;
}

#main.main header h1 small:before {
  position: absolute;
  font-family: "Font Awesome 5 Pro";
  top: -1px;
  left: 0px;
  font-weight: 900;
  content: "\F054";
}

#main.main header .actions.search-bar {
  position: absolute;
  top: 10px;
  right: 10px;
}

@media (min-width: 750px) {
  #main.main header .actions.search-bar {
    right: 70px;
  }
}

#main.main header .actions.search-bar .form-control {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 80px;
  height: 40px;
}

@media (min-width: 750px) {
  #main.main header .actions.search-bar .form-control {
    width: 100px;
  }
}

@media (min-width: 1000px) {
  #main.main header .actions.search-bar .form-control {
    width: 200px;
  }
}

#main.main header .actions.search-bar .form-control:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 300px;
}

#main.main header .actions.search-bar .form-control:focus {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: 300px;
}

#main.main header .actions.search-bar button {
  position: absolute;
  right: 0;
  top: 0px;
  width: 28px;
  height: 40px;
  border: 1px solid #ced4da;
  border-right: none;
  border-left: none;
  background-color: #ffffff;
}

@media (min-width: 750px) {
  #main.main header .actions.search-bar button {
    width: 40px;
    border: 1px solid #ced4da;
    border-right: none;
    border-left: none;
  }
}

#main.main header .actions.search-bar button i:before {
  position: relative;
  top: -12px;
}

#main.main .tab-content,
#main.main .content-padding {
  position: relative;
  padding: 30px;
  padding-bottom: 200px;
}

@media (min-width: 750px) {
  #main.main .tab-content,
  #main.main .content-padding {
    padding: 60px;
  }
}

@media (min-width: 1000px) {
  #main.main .tab-content,
  #main.main .content-padding {
    padding: 60px;
  }
}

@media (min-width: 1200px) {
  #main.main .tab-content,
  #main.main .content-padding {
    width: 75%;
  }
}

#main.main .tab-content .image,
#main.main .content-padding .image {
  width: 100%;
}

@media (min-width: 750px) {
  #main.main .tab-content .image,
  #main.main .content-padding .image {
    width: 200px;
  }
}

#main.main .tab-content .image .position-relative img,
#main.main .content-padding .image .position-relative img {
  width: 100%;
}

@media (min-width: 750px) {
  #main.main .tab-content .image .position-relative img,
  #main.main .content-padding .image .position-relative img {
    width: 200px;
  }
}

#main.main .tab-content .form-group .col-8,
#main.main .content-padding .form-group .col-8 {
  -webkit-box-flex: 0;
          flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 1000px) {
  #main.main .tab-content .form-group .col-8,
  #main.main .content-padding .form-group .col-8 {
    -webkit-box-flex: 0;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

#main.main .tab-content .actions-group .btn-primary,
#main.main .content-padding .actions-group .btn-primary {
  float: left;
  margin: 15px 0;
}

#main.main .tab-content .upload-preview .upload-field,
#main.main .content-padding .upload-preview .upload-field {
  margin-bottom: 15px;
  margin-left: 15px;
  width: calc(100% - 145px);
}

#main.main .tab-content .upload-preview-img-container,
#main.main .content-padding .upload-preview-img-container {
  position: relative;
  text-align: right;
}

#main.main .tab-content .upload-preview-img-container .preview,
#main.main .content-padding .upload-preview-img-container .preview {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  border: 1px solid #ffffff;
}

#main.main .tab-content .upload-preview-img-container .image-size,
#main.main .content-padding .upload-preview-img-container .image-size {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: auto;
  padding: 5px 10px;
  background-color: rgba(0, 10, 20, 0.75);
  color: rgba(255, 255, 255, 0.5);
  font-size: 11px;
}

#main.main .tab-content .news-meta,
#main.main .content-padding .news-meta {
  background-color: rgba(0, 10, 20, 0.05);
  padding: 20px;
  line-height: 50px;
}

#main.main .tab-content #product_categories,
#main.main .content-padding #product_categories {
  border-top: 1px solid rgba(0, 10, 20, 0.05);
}

#main.main .tab-content #product_categories:hover,
#main.main .content-padding #product_categories:hover {
  cursor: pointer;
}

#main.main .tab-content #product_categories .form-check,
#main.main .content-padding #product_categories .form-check {
  padding-left: 0;
}

#main.main .tab-content #product_categories .form-check.checkbox,
#main.main .content-padding #product_categories .form-check.checkbox {
  border-bottom: 1px solid rgba(0, 10, 20, 0.05);
}

#main.main .tab-content #product_categories .form-check label,
#main.main .content-padding #product_categories .form-check label {
  line-height: 40px;
}

#main.main .tab-content #visuals .visual-container,
#main.main .content-padding #visuals .visual-container {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  display: inline-block;
  width: 200px;
  margin-right: 15px;
  box-shadow: 0 0 10px rgba(0, 10, 20, 0.1);
  overflow: hidden;
}

#main.main .tab-content #visuals .visual-container:hover,
#main.main .content-padding #visuals .visual-container:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 0 15px rgba(0, 10, 20, 0.5);
  cursor: pointer;
}

#main.main .tab-content #visuals .visual-container .visual-img-container,
#main.main .content-padding #visuals .visual-container .visual-img-container {
  position: relative;
  height: 200px;
  width: 200px;
  overflow: hidden;
  background-color: #000000;
}

#main.main .tab-content #visuals .visual-container .visual-img,
#main.main .content-padding #visuals .visual-container .visual-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  width: 200px;
}

#main.main .tab-content #visuals .visual-container .visual-checkbox,
#main.main .content-padding #visuals .visual-container .visual-checkbox {
  background-color: #ffffff;
  padding: 10px;
}

#main.main .tab-content #visuals .visual-container .visual-checkbox input,
#main.main .content-padding #visuals .visual-container .visual-checkbox input {
  display: none;
}

#main.main .tab-content #visuals .visual-container .visual-checkbox .form-check.form-check-inline,
#main.main .content-padding #visuals .visual-container .visual-checkbox .form-check.form-check-inline {
  display: none;
}

#main.main .tab-content #visuals .visual-container .visual-checkbox-check,
#main.main .content-padding #visuals .visual-container .visual-checkbox-check {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  color: #ffffff;
  background-color: #009530;
  border: 1px solid #ffffff;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  display: none;
}

#main.main .tab-content #visuals .visual-container.checked,
#main.main .content-padding #visuals .visual-container.checked {
  position: relative;
}

#main.main .tab-content #visuals .visual-container.checked:after,
#main.main .content-padding #visuals .visual-container.checked:after {
  position: absolute;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#009530+0,009530+100&0+0,1+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0, 149, 48, 0) 0%, rgba(0, 149, 48, 0.5) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00009530', endColorstr='#009530',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#main.main .tab-content #visuals .visual-container.checked .visual-checkbox,
#main.main .content-padding #visuals .visual-container.checked .visual-checkbox {
  position: relative;
  background-color: #009530;
  color: #ffffff;
  z-index: 2;
}

#main.main .tab-content #visuals .visual-container.checked .visual-checkbox-check,
#main.main .content-padding #visuals .visual-container.checked .visual-checkbox-check {
  display: block;
}

@media (min-width: 1200px) {
  #main.main .content-padding {
    width: 100%;
  }
}

#main.main .vm-tag__container {
  background-color: rgba(0, 10, 20, 0.1);
  border-radius: 3px;
}

#main.main .vm-tag__container .vm-tag__item.badge.badge-pill {
  margin: 5px;
  border-radius: 3px;
  border: 1px solid rgba(0, 10, 20, 0.25);
  border-bottom: 3px solid rgba(0, 10, 20, 0.25);
  background-color: #ffffff;
  line-height: 24px;
}

#main.main .vm-tag__container .vm-tag__item.badge.badge-pill button.vm-tag__button.vm-tag__button--remove {
  display: inline-block;
  height: 18px;
  width: 18px;
  line-height: 18px;
  text-align: center;
  margin: 0;
  padding: 0;
  background-color: #A94442;
  border: none;
  border-bottom: 2px solid maroon;
  border-radius: 3px;
  margin-left: 5px;
}

#main.main .vm-tag__container .vm-tag__item.badge.badge-pill button.vm-tag__button.vm-tag__button--remove:after {
  font-family: 'Font Awesome 5 Pro';
  content: '\F00D';
  color: #ffffff;
}

#main.main .vm-tag__container .vm-tag__item.badge.badge-pill button.vm-tag__button.vm-tag__button--remove:hover {
  background-color: #1b1c20;
  cursor: pointer;
  border-bottom: none;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

#main.main.xshown {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media (min-width: 1000px) {
  #main.main.xshown {
    margin-left: 260px;
  }
}

#main.main.xshown header {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  z-index: 10;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

@media (min-width: 1000px) {
  #main.main.xshown header {
    width: calc(100% - 260px);
  }
}

#main.main.xshown header.scroll {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

#main.main.xhidden {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

@media (min-width: 1000px) {
  #main.main.xhidden {
    margin-left: 60px;
  }
}

#main.main.xhidden header {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  width: calc(100% - 60px);
}

#main.main .bottom-bar {
  /*border-top: 1px solid $color-black-10;*/
}

@media (min-width: 1000px) {
  #main.main .bottom-bar {
    position: fixed;
    width: calc(100% - 60px);
  }

  #main.main .bottom-bar .copyright {
    position: static;
    display: block;
  }
}

.nav-overlay {
  display: none;
  /* desktop tablet menu */
}

@media (min-width: 1000px) {
  .nav-overlay {
    display: block;
  }
}

@media (min-width: 1000px) {
  .nav-overlay #menu.menu .menu-container ul {
    margin: 0;
    padding: 0;
  }

  .nav-overlay #menu.menu .menu-container ul li {
    position: relative;
    line-height: 60px;
    list-style: none;
    background-color: #1b1c20;
    border-bottom: 1px solid rgba(0, 10, 20, 0.5);
  }

  .nav-overlay #menu.menu .menu-container ul li.active:after {
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    content: '\F078';
    color: #ffffff;
  }

  .nav-overlay #menu.menu .menu-container ul li.active ul {
    display: block;
  }

  .nav-overlay #menu.menu .menu-container ul li:after {
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    content: '\F054';
    color: #ffffff;
  }

  .nav-overlay #menu.menu .menu-container ul li.open ul {
    display: block;
  }

  .nav-overlay #menu.menu .menu-container ul li.open:after {
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    content: '\F078';
    color: #ffffff;
  }

  .nav-overlay #menu.menu .menu-container ul li a {
    display: block;
    color: #ffffff;
  }

  .nav-overlay #menu.menu .menu-container ul li a:hover {
    color: #00e94b;
  }

  .nav-overlay #menu.menu .menu-container ul li a:hover i:after {
    opacity: 1;
    width: 3px;
  }

  .nav-overlay #menu.menu .menu-container ul li a:hover i.icon-flag {
    border: 1px solid red;
  }

  .nav-overlay #menu.menu .menu-container ul li a span {
    display: inline-block;
    padding-left: 20px;
  }

  .nav-overlay #menu.menu .menu-container ul li a i {
    display: block;
    float: left;
    text-align: center;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-right: 1px solid rgba(0, 10, 20, 0.5);
  }

  .nav-overlay #menu.menu .menu-container ul li a i:after {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0px;
    background-color: #009530;
    content: '';
  }

  .nav-overlay #menu.menu .menu-container ul li ul {
    display: none;
  }

  .nav-overlay #menu.menu .menu-container ul li ul li {
    background-color: #373841;
  }

  .nav-overlay #menu.menu .menu-container ul li ul li:after {
    content: '';
  }

  .nav-overlay #menu.menu .menu-container ul li ul li.active:after {
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    right: 0;
    content: '\F061';
    color: rgba(255, 255, 255, 0.5);
  }

  .nav-overlay #menu.menu .menu-container ul li ul li.active a {
    background-color: rgba(255, 255, 255, 0.1);
  }

  .nav-overlay #menu.menu .menu-container ul li ul li.active a i:after {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #009530;
    content: '';
  }

  .nav-overlay #menu.menu.admin.menu ul li:last-of-type:hover a {
    color: #e55162;
  }

  .nav-overlay #menu.menu.admin.menu ul li:last-of-type:hover a i:after {
    background-color: #e55162;
  }

  .nav-overlay #menu.menu.admin.menu,
  .nav-overlay #menu.menu.domain.menu {
    margin: 90px 0;
    border-top: 1px solid rgba(0, 10, 20, 0.5);
  }

  .nav-overlay #menu.menu.admin.menu ul,
  .nav-overlay #menu.menu.domain.menu ul {
    margin: 0;
    padding: 0;
  }

  .nav-overlay #menu.menu.admin.menu ul li,
  .nav-overlay #menu.menu.domain.menu ul li {
    list-style: none;
    line-height: 60px;
    border-bottom: 1px solid rgba(0, 10, 20, 0.5);
  }

  .nav-overlay #menu.menu.admin.menu ul li a,
  .nav-overlay #menu.menu.domain.menu ul li a {
    color: #ffffff;
    display: block;
  }

  .nav-overlay #menu.menu.admin.menu ul li a:hover,
  .nav-overlay #menu.menu.domain.menu ul li a:hover {
    color: #00e94b;
  }

  .nav-overlay #menu.menu.admin.menu ul li a:hover i:after,
  .nav-overlay #menu.menu.domain.menu ul li a:hover i:after {
    opacity: 1;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #009530;
    content: '';
  }

  .nav-overlay #menu.menu.admin.menu ul li a i,
  .nav-overlay #menu.menu.domain.menu ul li a i {
    display: block;
    float: left;
    text-align: center;
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-right: 1px solid rgba(0, 10, 20, 0.5);
  }

  .nav-overlay #menu.menu.admin.menu ul li a span,
  .nav-overlay #menu.menu.domain.menu ul li a span {
    display: inline-block;
    padding-left: 20px;
  }

  .nav-overlay #menu.menu.admin.menu ul li a i.icon-flag img,
  .nav-overlay #menu.menu.domain.menu ul li a i.icon-flag img {
    width: 18px;
    height: 18px;
  }

  .nav-overlay #menu.menu.admin.menu ul li ul,
  .nav-overlay #menu.menu.domain.menu ul li ul {
    /*    display: none;*/
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    left: 0px;
    width: 260px;
    z-index: 100;
    background-color: #000000;
  }

  .nav-overlay #menu.menu.admin.menu ul li ul > li > a > span,
  .nav-overlay #menu.menu.domain.menu ul li ul > li > a > span {
    padding-left: 0;
  }

  .nav-overlay #menu.menu.admin.menu ul li ul li,
  .nav-overlay #menu.menu.domain.menu ul li ul li {
    line-height: 48px;
    border-bottom: 1px solid #1b1c20;
  }

  .nav-overlay #menu.menu.admin.menu ul li ul li a,
  .nav-overlay #menu.menu.domain.menu ul li ul li a {
    line-height: 48px;
    height: 48px;
  }

  .nav-overlay #menu.menu.admin.menu ul li ul li a i,
  .nav-overlay #menu.menu.domain.menu ul li ul li a i {
    height: 48px;
    line-height: 48px;
    border-right: 1px solid #1b1c20;
  }

  .nav-overlay #menu.menu.admin.menu ul li:hover ul,
  .nav-overlay #menu.menu.domain.menu ul li:hover ul {
    display: block;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .nav-overlay #menu.menu.admin.menu ul li:hover ul li:last-of-type:hover a,
  .nav-overlay #menu.menu.domain.menu ul li:hover ul li:last-of-type:hover a {
    color: #009530;
  }

  .nav-overlay #menu.menu.admin.menu ul li:hover ul li:last-of-type:hover a i:after,
  .nav-overlay #menu.menu.domain.menu ul li:hover ul li:last-of-type:hover a i:after {
    background-color: #009530;
  }
}

html.noscroll {
  overflow-y: hidden;
}

@media only screen and (max-width: 992px) {
  .nav-overlay {
    position: fixed;
    top: 59px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: #1b1c20;
    padding-bottom: 200px;
    overflow-y: scroll;
  }

  .nav-overlay .menu {
    padding: 0;
  }

  .nav-overlay .menu ul {
    margin: 0;
    padding: 0;
  }

  .nav-overlay .menu ul li {
    position: relative;
    list-style: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .nav-overlay .menu ul li:after {
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0px;
    right: 5px;
    content: '\F078';
    color: #ffffff;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    z-index: -1;
  }

  .nav-overlay .menu ul li.active:after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    top: 10px;
    right: 0;
  }

  .nav-overlay .menu ul li.active a {
    text-decoration: none;
  }

  .nav-overlay .menu ul li.active ul {
    display: block;
  }

  .nav-overlay .menu ul li a {
    display: block;
    line-height: 48px;
    color: #ffffff;
    padding: 0 20px;
  }

  .nav-overlay .menu ul li a:focus {
    text-decoration: none;
  }

  .nav-overlay .menu ul li a i {
    margin-right: 15px;
  }

  .nav-overlay .menu ul li a.hvr-bounce-to-right:before {
    display: none !important;
  }

  .nav-overlay .menu ul li ul {
    display: none;
    background-color: #373841;
  }

  .nav-overlay .menu ul li ul li.active {
    background-color: #ffffff;
  }

  .nav-overlay .menu ul li ul li.active a {
    color: #000000;
  }

  .nav-overlay .menu ul li ul li:after {
    display: none;
  }

  .nav-overlay .menu ul li ul li a {
    padding: 0 20px;
    color: #ffffff;
  }

  .nav-overlay .menu ul li.open:after {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    top: 10px;
    right: 0;
  }

  .nav-overlay .menu ul li.open ul {
    display: block;
  }

  .nav-overlay .menu ul li.open ul li.active {
    background-color: #009530;
  }

  .nav-overlay .menu ul li.open ul li.active a {
    color: #ffffff;
  }
}

@media only screen and (max-width: 992px) and (max-width: 992px) {
  .nav-overlay .admin.menu {
    position: relative;
    top: 60px;
    z-index: 100;
  }
}

.table-responsive {
  position: relative;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 0 25px rgba(0, 10, 20, 0.1);
  background-color: #ffffff;
}

.table-responsive:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  box-shadow: 0 0 50px rgba(0, 10, 20, 0.25);
}

.table-responsive .swipe {
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 10;
  display: block;
}

@media (min-width: 1000px) {
  .table-responsive .swipe {
    display: none;
  }
}

.table-responsive table.table {
  margin: 0;
  border: 1px solid #dee2e6;
  display: block;
}

@media (min-width: 1000px) {
  .table-responsive table.table {
    display: table;
    width: 100%;
  }
}

.table-responsive table.table tr:hover {
  background-color: #f0faff;
}

.table-responsive table.table tr td span.icon-order-hide {
  display: none;
}

.table-responsive table.table tr td .order-margin {
  margin-left: 34px;
}

.table-responsive table.table th {
  background-color: #373841;
  padding: 8px;
  vertical-align: middle;
  color: #ffffff;
}

.table-responsive table.table th:last-of-type {
  text-align: right;
}

.table-responsive table.table th a {
  color: #ffffff;
}

.table-responsive table.table th a:hover {
  color: #00e94b;
}

.table-responsive table.table td {
  position: relative;
  padding: 8px;
  vertical-align: middle;
}

.table-responsive table.table td i {
  padding: 10px;
  color: #354052;
}

.table-responsive table.table td i:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-color: #009530;
  color: #ffffff;
}

.table-responsive table.table td i:hover.fal.fa-pen-alt {
  background-color: #00aeff;
}

.table-responsive table.table td i:hover.fal.fa-paste {
  background-color: #ffa200;
}

.table-responsive table.table td i:hover.fal.fa-search {
  background-color: #373841;
}

.table-responsive table.table td i:hover.fa-trash-alt {
  background-color: #A94442;
}

.table-responsive table.table td:last-of-type {
  display: block;
  width: 200px !important;
  text-align: right;
}

@media (min-width: 1000px) {
  .table-responsive table.table td:last-of-type {
    display: table-cell;
    width: auto;
  }
}

.table-responsive table.table td a .label {
  position: absolute;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  font-size: 0px;
  top: 50%;
  left: 25%;
  text-align: center;
  line-height: 24px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.table-responsive table.table td a .label.label-success {
  background-color: #009530;
  color: #009530;
}

.table-responsive table.table td a .label.label-success:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  margin-left: -6px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  font-size: 10px;
  color: #ffffff;
  font-weight: bold;
}

.table-responsive table.table td a .label.label-danger {
  background-color: #A94442;
  color: #A94442;
}

.table-responsive table.table td a .label.label-danger:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  margin-left: -6px;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
}

.table-responsive table.table td a:hover {
  text-decoration: none;
}

.table.form-reactions td {
  display: block;
}

@media (min-width: 750px) {
  .table.form-reactions td {
    display: table-cell;
  }
}

.filter-bar {
  background-color: rgba(0, 10, 20, 0.05);
  font-size: .8em;
  padding-top: 6px;
  padding-left: 30px;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
}

@media (min-width: 750px) {
  .filter-bar {
    width: auto;
    overflow: hidden;
  }
}

@media (min-width: 1000px) {
  .filter-bar {
    font-size: 1em;
    padding-left: 60px;
  }
}

.filter-bar .filtering {
  float: left;
  display: inline-block;
  float: left;
}

.filter-bar .filtering .row {
  display: inline-block;
  margin-right: 15px !important;
}

@media (min-width: 750px) {
  .filter-bar .filtering .row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
}

.filter-bar .filtering .col-xs-12.col-sm-8 {
  display: inline-block;
  position: static;
  width: auto;
}

@media (min-width: 750px) {
  .filter-bar .filtering .col-xs-12.col-sm-8 {
    position: relative;
    width: 100%;
  }
}

.filter-bar .filtering select {
  height: 40px;
  line-height: 40px;
  width: 80px;
  margin-bottom: 6px;
}

@media (min-width: 750px) {
  .filter-bar .filtering select {
    left: 60px;
    width: 200px;
  }
}

.filter-bar .filtering .control-label {
  width: auto;
}

@media (min-width: 1000px) {
  .filter-bar .filtering .control-label {
    width: 100%;
  }
}

.datatable-filters {
  float: left;
  margin-left: 30px;
  margin-top: 15px;
}

@media (min-width: 750px) {
  .datatable-filters {
    margin-left: 60px;
  }
}

.datatable-filters .filter-order {
  float: left;
  padding-right: 15px;
}

.datatable-filters .filter-order .badge {
  padding: 14px;
}

.datatable-filters .filter-order .badge.badge-info {
  background-color: rgba(0, 10, 20, 0.1);
  color: #354052;
}

.datatable-filters .filter-filters {
  float: left;
  margin-right: 15px;
  background-color: #ffffff;
  padding-left: 10px;
  border: 1px solid rgba(0, 10, 20, 0.1);
  border-radius: 5px;
}

.datatable-filters .filter-filters .badge {
  padding: 10px;
}

.datatable-filters .filter-filters .badge.badge-info {
  background-color: #A94442;
  color: #ffffff;
}

.datatable-filters .filter-filters .badge.badge-info span {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border: 1px solid white;
  border-radius: 9px;
  z-index: 2;
}

.datatable-filters .filter-filters .badge.badge-info span:hover {
  cursor: pointer;
  background-color: rgba(0, 10, 20, 0.25);
}

.datatable-filters .filter-search {
  float: left;
  background-color: #ffffff;
  padding-left: 10px;
  border: 1px solid rgba(0, 10, 20, 0.1);
  border-radius: 5px;
  margin-right: 15px;
}

.datatable-filters .filter-search .badge {
  padding: 10px;
}

.datatable-filters .filter-search .badge.badge-info {
  background-color: #A94442;
  color: #ffffff;
}

.datatable-filters .filter-search .badge.badge-info span {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border: 1px solid white;
  border-radius: 9px;
  z-index: 2;
}

.datatable-filters .filter-search .badge.badge-info span:hover {
  cursor: pointer;
  background-color: rgba(0, 10, 20, 0.25);
}

.filter-bar_content {
  float: left;
  width: 900px;
}

@media (min-width: 750px) {
  .filter-bar_content {
    width: auto;
  }
}

.datatable-pagination {
  float: left;
  width: 100%;
  text-align: center;
}

.datatable-pagination .pagination {
  -webkit-box-pack: center;
          justify-content: center;
}

.datatable-pagination .pagination .page-item a {
  color: #009530;
}

.datatable-pagination .pagination .page-item.active .page-link {
  color: #ffffff;
  background-color: #009530;
  border-color: rgba(0, 10, 20, 0.25);
}

.datatable-pagination .pagination .page-item.active .page-item:first-child .page-link {
  border-radius: 3px;
}

.datatable-pagination .pagination .page-item.active .page-item:last-child .page-link {
  border-radius: 3px;
}

#main header .bottom-bar {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  position: fixed;
  min-height: 60px;
  bottom: 0;
  background-color: #ffffff;
  padding-left: 15px;
  text-align: left;
  width: 100%;
}

@media (min-width: 1000px) {
  #main header .bottom-bar {
    width: calc(100% - 260px);
    padding-left: 60px;
  }
}

#main header .bottom-bar .actions-group {
  display: inline-block;
}

#main header .bottom-bar.rest {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

#main header .bottom-bar.scroll {
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.15);
}

.tabs {
  min-height: 60px;
  line-height: 60px;
  position: relative;
  border-bottom: 1px solid rgba(0, 10, 20, 0.1);
  z-index: 2;
}

.tabs ul.nav {
  background: #ebeef2;
  background: -webkit-gradient(linear, left top, right top, from(#ebeef2), to(#ffffff));
  background: linear-gradient(to right, #ebeef2 0%, #ffffff 100%);
}

.tabs ul.nav li.nav-item {
  margin: 0;
  border-right: 1px solid rgba(0, 10, 20, 0.1);
  background-color: #ebeef2;
}

.tabs ul.nav li.nav-item a.nav-link {
  position: relative;
  padding: 0 15px;
  color: #354052;
  width: 88px;
  overflow: hidden;
  height: 60px;
}

@media (min-width: 750px) {
  .tabs ul.nav li.nav-item a.nav-link {
    width: auto;
    overflow: auto;
  }
}

.tabs ul.nav li.nav-item a.nav-link.active {
  color: #009530;
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 10, 20, 0.25);
}

.tabs ul.nav li.nav-item a.nav-link.active:after {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0px;
  background-color: #009530;
  content: '';
}

.tabs ul.nav li.nav-item a.nav-link:hover {
  color: #61b3e2;
}

.tabs ul.nav li.nav-item a.nav-link:hover:after {
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  bottom: 0px;
  background-color: #61b3e2;
  content: '';
}

.manage-slide-container {
  position: fixed !important;
}

.manage-slide-container .manage-slide-header {
  position: relative;
}

.manage-slide-container .manage-slide-header .btn-close {
  position: absolute;
  height: 60px;
  width: 60px;
  left: 0px;
  top: -10px;
}

.manage-slide-container h1 {
  line-height: 60px;
  padding-left: 80px;
}

.manage-slide-container h1 small {
  position: relative;
  font-size: 1em;
  margin-left: 30px;
}

.manage-slide-container h1 small:after {
  position: absolute;
  top: -22px;
  left: -20px;
  font-family: "Font Awesome 5 Pro";
  font-weight: normal;
  content: "\F054";
}

.manage-slide-container .manage-slide {
  width: 100%;
  margin-right: -100%;
  border: none;
}

@media (min-width: 1000px) {
  .manage-slide-container .manage-slide {
    width: 85%;
  }
}

.manage-slide-container .manage-slide .manage-file {
  overflow-x: hidden;
  float: left;
  width: 100%;
}

.manage-slide-container .manage-slide .manage-slide-content {
  padding: 15px;
  overflow-x: hidden;
  float: left;
  width: 100%;
}

@media (min-width: 1000px) {
  .manage-slide-container .manage-slide .manage-slide-content {
    padding: 60px;
  }
}

.manage-slide-container .manage-slide .manage-slide-content .drop {
  margin-bottom: 60px;
}

.drop {
  border: 2px dashed rgba(0, 10, 20, 0.25);
  border-radius: 10px;
  padding: 30px 10px;
  background-color: rgba(255, 190, 0, 0.05);
}

.icon {
  width: 30px;
  height: 30px;
  background-color: #A94442;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  padding: 4px;
  display: none;
  cursor: pointer;
}

.folder {
  margin-bottom: 20px;
}

.folder img {
  width: 100%;
  height: 200px;
  -o-object-fit: contain;
     object-fit: contain;
}

.folder .remove {
  top: 0px;
  right: 10%;
}

.folder .remove:hover {
  color: #fff;
}

.folder:hover .icon {
  display: initial;
}

.image-size {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 10, 20, 0.75);
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
  padding: 5px;
}

.upload {
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.upload:hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  background-color: #009530;
  color: #ffffff;
}

.upload:hover .filename {
  border: 1px solid #009530;
}

.upload img {
  border: 1px solid rgba(0, 10, 20, 0.25);
  width: 100%;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.upload .filename {
  margin-top: -1px;
  border: 1px solid rgba(0, 10, 20, 0.1);
  padding: 5px 15px;
}

.upload .check {
  left: 50%;
  margin-left: -15px;
  top: 30%;
  pointer-events: none;
  background-color: #009530;
}

.upload.selected {
  border: 2px solid #009530;
}

.upload.selected .filename {
  background-color: #009530;
  color: #ffffff;
}

.upload.selected a {
  position: relative;
  display: inline-block;
}

.upload.selected a:after {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  content: '';
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#009530+0,009530+100&0+0,1+100 */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(0, 149, 48, 0) 0%, rgba(0, 149, 48, 0.5) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00009530', endColorstr='#009530',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}

.upload.selected .check {
  display: initial;
}

.login {
  background: url(/assets/images/login-bg.1a2641ffccf52003715127f322fafff1.jpg) no-repeat top center;
  background-size: 100% 200px;
}

@media (min-width: 1000px) {
  .login {
    background-size: 100%;
  }
}

.login .no-padding {
  padding: 0;
}

.login .login-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  background-color: #ffffff;
  box-shadow: 0 0 50px rgba(0, 10, 20, 0.25);
  border-radius: 10px;
  overflow: hidden;
  width: 80%;
}

@media (min-width: 1000px) {
  .login .login-container {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    top: 50%;
    height: 600px;
    width: 800px;
  }
}

.login .login-container .login-left {
  text-align: center;
}

.login .login-container .login-left h1 {
  position: relative;
  z-index: 2;
  font-size: 24px;
  color: #ffffff;
  line-height: 300px;
  top: -25%;
}

@media (min-width: 1000px) {
  .login .login-container .login-left {
    width: 400px;
    float: left;
    text-align: left;
  }

  .login .login-container .login-left h1 {
    margin: 0;
    font-size: 48px;
    top: 75%;
    left: 30px;
    line-height: normal;
  }
}

@media (min-width: 1000px) {
  .login .login-container .login-right {
    position: relative;
    width: 400px;
    float: left;
  }
}

.login .login-container .login-right .control-label {
  display: block;
  width: 100%;
  max-width: 100%;
}

.login .login-container .login-right .form-control {
  display: block;
  height: 50px;
  border-radius: 3px;
}

@media (min-width: 1000px) {
  .login .login-container .login-right .form-control {
    width: 300px;
    max-width: 300px;
  }
}

.login .login-container .login-right .checkbox label {
  margin-left: 30px;
}

.login .login-panel {
  padding: 20px;
  padding-top: 60px;
}

@media (min-width: 1000px) {
  .login .login-panel {
    padding: 60px;
  }
}

.login .login-hero {
  position: relative;
  background: url(/assets/images/login-hero.66a464f4df59520318fd1f7b723dc6da.jpg) no-repeat center;
  background-size: cover;
  height: 200px;
}

.login .login-hero:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 10, 20, 0.25);
  content: '';
}

@media (min-width: 1000px) {
  .login .login-hero {
    height: 600px;
  }
}

.login .login-logo {
  position: absolute;
  display: block;
  top: 150px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  background-color: #009530;
}

.login .login-logo img {
  width: 60px;
  height: 60px;
}

@media (min-width: 430px) {
  .login .login-logo {
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    top: 250px;
    left: 50%;
  }
}

@media (min-width: 1000px) {
  .login .login-logo {
    position: absolute;
    top: 50%;
    left: 0px;
  }
}

@media (min-width: 1440px) {
  .dashboard {
    display: table;
  }
}

.dashboard .empty-card {
  margin-bottom: 30px;
}

.dashboard .empty-card span {
  font-size: 1.4em;
}

.dashboard .dashboard-card {
  background-color: #ffffff;
  box-shadow: 0 0 15px rgba(0, 10, 20, 0.1);
  border-radius: 3px;
  margin-bottom: 30px;
}

.dashboard .dashboard-card.half-column {
  float: left;
  width: calc(50% - 15px);
}

.dashboard .dashboard-card.half-column.first-column {
  margin-right: 30px;
}

@media (min-width: 1000px) {
  .dashboard .dashboard-card.half-column.first-column {
    min-height: 310px;
  }
}

@media (min-width: 1440px) {
  .dashboard .dashboard-card.half-column.first-column {
    min-height: auto;
  }
}

@media (min-width: 1000px) {
  .dashboard .dashboard-card.half-column.pageviews {
    min-height: 310px;
  }
}

@media (min-width: 1440px) {
  .dashboard .dashboard-card.half-column.pageviews {
    min-height: auto;
  }
}

@media (min-width: 1000px) {
  .dashboard .dashboard-card.half-column {
    width: calc(33% - 15px);
  }
}

@media (min-width: 1440px) {
  .dashboard .dashboard-card.half-column {
    width: calc(50% - 15px);
  }
}

.dashboard .dashboard-card.full-column {
  float: left;
  width: 100%;
}

@media (min-width: 1000px) {
  .dashboard .dashboard-card.full-column.hosting {
    width: calc(33% - 30px);
    margin-left: 30px;
  }
}

@media (min-width: 1440px) {
  .dashboard .dashboard-card.full-column.hosting {
    width: 100%;
    margin-left: 0px;
  }
}

.dashboard .dashboard-card .dashboard-card_header {
  color: #ffffff;
  background-color: #373841;
  padding: 0 20px;
  line-height: 40px;
  height: 40px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.dashboard .dashboard-card .dashboard-card_header .dashboard-nav {
  float: right;
  color: #00e94b;
}

.dashboard .dashboard-card .dashboard-card_header .dashboard-nav:hover {
  cursor: pointer;
}

.dashboard .dashboard-card .dashboard-card_content {
  position: relative;
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

.dashboard .dashboard-card .dashboard-card_content .content-no {
  display: block;
  text-align: center;
  font-size: 4em;
  color: #009530;
}

.dashboard .dashboard-card .dashboard-card_content table {
  line-height: 36px;
}

.dashboard .dashboard-card .dashboard-card_content table i {
  margin: 0 5px;
}

.dashboard .dashboard-card .dashboard-card_content .dashboard-card_content-img {
  float: left;
  width: 50%;
}

@media (min-width: 1000px) {
  .dashboard .dashboard-card .dashboard-card_content .dashboard-card_content-img {
    display: block;
    width: 100%;
    float: none;
  }
}

@media (min-width: 1440px) {
  .dashboard .dashboard-card .dashboard-card_content .dashboard-card_content-img {
    float: left;
    width: 50%;
  }
}

.dashboard .dashboard-card .dashboard-card_content .dashboard-card_content-txt {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  float: left;
  width: 50%;
}

@media (min-width: 1000px) {
  .dashboard .dashboard-card .dashboard-card_content .dashboard-card_content-txt {
    display: block;
    position: static;
    top: 0;
    width: 100%;
    float: none;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}

@media (min-width: 1440px) {
  .dashboard .dashboard-card .dashboard-card_content .dashboard-card_content-txt {
    display: inline;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    float: left;
    width: 50%;
  }
}

.dashboard .dashboard-card .dashboard-card_footer {
  text-align: center;
}

.dashboard .dashboard-card .dashboard-card_footer .content-per {
  display: inline-block;
  padding-bottom: 10px;
}

.dashboard .dashboard-card .dashboard-card_footer .content-per.neg {
  color: #A94442;
}

.dashboard .dashboard-column-left {
  width: 100%;
}

@media (min-width: 1440px) {
  .dashboard .dashboard-column-left {
    display: table-cell;
    vertical-align: top;
    width: 540px;
    padding-right: 30px;
  }
}

.dashboard .dashboard-column-right {
  vertical-align: top;
  width: 100%;
}

@media (min-width: 1440px) {
  .dashboard .dashboard-column-right {
    display: table-cell;
    width: calc(100% - 570px);
  }
}

.dashboard .dashboard-column-right .dashboard-table {
  width: 100%;
  line-height: 36px;
}

.dashboard .dashboard-column-right .dashboard-table .remaining {
  color: #A94442;
  border-bottom: 1px solid #ddd;
}

.dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list {
  display: block;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

@media (min-width: 1800px) {
  .dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list {
    display: table-cell;
  }
}

.dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list .dashboard-ticket-list {
  width: 100%;
}

.dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list .dashboard-ticket-list tr:hover {
  background-color: #ebeef2;
}

.dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list .dashboard-ticket-list .dash-no {
  width: 75px;
}

.dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list .dashboard-ticket-list .dash-type {
  width: 75px;
}

.dashboard .dashboard-column-right .dashboard-table .td-dashboard-ticket-list .dashboard-ticket-list .dash-status {
  width: 75px;
}

.dashboard .dashboard-column-right .dashboard-table .new-ticket-td {
  display: block;
  width: 100%;
}

@media (min-width: 1800px) {
  .dashboard .dashboard-column-right .dashboard-table .new-ticket-td {
    display: table-cell;
    padding-left: 30px;
    border-left: 1px solid #ddd;
  }
}

.dashboard .dashboard-column-right .dashboard-table .new-ticket-td .dashboard-ticket-new {
  width: 350px;
}

.dashboard .dashboard-column-right .dashboard-table .new-ticket-td .dashboard-ticket-new select {
  width: 100%;
  line-height: 36px;
  padding: 10px 0;
}

.dashboard .dashboard-column-right .dashboard-table .new-ticket-tfoot {
  border-top: 1px solid #ddd;
}

.dashboard .dashboard-column-right .dashboard-table .new-ticket-tfoot .teamviewer {
  width: 120px;
}

.views-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 10, 20, 0.75);
}

.views-popup-wrapper.xhidden {
  display: none;
}

.views-popup-wrapper.xshown {
  display: block;
  z-index: 10;
}

.views-popup-wrapper .views-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.views-popup-wrapper .views-popup .close-popup {
  position: absolute;
  background-color: #A94442;
  color: #ffffff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  top: -15px;
  right: -15px;
}

.views-popup-wrapper .views-popup .close-popup:hover {
  cursor: pointer;
}

.bulk_container {
  position: absolute;
  right: 30px;
  top: 35px;
  height: 60px;
  overflow: hidden;
  width: 92px;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  display: none;
}

@media (min-width: 1000px) {
  .bulk_container {
    top: 15px;
    display: block;
  }
}

@media (min-width: 1200px) {
  .bulk_container {
    border: none;
    display: block;
    top: 65px;
    right: 60px;
  }
}

.bulk_container.open {
  width: 665px;
}

.bulk_container.open .bulk-acties_slidebutton:before {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.bulk_container .bulk-acties_slidebutton {
  position: relative;
  float: left;
  margin-right: 5px;
  margin-top: 10px;
  padding-left: 30px;
}

.bulk_container .bulk-acties_slidebutton:before {
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\F33E";
}

.bulk_container .bulk-acties_slidebutton:focus {
  border-color: inherit;
  box-shadow: none;
  outline: none;
}

.bulk_container .form-horizontal.bulk_form {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  float: left;
  padding-top: 10px;
  display: none;
}

@media (min-width: 750px) {
  .bulk_container .form-horizontal.bulk_form {
    display: block;
  }
}

.bulk_container .form-horizontal.bulk_form .form-group {
  float: left;
}

.bulk_container .form-horizontal.bulk_form .form-group select.form-control.bulk_factory {
  width: 250px;
  margin-right: 5px;
  margin-bottom: 0px;
}

.bulk_container .form-horizontal.bulk_form .row {
  display: block;
}

.bulk_container .form-horizontal.bulk_form .row .col-xs-12.col-sm-4.control-label {
  display: inline-block !important;
  width: auto !important;
  max-width: auto !important;
}

.bulk_container .form-horizontal.bulk_form .row .col-xs-12.col-sm-8 {
  display: inline-block !important;
  width: auto !important;
  max-width: auto !important;
}

/* custom checkbox */

.table-responsive input[type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: 2;
  width: 14px;
  height: 14px;
}

.table-responsive input[type=checkbox]:hover {
  cursor: pointer;
}

.table-responsive input[type=checkbox] + span {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.table-responsive input[type=checkbox] + span:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 14px;
  height: 14px;
  background: white;
  border-radius: 2px;
  border: 1px solid rgba(0, 10, 20, 0.25);
}

.table-responsive input[type=checkbox]:hover + span:before {
  background: #009530;
}

.table-responsive input[type=checkbox]:focus + span:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.table-responsive input[type=checkbox]:checked + span:before {
  background: #009530;
}

.table-responsive input[type=checkbox]:disabled + span {
  color: #b8b8b8;
  cursor: auto;
}

.table-responsive input[type=checkbox]:disabled + span:before {
  box-shadow: none;
  background: #ddd;
}

.table-responsive input[type=checkbox]:checked + span:after {
  content: '';
  position: absolute;
  left: 1px;
  top: 5px;
  background: white;
  width: 3px;
  height: 3px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.help-overlay {
  text-align: center;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(0, 10, 20, 0.25);
}

.help-overlay.xshown {
  display: block;
}

.help-overlay .help-modus {
  position: absolute;
  top: 140px;
  background-color: #ffbe00;
  padding: 10px 15px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 10, 20, 0.5);
  border-radius: 20px;
}

@media (min-width: 1000px) {
  .help-overlay .help-modus {
    top: 80px;
  }
}

.help-overlay .help-modus:hover {
  background-color: #000000;
  color: #ffffff;
  cursor: pointer;
}

.actions.help {
  display: none;
  position: absolute;
  top: 0px;
  right: 0px;
  display: none;
}

.actions.help .btn-help {
  position: relative;
  height: 60px;
  width: 60px;
  border-radius: 0px;
  box-shadow: none;
  z-index: 10;
  display: none;
}

@media (min-width: 750px) {
  .actions.help .btn-help {
    display: block;
  }
}

.actions.help .btn-help:focus {
  color: #000000;
}

.actions.help .btn-help:hover {
  background-color: #000000;
  color: #ffffff;
}

.actions.help .btn-help.btn-active {
  background-color: #000000;
  color: #ffffff;
}

.help-container {
  display: inline-block;
  position: relative;
}

.btn-tooltip-help {
  position: absolute;
  right: -12px;
  background-color: #ffbe00;
  color: #000000;
  border: none;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  box-shadow: 0 2px 4px rgba(0, 10, 20, 0.5);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.btn-tooltip-help .help-txt {
  opacity: 0;
  visibility: hidden;
}

.btn-tooltip-help.hover {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  right: -248px;
  height: auto;
  width: 260px;
}

.btn-tooltip-help.hover i {
  float: left;
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-top: 5px;
}

.btn-tooltip-help.xshown {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.btn-tooltip-help.xshown:hover {
  cursor: pointer;
}

.btn-tooltip-help.xshown:hover .help-txt {
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  -webkit-transition-delay: 0.1s;
          transition-delay: 0.1s;
  display: inline-block;
  padding: 15px;
  opacity: 1;
  height: 100%;
  width: 260px;
  visibility: visible;
  text-align: left;
}

.manage-slide-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 11000;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  pointer-events: none;
}

.manage-slide {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #ddd;
  box-shadow: -5px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 11001;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  overflow-x: hidden;
}

.manage-slide-header {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #ececec;
  padding-top: 0px;
  padding-bottom: 0px;
}

.manage-slide-header button {
  margin-top: 10px;
}