.drop {
  border: 2px solid #ccc;
  border-style: dashed;
  border-radius: 10px;
  padding: 10px;
  background-color: #f2f2f2;
}

.icon {
  width: 30px;
  height: 30px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  text-align: center;
  padding: 4px;
  display: none;
  color: #f2f2f2;
  cursor: pointer;
}

.folder {
  margin-bottom: 20px;
}

.folder img {
  width: 100%;
  height: 100px;
  -o-object-fit: contain;
     object-fit: contain;
}

.folder .remove {
  top: 0px;
  right: 10%;
}

.folder .remove:hover {
  color: #ff5050;
}

.folder:hover .icon {
  display: initial;
}

.upload {
  margin-bottom: 20px;
}

.upload img {
  border: 1px solid #ccc;
  width: 100%;
  height: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}

.upload .filename {
  margin-top: -1px;
  border: 1px solid #ccc;
  padding: 5px 15px;
}

.upload .check {
  left: 50%;
  margin-left: -15px;
  top: 30%;
  pointer-events: none;
}

.upload.selected {
  border: 2px dashed #ccc;
}

.upload.selected .check {
  display: initial;
}

.image {
  width: 200px;
  height: 275px;
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  margin: 10px 10px 30px 0px;
  cursor: move;
}

.image img {
  width: 200px;
  height: 200px;
  -o-object-fit: cover;
     object-fit: cover;
}

.image .collection-delete {
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: center;
}